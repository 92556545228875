import { TipoAtendimentoCartorio } from '../_enums';

export class HorarioAtendimento {
    constructor(usuario: string, hora: string, tipo: TipoAtendimentoCartorio) {
        this.idUsuario = usuario;
        this.horaInicio = hora;
        this.tipoAtendimento = tipo;
    }

    idUsuario: string;
    horaInicio: string;
    tipoAtendimento: TipoAtendimentoCartorio;
}
