import { Component, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { TipoAtendimentoCartorio } from '../_shared/models/_enums';
import { ConfiguracoesGeraisCartsys } from '../_shared/models';
import { CartorioControllerService } from '../_shared/servicos/controllers';
import { DadosCartorioHomeDTO } from '../_shared/models/dtos/dados-cartorio-home-dto.model';
import { ConfiguracoesGeraisControllerService } from '../_shared/servicos/controllers/configuracoes-gerais-controller.service';
import { TipoServicoControllerService } from '../_shared/servicos/controllers/tipo-servico-controller.service';
import { forkJoin } from 'rxjs';
import { TipoServicoAtendimento } from '../_shared/models/cartorio/tipo-servico-atendimento.model';
import { DialogService } from '../_shared/servicos/dialogs';
import { DialogTirarDuvidaComponent } from './dialog-tirar-duvida/dialog-tirar-duvida.component';
import { Overlay } from '@angular/cdk/overlay';
import { DialogDetalhesAgendamentoComponent } from './dialog-detalhes-agendamento/dialog-detalhes-agendamento.component';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class HomeComponent {
    public cns = '';
    public perguntas = false;
    public tipoAtendimento: TipoAtendimentoCartorio;
    public dadosCartorio: DadosCartorioHomeDTO;
    public dadosConfiguracao: ConfiguracoesGeraisCartsys;
    public carregando = true;
    public retirarDuvidas = false;
    public tiposServico: Array<TipoServicoAtendimento>;
    public urlSuporteWhatsApp: string;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _activatedRoute: ActivatedRoute,
        private _fuseProgressBarService: FuseProgressBarService,
        private _cartorioController: CartorioControllerService,
        private _configuracoesController: ConfiguracoesGeraisControllerService,
        private _tipoServicoController: TipoServicoControllerService,
        private _dialogService: DialogService,
        private _overlay: Overlay
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };

        this._activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            const cns = params.get('cns');

            if (!!cns) {
                this._fuseProgressBarService.show();

                const obsTipoServico = this._tipoServicoController.listarTiposServicoAtendimento(
                    cns
                );

                const obscartorio = this._cartorioController.listarDadosCartorioHomeDTO(
                    cns
                );

                forkJoin({
                    cartorio: obscartorio,
                    tiposServico: obsTipoServico,
                }).subscribe(
                    ({ cartorio, tiposServico }) => {
                        this.cns = cns;
                        this.dadosCartorio = cartorio;
                        
                        this.tiposServico = tiposServico;
                        this.carregando = false;

                        this.urlSuporteWhatsApp = `https://wa.me/${this.sanitizarTelefone(
                            this.dadosCartorio.telefoneSuporteWhatsApp
                        )}?text=Ol%C3%A1%20quero%20retirar%20d%C3%BAvidas%20sobre%20o%20cart%C3%B3rio`;
                    },
                    () => {
                        this.carregando = false;
                    }
                );
            } else {
                this._fuseProgressBarService.show();
                this._configuracoesController
                    .listarConfiguracoesGerais()
                    .subscribe(
                        (config) => {
                            this.cns = cns;
                            this.dadosConfiguracao = config;
                            this.carregando = false;
                        },
                        () => {
                            this.carregando = false;
                        }
                    );
            }
        });
    }

    public chatAtendimentoVirtual(): void {
        this.tipoAtendimento = TipoAtendimentoCartorio.Virtual;
        this.perguntas = true;
    }

    public retornarParaTelaInicial(): void {
        this.perguntas = false;
    }

    public chatAtendimentoPresencial(): void {
        this.tipoAtendimento = TipoAtendimentoCartorio.Presencial;
        this.perguntas = true;
    }

    public exibirRetirarDuvidas(tipoServico: TipoServicoAtendimento): void {
        this._dialogService.dialogGenerico(DialogTirarDuvidaComponent, {
            scrollStrategy: this._overlay.scrollStrategies.noop(),
            height: 'auto',
            maxHeight: '80%',
            width: '40%',
            maxWidth: '40%',
            data: {
                tipoServico: tipoServico,
            },
        });
    }

    public verificarDetalhesAgendamento(): void {
        this._dialogService.dialogGenerico(DialogDetalhesAgendamentoComponent, {
            scrollStrategy: this._overlay.scrollStrategies.block(),
            height: 'auto',
            maxHeight: '80%',
            width: '80%',
            maxWidth: '80%',
            data: {
                dadosCartorio: this.dadosCartorio,
            },
        });
    }

    private sanitizarTelefone(telefone: string): string {
        if (telefone != undefined){
            let temp = telefone.replace(/\D/g, '');
            if (telefone.startsWith('55')) {
                temp = telefone.substr(2);
            }

            const ddd = temp.substring(0, 2);
            temp = temp.substr(2);

            if (temp.length === 9) {
                temp = temp.substr(1);
            }
            return `55${ddd}${temp}`;
        }
        return ``;
    }
}
