import { TipoAtendimentoUsuario } from '../_enums';

export class UsuarioCartorio {
    cnsCartorio: string;
    id: string;
    nome: string;
    email: string;
    senha: string;
    tipoAtendimento: TipoAtendimentoUsuario;
}
