import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DadosCartorio } from '../../models';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '..';
import { BaseControllerService } from './base-controller.service';
import { ExpedienteCartorio } from '../../models/cartorio';
import { DadosCartorioHomeDTO } from '../../models/dtos/dados-cartorio-home-dto.model';
import { ConfigService } from 'app/config/config.service';

@Injectable({
    providedIn: 'root',
})
export class CartorioControllerService extends BaseControllerService {
    constructor(
        _http: HttpClient,
        _authenticationService: AuthenticationService,
        configService: ConfigService
    ) {
        super(_http, _authenticationService, configService);
    }

    listarDadosCartorios(): Observable<Array<DadosCartorio>> {
        return this.get<Array<DadosCartorio>>('cartorio');
    }

    listarDadosCartorio(cns: string): Observable<DadosCartorio> {
        return this.get<DadosCartorio>(`cartorio/${cns}`);
    }

    listarDadosCartorioHomeDTO(cns: string): Observable<DadosCartorioHomeDTO> {
        return this.get<DadosCartorioHomeDTO>(`cartorio/${cns}/toHome`);
    }

    validarCnsCartorioExistente(cns: string): Observable<boolean> {
        return this.get<boolean>(`cartorio/validarCnsCartorioExistente/${cns}`);
    }

    listarConfiguracoesExpediente(
        cns: string
    ): Observable<Array<ExpedienteCartorio>> {
        return this.get<Array<ExpedienteCartorio>>(
            `cartorio/listarConfiguracoesExpediente/${cns}`
        );
    }

    deletarCartorio(cns: string): Observable<void> {
        return this.delete(`cartorio/${cns}`);
    }

    alterarCartorio(cartorio: DadosCartorio): Observable<DadosCartorio> {
        return this.put<DadosCartorio, DadosCartorio>(
            `cartorio/${cartorio.cns}`,
            cartorio
        );
    }

    alterarDadosBasicosCartorio(cartorio: {
        cns: string;
        nomeAtendenteVirtual: string;
        minutosDuracaoPadraoAtendimento: number;
        telefoneSuporteWhatsApp: string;
    }): Observable<DadosCartorio> {
        return this.put(`cartorio/${cartorio.cns}/basicos`, cartorio);
    }

    alterarOrientacoesLgpd(cns: string, orientacoes: string) {
        return this.patch(`cartorio/${cns}/orientacoeslgpd`, { orientacoes });
    }

    inserirCartorio(cartorio: DadosCartorio): Observable<DadosCartorio> {
        return this.post<DadosCartorio, DadosCartorio>(`cartorio`, cartorio);
    }

    gravarExpedienteCartorio(
        cns: string,
        arrayExpediente: Array<ExpedienteCartorio>
    ): Observable<void> {
        return this.put<void, Array<ExpedienteCartorio>>(
            `cartorio/gravarExpedienteCartorio/${cns}`,
            arrayExpediente
        );
    }
}
