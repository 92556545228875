import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "./config.model";

@Injectable({
    providedIn: 'root'
  })
  export class ConfigService {
    public config: Config;
    private http: HttpClient;
  
    constructor(private handler: HttpBackend) {
        this.http = new HttpClient(handler);
    }
  
    async loadConfig(): Promise<void> {
      return this.http
        .get<Config>('assets/config.json')
        .toPromise()
        .then(config => {
          this.config = config;
        });
    }
  }