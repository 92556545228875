<div class="page-layout carded fullwidth" id="video-conferencia">
    <div class="top-bg accent"></div>
    <div class="center">
        <div class="header accent p-24">
            <h2 class="m-0">Vídeo conferência</h2>
        </div>
        <div class="content-card">
            <div class="content" fxLayout="row" fxFlex *ngIf="!this.conferenciaEmAndamento"
                fxLayoutAlign="center center">
                <button mat-raised-button color="primary" (click)="this.iniciarVideoConferencia()">Iniciar vídeo
                    conferência</button>
            </div>
            <meeting class="content" fxLayout="row" fxFlex fxLayoutAlign="center stretch"
                (eventoAoFechar)="this.fecharVideoConferencia()" *ngIf="this.conferenciaEmAndamento"></meeting>
        </div>
    </div>
</div>