<h1 mat-dialog-title>Alterar agendamento realizado</h1>
<mat-dialog-content mat-dialog-content>
    <h2 *ngIf="this.datasDisponiveis.length > 0">
        Selecione o horário desejado:
    </h2>

    <div
        fxLayout="column"
        fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="center center"
        *ngIf="this.datasDisponiveis.length > 0"
    >
        <mat-form-field fxFlex="25">
            <mat-label>Data</mat-label>
            <input
                matInput
                [matDatepickerFilter]="this.filtroDatas"
                disabled
                [matDatepicker]="picker"
                (dateChange)="this.selecionarData($event)"
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="picker"
                disabled="false"
            ></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker disabled="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="20">
            <mat-label>Horário</mat-label>
            <mat-select
                [disabled]="this.horariosDisponiveis.length === 0"
                [(ngModel)]="this.horarioSelecionado"
            >
                <mat-option
                    [value]="horario"
                    *ngFor="let horario of this.horariosDisponiveis"
                >
                    {{ horario }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button
            mat-stroked-button
            aria-label="Alterar horário do agendamento"
            matTooltip="Alterar horário do agendamento"
            color="accent"
            fxFlex
            class="botao-pesquisa ml-4"
            (click)="this.realizarAlteracaoDeAgendamento()"
        >
            Realizar alteração
            <mat-icon>alarm</mat-icon>
        </button>
    </div>
</mat-dialog-content>

<mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end center"
    class="text-right"
>
    <button mat-stroked-button color="accent" class="h-42" mat-dialog-close>
        Fechar
    </button>
</mat-dialog-actions>
