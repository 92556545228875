<h1 mat-dialog-title>
    {{ this.dados.tipoServico.descricao }}
</h1>
<mat-dialog-content mat-dialog-content class="conteudo-mensagem" [fxShow]="!this.carregandoEditor">
    <editor
        [init]="this.configuracoesEditor"
        [disabled]="true"
        [(ngModel)]="this.dados.tipoServico.mensagemFinalAtendimento"
        (onInit)="this.onInitEditor()"
    >
    </editor>
</mat-dialog-content>
<mat-dialog-content mat-dialog-content class="conteudo-mensagem" [fxShow]="this.carregandoEditor">
    <mat-progress-spinner 
        color="accent"
        mode="indeterminate">
    </mat-progress-spinner>
</mat-dialog-content>
<mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end center"
    class="text-right"
>
    <button mat-stroked-button color="accent" mat-dialog-close [disabled]="this.carregandoEditor">
        Fechar
    </button>
</mat-dialog-actions>
