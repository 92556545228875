import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
    selector: 'video-cliente',
    templateUrl: './video-cliente.component.html',
    styleUrls: ['./video-cliente.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class VideoClienteComponent implements OnInit {
    public exibirVideo = false;
    public agradecimento = false;
    public aguarde = true;
    public idSala = '';

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _activatedRoute: ActivatedRoute
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }

    ngOnInit(): void {
        this._activatedRoute.paramMap.subscribe((params: ParamMap) => {
            this.idSala = params.get('idSala');
            console.log(this.idSala);
            setTimeout(() => {
                this.exibirVideo = true;
                this.agradecimento = false;
                this.aguarde = false;
            }, 500);
        });
    }

    public fecharVideoConferencia(): void {
        this.exibirVideo = false;
        this.agradecimento = true;
        this.aguarde = false;
    }
}
