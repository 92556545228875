
export function configuracaoPadraoEditor(heightEditor: string = '500', urlDominio: string): object {
    return {
        base_url: urlDominio + 'tinymce' || '/tinymce',
        language: 'pt_BR',
        suffix: '.min',
        menubar: false,
        height: heightEditor,
        nonbreaking_force_tab: true,
        pagebreak_separator: '<p>&#x21A1;</p>',
        resize: false,
        force_br_newlines: true,
        force_p_newlines: false,
        forced_root_block: '',
        autoresize_on_init: true,
        max_height: +heightEditor,
        min_height: +heightEditor,
        table_default_styles: {
            width: '100%',
        },
        plugins: [
            'advlist lists link image print preview fullscreen media table',
        ],
        toolbar:
            'undo redo | formatselect | ' +
            'bold italic forecolor backcolor | removeformat link | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            ' preview print fullscreen |',
        fontsize_formats: '8pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
    };
}

export function configuracaoEditorReadOnly(
    heightEditor: string = '500',
    urlDominio: string
): object {
    return {
        base_url: urlDominio + 'tinymce' || '/tinymce',
        readonly: true,
        language: 'pt_BR',
        suffix: '.min',
        height: heightEditor,
        nonbreaking_force_tab: true,
        pagebreak_separator: '<p>&#x21A1;</p>',
        resize: false,
        force_br_newlines: true,
        force_p_newlines: false,
        forced_root_block: '',
        autoresize_on_init: true,
        max_height: +heightEditor,
        min_height: +heightEditor,
        table_default_styles: {
            width: '100%',
        },
        menubar: false,
        inline: true,
        toolbar: true,
        plugins: [],
        preview_styles: 'font-size color',
    };
}
