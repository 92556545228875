import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { HammerModule } from '@angular/platform-browser';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { HomeModule } from 'app/main/home/home.module';
import { AdminModule } from './main/admin/admin.module';
import { ConfiguracoesModule } from './main/configuracoes/configuracoes.module';
import { VideoConferenciaModule } from './main/video-conferencia/video-conferencia.module';
import { VideoClienteModule } from './main/video-cliente/video-cliente.module';
import { ComponentesModule } from './main/_shared/componentes/componentes.module';
import { ServicosModule } from './main/_shared/servicos/servicos.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AtendimentoModule } from './main/atendimento/atendimento.module';
import { CartorioGuard } from './main/_shared/guards/cartorio.guard';
import { CartsysGuard } from './main/_shared/guards/cartsys.guard';
import { JwtInterceptor } from './main/_shared/interceptors/jwt-interceptor';
import { ErrorInterceptor } from './main/_shared/interceptors/error-interceptor';
import { ErrorsModule } from './main/_shared/errors/errors.module';
import { AdminCartorioGuard } from './main/_shared/guards/admin-cartorio.guard';
import { AdminEUsuarioCartorioGuard } from './main/_shared/guards/admin-e-usuario-cartorio.guard';
import { ResetPasswordModule } from './main/reset-password/reset-password.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgxPrintModule } from 'ngx-print';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ConfigService } from './config/config.service';

const appRoutes: Routes = [
    {
        path: '**',
        redirectTo: '/errors/error-404',
    },
];
export const configFactory = (configService: ConfigService) => {
    return () => configService.loadConfig();
};
  
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HammerModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        NgxMaskModule.forRoot(),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        CommonModule,
        ServicosModule,

        // App modules
        LayoutModule,
        HomeModule,
        AdminModule,
        ResetPasswordModule,
        ConfiguracoesModule,
        VideoConferenciaModule,
        VideoClienteModule,
        ComponentesModule,
        AtendimentoModule,
        ErrorsModule,
        EditorModule,
        NgxPrintModule,
        MatToolbarModule
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        AdminCartorioGuard,
        AdminEUsuarioCartorioGuard,
        CartorioGuard,
        CartsysGuard,
        DatePipe,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: APP_INITIALIZER,
            useFactory: configFactory,
            deps: [ConfigService],
            multi: true
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
