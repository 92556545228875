<div class="page-layout simple tabbed" id="configuracoes-cartorio">
    <div class="header accent">
        <h2>
            Configurações
            <span *ngIf="!!this.dadosCartorio">
                - Cartório {{ this.dadosCartorio.nome }}, CNS:
                {{ this.dadosCartorio.cns }}
            </span>
        </h2>
    </div>
    <div class="content" fxLayout="column" fxLayoutAlign="center space-around">
        <mat-card
            class="mat-elevation-z1 card-configuracoes-basicas"
            fxLayout="column"
        >
            <mat-card-subtitle>Configurações básicas</mat-card-subtitle>
            <mat-card-content class="pb-12">
                <form [formGroup]="this.form">
                    <div
                        fxLayout="column"
                        fxLayout.gt-sm="row"
                        class="fix-form-field-wrapper"
                    >
                        <mat-form-field appearance="outline" fxFlex.gt-sm="50">
                            <mat-label>Nome atendente virtual</mat-label>
                            <input
                                matInput
                                formControlName="nomeAtendenteVirtual"
                                type="text"
                                maxlength="20"
                            />
                            <mat-icon matSuffix class="secondary-text"
                                >account_box</mat-icon
                            >
                            <mat-error
                                *ngIf="
                                    this.form
                                        .get('nomeAtendenteVirtual')
                                        .hasError('required')
                                "
                            >
                                Nome do atendente virtual é obrigatório
                            </mat-error>
                            <mat-error
                                *ngIf="
                                    this.form
                                        .get('nomeAtendenteVirtual')
                                        .hasError('maxLength')
                                "
                            >
                                Nome do atendente virtual inválido
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex.gt-sm="20">
                            <mat-label>Duração padrão do atendimento</mat-label>
                            <input
                                matInput
                                formControlName="minutosDuracaoPadraoAtendimento"
                                type="text"
                                maxlength="2"
                            />
                            <mat-icon matSuffix class="secondary-text"
                                >access_time</mat-icon
                            >
                            <mat-error
                                *ngIf="
                                    this.form
                                        .get('minutosDuracaoPadraoAtendimento')
                                        .hasError('required')
                                "
                            >
                                Duração é obrigatória
                            </mat-error>

                            <mat-error
                                *ngIf="
                                    this.form
                                        .get('minutosDuracaoPadraoAtendimento')
                                        .hasError('min')
                                "
                            >
                                Duração mínima de 15 minutos
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex.gt-sm="30">
                            <mat-label>WhatsApp suporte</mat-label>
                            <input
                                matInput
                                formControlName="telefoneSuporteWhatsApp"
                                type="text"
                                [mask]="this.mascaraTelefone"
                            />
                            <mat-icon matSuffix class="secondary-text">
                                phone
                            </mat-icon>
                        </mat-form-field>
                    </div>

                    <div 
                        fxLayout="column"
                        fxLayout.gt-sm="row"
                        class="fix-form-field-wrapper">
                        <button
                            mat-raised-button
                            color="accent"
                            class="botao-gravar-cfg-basica"
                            (click)="this.abrirManutencaoExpediente()"
                            fxFlex.gt-sm="25"
                        >
                            Configurar expediente
                        </button>

                        <button
                            mat-raised-button
                            color="accent"
                            class="botao-gravar-cfg-basica"
                            (click)="this.abrirExclusaoDados()"
                            fxFlex.gt-sm="25"
                        >
                            Exclusão de dados
                        </button>
                        
                        <button
                            mat-raised-button
                            color="accent"
                            class="botao-gravar-cfg-basica"
                            (click)="this.abrirOrientacoesLGPD()"
                            fxFlex.gt-sm="25"
                        >
                            Orientações da LGPD
                        </button>

                        <button
                            mat-raised-button
                            color="accent"
                            [disabled]="this.form.invalid"
                            class="botao-gravar-cfg-basica"
                            (click)="this.gravarConfiguracoesBasicas()"
                            fxFlex.gt-sm="15"
                        >
                            Gravar
                        </button>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>

        <div
            class="card-configuracoes-usuario"
            fxLayout="column"
            fxLayout.gt-md="row"
        >
            <mat-card
                class="mat-elevation-z1 mr-4"
                fxLayout="column"
                fxFlex.gt-md="50"
            >
                <mat-card-subtitle>Tipos de serviço</mat-card-subtitle>
                <table mat-table [dataSource]="this.dataSourceTiposServico">
                    <ng-container matColumnDef="descricao">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Descrição
                        </th>
                        <td mat-cell *matCellDef="let tipoServico">
                            {{ tipoServico.descricao }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tipoAtendimento">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Tipo de atendimento
                        </th>
                        <td mat-cell *matCellDef="let tipoServico">
                            {{
                                this.retornarDescricaoTipoAtendimento(
                                    tipoServico
                                )
                            }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="buttons">
                        <th mat-header-cell *matHeaderCellDef class="pl-12">
                            Ações
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let tipoServico"
                            class="pr-24"
                        >
                            <div fxLayout="row">
                                <button
                                    mat-button
                                    matTooltip="Editar tipo de serviço"
                                    class="p-0 w-36"
                                    (click)="
                                        this.abrirManutencaoTipoServicoAtendimento(
                                            tipoServico
                                        )
                                    "
                                >
                                    <mat-icon class="material-icons">
                                        edit
                                    </mat-icon>
                                </button>
                                <button
                                    mat-button
                                    class="p-0 w-36"
                                    matTooltip="Excluir tipo de serviço"
                                    (click)="
                                        this.excluirTipoServicoAtendimento(
                                            tipoServico
                                        )
                                    "
                                >
                                    <mat-icon class="material-icons">
                                        delete
                                    </mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr
                        mat-header-row
                        *matHeaderRowDef="this.displayedColumnsTiposServico"
                    ></tr>
                    <tr
                        mat-row
                        *matRowDef="
                            let row;
                            columns: this.displayedColumnsTiposServico
                        "
                    ></tr>
                </table>
                <mat-toolbar class="mt-8">
                    <mat-toolbar-row
                        fxLayoutAlign="space-between center"
                        fxLayout="row wrap"
                        class="footer-paginacao"
                    >
                        <button
                            mat-button
                            mat-raised-button
                            color="accent"
                            matTooltip="Adicionar tipo de serviço"
                            (click)="
                                this.abrirManutencaoTipoServicoAtendimento()
                            "
                        >
                            Adicionar
                        </button>
                        <span class="example-spacer"></span>
                        <mat-paginator
                            #paginatorTiposServico
                            [pageSizeOptions]="[3, 5, 10, 15]"
                        ></mat-paginator>
                    </mat-toolbar-row>
                </mat-toolbar>
            </mat-card>

            <mat-card
                class="mat-elevation-z1 ml-4"
                fxLayout="column"
                fxFlex.gt-md="50"
            >
                <mat-card-subtitle>Usuários</mat-card-subtitle>
                <table mat-table [dataSource]="this.dataSourceUsuarios">
                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Id
                        </th>
                        <td mat-cell *matCellDef="let usuario">
                            {{ usuario.id }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="nome">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Nome
                        </th>
                        <td mat-cell *matCellDef="let usuario">
                            {{ usuario.nome }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Email
                        </th>
                        <td mat-cell *matCellDef="let usuario">
                            {{ usuario.email }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="buttons">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="header-acoes"
                        >
                            Ações
                        </th>
                        <td mat-cell *matCellDef="let usuario" class="pr-24">
                            <div fxLayout="row">
                                <button
                                    mat-button
                                    matTooltip="Editar usuário"
                                    (click)="
                                        this.abrirManutencaoUsuarios(usuario)
                                    "
                                    class="p-0 w-36"
                                >
                                    <mat-icon class="material-icons">
                                        edit
                                    </mat-icon>
                                </button>
                                <button
                                    mat-button
                                    matTooltip="Excluir usuário"
                                    (click)="this.excluirUsuario(usuario)"
                                    class="p-0 w-36"
                                >
                                    <mat-icon class="material-icons">
                                        delete
                                    </mat-icon>
                                </button>
                                <button
                                    mat-button
                                    [disabled]="
                                        !this.form.get(
                                            'minutosDuracaoPadraoAtendimento'
                                        ).value
                                    "
                                    matTooltip="Editar horarios de atendimento do usuário"
                                    (click)="
                                        this.abrirAtendimentoUsuario(usuario)
                                    "
                                    class="p-0 w-36"
                                >
                                    <mat-icon class="material-icons">
                                        alarm
                                    </mat-icon>
                                </button>
                                <button
                                    mat-button
                                    matTooltip="Editar tipos de serviço do usuário"
                                    (click)="
                                        this.abrirTiposServicoUsuario(usuario)
                                    "
                                    class="p-0 w-36"
                                >
                                    <mat-icon class="material-icons">
                                        settings
                                    </mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr
                        mat-header-row
                        *matHeaderRowDef="this.displayedColumnsUsuarios"
                    ></tr>
                    <tr
                        mat-row
                        *matRowDef="
                            let row;
                            columns: this.displayedColumnsUsuarios
                        "
                    ></tr>
                </table>
                <mat-toolbar class="mt-8">
                    <mat-toolbar-row
                        fxLayoutAlign="space-between center"
                        fxLayout="row wrap"
                        class="footer-paginacao"
                    >
                        <button
                            mat-button
                            mat-raised-button
                            color="accent"
                            matTooltip="Adicionar usuário"
                            (click)="this.abrirManutencaoUsuarios()"
                        >
                            Adicionar
                        </button>
                        <span class="example-spacer erro-duracao">
                            <span
                                *ngIf="
                                    !this.form.get(
                                        'minutosDuracaoPadraoAtendimento'
                                    ).value
                                "
                            >
                                Para alterar os horários do usuário é necessário
                                definir a duração padrão do atendimento
                            </span>
                        </span>
                        <mat-paginator
                            #paginatorUsuarios
                            [pageSizeOptions]="[3, 5, 10, 15]"
                        ></mat-paginator>
                    </mat-toolbar-row>
                </mat-toolbar>
            </mat-card>
        </div>
    </div>
</div>
