import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { AuthenticationService, ToastService } from '../servicos';
import { TipoLogin } from '../models/_enums';

@Injectable({ providedIn: 'root' })
export class BaseLoginGuard implements CanActivate {
    constructor(
        protected _router: Router,
        protected _authenticationService: AuthenticationService,
        protected _toastService: ToastService,
        protected _tiposLogin: TipoLogin[]
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree {
        if (!!!this._authenticationService.usuarioAtual) {
            return this._router.createUrlTree([''], {
                queryParams: { returnUrl: state.url },
            });
        }

        const podeAcessar = this._tiposLogin.includes(
            this._authenticationService.usuarioAtual.tipoLogin
        );

        if (!podeAcessar) {
            this._toastService.mensagemErro(
                'Não é possível acessar essa rotina'
            );
        }

        return podeAcessar;
    }
}
