import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../servicos';
import { Route, Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                if (err.status && (err.status === 401 || err.status === 403)) {
                    console.log('Erro de autenticação');
                    console.log(err);
                    this.authenticationService.logout();
                    location.reload();
                }

                if (err.status && err.stats === 404) {
                    this.router.navigate(['errors/error-404']);
                }

                if (err.status && err.stats === 500) {
                    this.router.navigate(['errors/error-500']);
                }

                console.log(err);
                const error = err.error || err.message || err.statusText;

                return throwError(error);
            })
        );
    }
}
