<h1 mat-dialog-title>Manutenção de tipos de serviço</h1>
<mat-dialog-content mat-dialog-content>
    <form
        id="usuario-form"
        fxLayout="column"
        fxLayoutAlign="center stretch"
        name="form"
        [formGroup]="this.form"
    >
        <div fxFlex="100" fxLayout="column" fxLayout.gt-sm="row">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50">
                <mat-label>Descrição</mat-label>
                <input matInput type="text" formControlName="descricao" />
                <mat-error *ngIf="form.get('descricao').hasError('required')">
                    Descrição obrigatória
                </mat-error>
                <mat-error *ngIf="form.get('descricao').hasError('maxlength')">
                    Tamanho máximo (100)
                </mat-error>
            </mat-form-field>

            <mat-form-field
                appearance="outline"
                fxFlex.xs="100"
                fxFlex.gt-xs="50"
            >
                <mat-label>Tipo de atendimento</mat-label>
                <mat-select formControlName="tipoAtendimento">
                    <mat-option [value]="this.tipoAtendimento.ApenasPresencial"
                        >Apenas presencial</mat-option
                    >
                    <mat-option [value]="this.tipoAtendimento.ApenasVirtual"
                        >Apenas virtual</mat-option
                    >
                    <mat-option
                        [value]="this.tipoAtendimento.VirtualEPresencial"
                        >Virtual e presencial</mat-option
                    >
                </mat-select>
                <mat-error
                    *ngIf="form.get('tipoAtendimento').hasError('required')"
                >
                    Tipo de atendimento obrigatório
                </mat-error>
            </mat-form-field>
        </div>
        
        <div fxFlex="100" [fxShow]="this.carregandoEditor" fxLayoutAlign="space-around center">
            <mat-progress-spinner 
                color="accent"
                mode="indeterminate">
            </mat-progress-spinner>
        </div>

        <div fxFlex="100" fxLayout="column" [fxShow]="!this.carregandoEditor">
            <span class="pl-4 mb-12">
                Orientações para a execução do serviço:
            </span>
            <div
                appearance="outline"
                fxFlex="100"
                class="mb-16 pl-4"
                fxLayout="column"
            >
                <editor
                    [init]="this.configuracoesEditor"
                    [ngClass]="{
                        'erro-texto-vazio':
                            this.form
                                .get('mensagemFinalAtendimento')
                                .hasError('required') ||
                            this.form
                                .get('mensagemFinalAtendimento')
                                .hasError('maxlength')
                    }"
                    formControlName="mensagemFinalAtendimento"
                    fxFlex="100"
                    (onInit)="this.onInitEditor()"
                    required
                >
                </editor>

                <mat-error
                    *ngIf="
                        form
                            .get('mensagemFinalAtendimento')
                            .hasError('required')
                    "
                >
                    Mensagem final do atendimento obrigatória
                </mat-error>
                <mat-error
                    *ngIf="
                        form
                            .get('mensagemFinalAtendimento')
                            .hasError('maxlength')
                    "
                >
                    Tamanho máximo (65000)
                </mat-error>
            </div>
        </div>

        <h3 class="pl-4">Perguntas</h3>

        <div fxFlex="100" fxLayout="row">
            <mat-vertical-stepper fxFlex="100" #stepper class="perguntas">
                <mat-step
                    *ngFor="
                        let formPergunta of this.perguntasForm.controls;
                        let i = index
                    "
                    [formGroup]="formPergunta"
                    fxFlex="100"
                    fxLayout="column"
                >
                    <ng-template matStepLabel>
                        {{formPergunta.get('texto').value}}
                    </ng-template>

                    <div fxFlex="100" fxLayout="row">
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Texto da pergunta</mat-label>
                            <input
                                matInput
                                type="text"
                                formControlName="texto"
                            />
                            <mat-error
                                *ngIf="
                                    formPergunta
                                        .get('texto')
                                        .hasError('required')
                                "
                            >
                                O texto da pergunta é obrigatório
                            </mat-error>

                            <mat-error
                                *ngIf="
                                    formPergunta
                                        .get('texto')
                                        .hasError('maxlength')
                                "
                            >
                                Tamanho máximo (200)
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxFlex="100" fxLayout="column" fxLayout.gt-sm="row">
                        <mat-form-field
                            appearance="outline"
                            fxFlex.xs="100"
                            fxFlex.gt-xs="50"
                        >
                            <mat-label
                                >Tipo de resposta para a pergunta</mat-label
                            >
                            <mat-select formControlName="tipo">
                                <mat-option [value]="this.tipoResposta.Texto">
                                    Texto
                                </mat-option>

                                <mat-option [value]="this.tipoResposta.Imagem">
                                    Número
                                </mat-option>

                                <mat-option [value]="this.tipoResposta.Mascara">
                                    Texto com máscara
                                </mat-option>

                                <mat-option [value]="this.tipoResposta.Imagem">
                                    Imagem
                                </mat-option>
                                
                                <mat-option [value]="this.tipoResposta.Arquivo">
                                    Arquivo
                                </mat-option>

                                <mat-option [value]="this.tipoResposta.MultiplaEscolha">
                                    Múltipla escolha
                                </mat-option>
                            </mat-select>
                            <mat-error
                                *ngIf="
                                    formPergunta
                                        .get('tipo')
                                        .hasError('required')
                                "
                            >
                                Tipo de resposta obrigatória
                            </mat-error>
                        </mat-form-field>
                        
                        <mat-form-field
                            appearance="outline"
                            fxFlex.xs="100"
                            fxFlex.gt-xs="50"
                            *ngIf="formPergunta
                            .get('tipo').value && formPergunta
                            .get('tipo').value == this.tipoResposta.Mascara"
                        >
                            <mat-label>Tipo de máscara</mat-label>
                            <mat-select formControlName="mascara">
                                <mat-option [value]="this.tipoMascara.Nenhuma"
                                    >Nenhuma</mat-option
                                >
                                <mat-option [value]="this.tipoMascara.Telefone"
                                    >Telefone</mat-option
                                >
                                <mat-option [value]="this.tipoMascara.Cpf"
                                    >CPF</mat-option
                                >
                                <mat-option [value]="this.tipoMascara.Cep"
                                    >CEP</mat-option
                                >
                            </mat-select>
                            <mat-error
                                *ngIf="
                                    formPergunta
                                        .get('mascara')
                                        .hasError('required')
                                "
                            >
                                Tipo de máscara obrigatória
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field
                            id="opcoes-pergunta"
                            appearance="outline"
                            fxFlex.xs="100"
                            fxFlex.gt-xs="50"
                            *ngIf="formPergunta.get('tipo').value && formPergunta.get('tipo').value == this.tipoResposta.MultiplaEscolha">
                            <mat-chip-list #chipList aria-label="Seleção de múltipla escolha">
                                <mat-chip *ngFor="let opcao of this.opcoesPerguntas[formPergunta.get('indice').value]" [selectable]="true" [removable]="true"
                                    (removed)="removerOpcaoMultiplaEscolha(opcao, formPergunta.get('indice').value)">
                                    {{opcao}}
                                    <mat-icon matChipRemove *ngIf="opcao">cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="Inserir opção (multipla escolha)"
                               [matChipInputFor]="chipList"
                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                               [matChipInputAddOnBlur]="true"
                               (matChipInputTokenEnd)="addicionarOpcaoMultiplaEscolha($event, formPergunta.get('indice').value)">
                            </mat-chip-list>
                        </mat-form-field>
                    </div>

                    <div>
                        <button
                            mat-stroked-button
                            color="primary"
                            matStepperPrevious
                            *ngIf="i > 0"
                        >
                            Anterior
                        </button>
                        <button
                            mat-stroked-button
                            color="primary"
                            [ngClass]="i > 0 ? 'ml-8' : ''"
                            matStepperNext
                            *ngIf="i < this.perguntasForm.controls.length - 1"
                        >
                            Próxima
                        </button>
                        <button
                            mat-stroked-button
                            color="accent"
                            [ngClass]="
                                this.perguntasForm.controls.length > 1
                                    ? 'ml-8'
                                    : ''
                            "
                            (click)="this.excluirPergunta(i)"
                        >
                            Excluir pergunta
                        </button>
                    </div>
                </mat-step>
            </mat-vertical-stepper>
        </div>

        <div>
            <button
                mat-raised-button
                color="accent"
                type="button"
                [disabled]="this.carregandoEditor"
                (click)="this.adicionarPergunta()"
            >
                Adicionar Pergunta
            </button>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end end" class="text-right">
    <button mat-stroked-button color="accent" mat-dialog-close [disabled]="this.carregandoEditor">
        Fechar
    </button>
    <button
        mat-raised-button
        color="accent"
        class="ml-8"
        (click)="this.gravarDados()"
        [disabled]="this.carregandoEditor || this.form.invalid || this.perguntasForm.length === 0"
    >
        Salvar
    </button>
</mat-dialog-actions>
