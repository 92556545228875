import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, ToastService } from '../servicos';
import { TipoLogin } from '../models/_enums';
import { BaseLoginGuard } from './base-login.guard';

@Injectable({ providedIn: 'root' })
export class AdminEUsuarioCartorioGuard extends BaseLoginGuard {
    constructor(
        _router: Router,
        _authenticationService: AuthenticationService,
        _toastService: ToastService
    ) {
        super(_router, _authenticationService, _toastService, [
            TipoLogin.AdminCartorio,
            TipoLogin.Cartorio,
        ]);
    }
}
