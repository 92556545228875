import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { FuseWidgetModule, FuseSearchBarModule } from '@fuse/components';
import { MatOptionModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaskModule } from 'ngx-mask';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { AtendimentoComponent } from './atendimento.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ManutencaoAtendimentoComponent } from './manutencao-atendimento/manutencao-atendimento.component';
import { AdminEUsuarioCartorioGuard } from '../_shared/guards/admin-e-usuario-cartorio.guard';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const routes = [
    {
        path: 'atendimento',
        canActivate: [AdminEUsuarioCartorioGuard],
        component: AtendimentoComponent,
    },
];

@NgModule({
    declarations: [AtendimentoComponent, ManutencaoAtendimentoComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatDialogModule,
        MatButtonModule,
        MatTabsModule,
        MatToolbarModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        FuseSharedModule,
        FuseWidgetModule,
        FuseSearchBarModule,
        NgxMaskModule,
        MatCardModule,
        MatExpansionModule,
        MatMenuModule,
        MatTableModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatChipsModule,
        MatDividerModule,
        MatStepperModule,
        MatCheckboxModule,
        MatDatepickerModule,
        EditorModule,
        MatProgressSpinnerModule
    ],
    exports: [],
    providers: [{ provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }],
})
export class AtendimentoModule {}
