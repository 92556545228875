import { Component, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { DialogService } from 'app/main/_shared/servicos/dialogs';
import { DialogFormBase } from 'app/main/_shared/contratos';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgendamentoAtendimento } from 'app/main/_shared/models/cartorio';
import {
    AtendimentoControllerService,
    UsuarioControllerService,
} from 'app/main/_shared/servicos/controllers';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastService, AuthenticationService } from 'app/main/_shared/servicos';
import {
    TipoResposta,
    StatusAtendimentoUsuario,
    TipoAtendimentoCartorio,
    TipoLogin,
} from 'app/main/_shared/models/_enums';
import { MatStepper } from '@angular/material/stepper';
import { PerguntaRespostaAtendimento } from 'app/main/_shared/models/dtos/pergunta-resposta-atendimento.model';
import { LookupUsuarioDTO } from 'app/main/_shared/models/dtos/lookup-usuario-dto.model';
import { forkJoin } from 'rxjs';
import { GravacaoAgendamentoAtendimentoDTO } from 'app/main/_shared/models/dtos/gravacao-agendamento-atendimento-dto.model';
import { configuracaoPadraoEditor } from 'app/main/_shared/funcoes/configuracoes-editor';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs';
import { ConfigService } from 'app/config/config.service';

@Component({
    selector: 'dialog-manutencao-atendimento',
    templateUrl: './manutencao-atendimento.component.html',
    styleUrls: ['./manutencao-atendimento.component.scss'],
})
export class ManutencaoAtendimentoComponent extends DialogFormBase<
    ManutencaoAtendimentoComponent
> {
    private _dadosAgendamento: AgendamentoAtendimento;
    public usuariosAplicacao: Array<LookupUsuarioDTO>;
    public configuracoesEditor = configuracaoPadraoEditor('350', this._configService.config.urlDominio);
    public carregandoEditor: boolean;

    @ViewChild('tabGroup')
    public tabGroup: MatTabGroup;

    @ViewChild('stepper')
    public stepper: MatStepper;
    public activeTabIndex: number;
    readonly EDITOR_TAB = 1;

    constructor(
        private cdRef: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA)
        private _dados: {
            agendamento: AgendamentoAtendimento;
            descricaoTipoServico: string;
        },
        private _authenticationService: AuthenticationService,
        private _atendimentoController: AtendimentoControllerService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _usuarioController: UsuarioControllerService,
        private _toastService: ToastService,
        private _configService: ConfigService,
        public formBuilder: FormBuilder,
        public dialogService: DialogService,
        public dialogRef: MatDialogRef<ManutencaoAtendimentoComponent>
    ) {
        super(formBuilder, dialogService, dialogRef);
    }

    get tipoAtendimento(): typeof TipoAtendimentoCartorio {
        return TipoAtendimentoCartorio;
    }

    get tipoResposta(): typeof TipoResposta {
        return TipoResposta;
    }

    get statusAtendimento(): typeof StatusAtendimentoUsuario {
        return StatusAtendimentoUsuario;
    }

    get perguntasForm(): FormArray {
        return this.form.get('arrayPerguntas') as FormArray;
    }

    construirForm(formBuilder: FormBuilder): void {
        this.form = formBuilder.group({
            id: [{ value: '', disabled: true }],
            dataAgendamento: [{ value: '', disabled: true }],
            horaAgendamento: [{ value: '', disabled: true }],
            tipoAtendimento: [
                { value: TipoAtendimentoCartorio.Virtual, disabled: true },
            ],
            idTipoServico: [{ value: '', disabled: true }],
            descricaoTipoServico: [
                { value: this._dados.descricaoTipoServico, disabled: true },
            ],
            protocolo: [{ value: '', disabled: true }],
            nomeRequirente: [{ value: '', disabled: true }],
            cpf: [{ value: '', disabled: true }],
            telefone: [{ value: '', disabled: true }],
            idUsuario: [
                {
                    value: null,
                    disabled:
                        !this.ehUsuarioAdministradorCartorio() ||
                        (this._dados.agendamento.status ===
                            StatusAtendimentoUsuario.AtendimentoCancelado &&
                            this._dados.agendamento.horarioLiberado),
                },
            ],
            status: [
                {
                    value: StatusAtendimentoUsuario.Pendente,
                    disabled:
                        this._dados.agendamento.status ===
                            StatusAtendimentoUsuario.AtendimentoCancelado &&
                        this._dados.agendamento.horarioLiberado,
                },
                [Validators.required],
            ],
            observacaoAtendimento: [
                {
                    value: '',
                    disabled:
                        this._dados.agendamento.status ===
                            StatusAtendimentoUsuario.AtendimentoCancelado &&
                        this._dados.agendamento.horarioLiberado,
                },
                [Validators.maxLength(65000)],
            ],
            arrayPerguntas: formBuilder.array([]),
        });
    }

    isEditorTab(): boolean {
        return this.activeTabIndex === this.EDITOR_TAB;
    }

    handleTabChange(e: MatTabChangeEvent): void {
        this.activeTabIndex = e.index;
    }

    processamentoOnAfterViewInit(): void {
        setTimeout(() => {
            this.carregandoEditor = true;
        });
        
        this.activeTabIndex = this.tabGroup.selectedIndex;
        this.cdRef.detectChanges();
    }

    carregarDados(): void {
        this.carregarDadosAgendamento();
        this.carregarPerguntasTipoServico();
    }

    carregarDadosAgendamento(): void {
        this._dadosAgendamento = this._dados.agendamento;

        if (!this.ehUsuarioAdministradorCartorio()) {
            this._dadosAgendamento.idUsuario = this._authenticationService.usuarioAtual.id;
        }

        this.form.patchValue(this._dadosAgendamento);
    }

    carregarPerguntasTipoServico(): void {
        this._fuseProgressBarService.show();
        forkJoin({
            usuarios: this._usuarioController.listarUsuariosCartorioLookup(
                this._authenticationService.usuarioAtual.cns
            ),
            perguntas: this._atendimentoController.listarPerguntasComResposta(
                this._dadosAgendamento.id
            ),
        }).subscribe(
            ({ usuarios, perguntas }) => {
                this.usuariosAplicacao = usuarios;
                for (const pergunta of perguntas) {
                    this.adicionarPerguntaComResposta(pergunta);
                }

                this._fuseProgressBarService.hide();
            },
            (erro) => {
                console.log(erro);
                this._toastService.mensagemErro(
                    'Ocorreu um erro ao carregar os dados'
                );
                this._fuseProgressBarService.hide();
            }
        );
    }

    criarPerguntaForm(): FormGroup {
        return this._formBuilder.group({
            textoPergunta: [''],
            resposta: [''],
            tpResposta: [TipoResposta.Texto],
        });
    }

    adicionarPerguntaComResposta(pergunta: PerguntaRespostaAtendimento): void {
        const formGroup = this.criarPerguntaForm();

        formGroup.patchValue(pergunta);

        this.perguntasForm.push(formGroup);
    }

    gravarDados(): void {
        const gravacaoAgendamento = new GravacaoAgendamentoAtendimentoDTO(
            this.form.get('id').value as string,
            this.form.get('status').value as StatusAtendimentoUsuario,
            this.form.get('idUsuario').value as string,
            this.form.get('observacaoAtendimento').value as string
        );

        const dataAgendamento = new Date(
            this._dadosAgendamento.dataAgendamento
        );

        const horaEMinuto = this._dadosAgendamento.horaAgendamento.split(':');

        dataAgendamento.setHours(Number.parseInt(horaEMinuto[0], 10));
        dataAgendamento.setMinutes(Number.parseInt(horaEMinuto[1], 10));

        const agora = new Date();

        if (
            gravacaoAgendamento.status ===
                StatusAtendimentoUsuario.AtendimentoCancelado &&
            dataAgendamento > agora
        ) {
            this._dialogService
                .dialogPerguntaAsync(
                    'Atenção',
                    'Deseja liberar o horário para um novo agendamento? Ao liberar o horário, este agendamento não poderá mais ser editado.'
                )
                .subscribe((liberar: boolean) => {
                    gravacaoAgendamento.liberarHorario = liberar;
                    this.processarGravacao(gravacaoAgendamento);
                });
        } else {
            this.processarGravacao(gravacaoAgendamento);
        }
    }

    private processarGravacao(
        gravacaoAgendamento: GravacaoAgendamentoAtendimentoDTO
    ): void {
        console.log(gravacaoAgendamento);
        this._fuseProgressBarService.show();
        this._atendimentoController
            .gravarAgendamentoAtendimento(
                this._authenticationService.usuarioAtual.cns,
                gravacaoAgendamento
            )
            .subscribe(
                () => {
                    this._toastService.mensagemSuccesso(
                        'Dados armazenados com sucesso'
                    );
                    this._fuseProgressBarService.hide();
                    this._dialogRef.close();
                },
                (erro) => {
                    console.log(erro);
                    this._toastService.mensagemErro(
                        'Ocorreu um erro ao carregar os dados'
                    );
                    this._fuseProgressBarService.hide();
                }
            );
    }

    ehUsuarioAdministradorCartorio(): boolean {
        return (
            this._authenticationService.usuarioAtual.tipoLogin ===
            TipoLogin.AdminCartorio
        );
    }

    onInitEditor(): void {
        setTimeout(() => {
            this.carregandoEditor = false;
        });
    }
}
