import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfiguracaoGlobal } from '../models/configuracao-global.model';

@Injectable({ providedIn: 'root' })
export class ConfiguracaoGlobalService {
    private _currentConfig: BehaviorSubject<ConfiguracaoGlobal>;
    private TAG_CONFIG = 'globalConfig';

    constructor() {
        if (!localStorage.getItem(this.TAG_CONFIG)) {
            const config = new ConfiguracaoGlobal();
            this.setConfiguration(config);
        } else {
            const rawConfig = atob(localStorage.getItem(this.TAG_CONFIG));
            this._currentConfig = new BehaviorSubject<ConfiguracaoGlobal>(
                JSON.parse(rawConfig)
            );
        }
    }

    public get currentConfiguration(): ConfiguracaoGlobal {
        return this._currentConfig.value;
    }

    public set currentConfiguration(config: ConfiguracaoGlobal) {
        this.setConfiguration(config);
    }

    private setConfiguration(config: ConfiguracaoGlobal): void {
        this._currentConfig = new BehaviorSubject<ConfiguracaoGlobal>(config);
        const json = JSON.stringify(config);
        const base64 = btoa(json);
        localStorage.setItem(this.TAG_CONFIG, base64);
    }
}
