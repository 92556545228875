import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FuseSharedModule } from '@fuse/shared.module';
import { VideoClienteComponent } from 'app/main/video-cliente/video-cliente.component';
import { ComponentesModule } from '../_shared/componentes';

const routes = [
    {
        path: 'video-cliente/:idSala',
        component: VideoClienteComponent,
    },
];

@NgModule({
    declarations: [VideoClienteComponent],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        FuseSharedModule,
        ComponentesModule,
    ],
})
export class VideoClienteModule {}
