import {
    Component,
    ViewEncapsulation,
    AfterViewInit,
    Inject,
} from '@angular/core';
import { InformacoesAgendamentoDTO } from 'app/main/_shared/models/dtos/informacoes-agendamento-dto.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AtendimentoControllerService } from 'app/main/_shared/servicos/controllers';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { DialogService } from 'app/main/_shared/servicos/dialogs';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
    selector: 'alterar-horario-agendamento',
    templateUrl: './alterar-horario-agendamento.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AlterarHorarioAgendamentoComponent implements AfterViewInit {
    public datasDisponiveis: Array<Date> = [];
    public horariosDisponiveis: Array<string> = [];
    public horarioSelecionado: string;
    public dataSelecionada: Date;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public dados: {
            cns: string;
            dadosAgendamento: InformacoesAgendamentoDTO;
        },
        private _dialogRef: MatDialogRef<AlterarHorarioAgendamentoComponent>,
        private _atendimentoController: AtendimentoControllerService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _dialogService: DialogService
    ) {}

    ngAfterViewInit(): void {
        this._fuseProgressBarService.show();
        this._atendimentoController
            .listarDatasDisponiveis(
                this.dados.cns,
                new Date(),
                this.dados.dadosAgendamento.tipoAtendimento,
                this.dados.dadosAgendamento.idTipoServico
            )
            .subscribe(
                (datas) => {
                    this.datasDisponiveis = datas;
                    this._fuseProgressBarService.hide();
                },
                (erro) => {
                    this._fuseProgressBarService.hide();
                    console.log(erro);
                }
            );
    }

    public filtroDatas = (d: Date | null): boolean => {
        const diasDisponiveis = this.datasDisponiveis.filter((data) => {
            if (!d) {
                return false;
            }

            const dataFiltro = new Date(data);
            return (
                dataFiltro.getDate() === d.getDate() &&
                dataFiltro.getMonth() === d.getMonth() &&
                dataFiltro.getFullYear() === d.getFullYear()
            );
        });

        return diasDisponiveis.length > 0;
    };

    public selecionarData(event: MatDatepickerInputEvent<Date>): void {
        const data = event.value;

        setTimeout(() => {
            this.horariosDisponiveis = [];
            this.horarioSelecionado = undefined;
            this.dataSelecionada = new Date(
                Date.UTC(data.getFullYear(), data.getMonth(), data.getDate())
            );
        }, 50);

        this._fuseProgressBarService.show();
        this._atendimentoController
            .listarHorariosAtendimentoDisponiveis(
                this.dados.cns,
                data,
                this.dados.dadosAgendamento.tipoAtendimento,
                this.dados.dadosAgendamento.idTipoServico
            )
            .subscribe(
                (horarios) => {
                    setTimeout(() => {
                        this.horariosDisponiveis = horarios;
                    }, 50);

                    this._fuseProgressBarService.hide();
                },
                (erro) => {
                    this._fuseProgressBarService.hide();
                    console.log(erro);
                }
            );
    }

    public realizarAlteracaoDeAgendamento(): void {
        this._fuseProgressBarService.show();
        this._atendimentoController
            .alterarDataHoraAgendamento(
                this.dados.cns,
                this.dados.dadosAgendamento.idAgendamento,
                this.dataSelecionada,
                this.horarioSelecionado
            )
            .subscribe(
                () => {
                    this._fuseProgressBarService.hide();
                    this._dialogService.dialogInformacao(
                        'Atenção',
                        `O agendamento de protocolo ${this.dados.dadosAgendamento.protocolo} foi alterado com sucesso !`
                    );
                    this._dialogRef.close();
                },
                (erro) => {
                    this._fuseProgressBarService.hide();
                    console.log(erro);
                }
            );
    }
}
