import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'app/config/config.service';
import { Observable } from 'rxjs';
import { AuthenticationService } from '..';

export abstract class BaseControllerService {
    constructor(
        private http: HttpClient,
        protected _authenticationService: AuthenticationService,
        protected _configService: ConfigService
    ) {}

    protected get loginUsuario(): string {
        return this._authenticationService.usuarioAtual.email;
    }

    protected get<T>(rota: string): Observable<T> {
        return this.http.get<T>(`${this._configService.config.apiUrl}${rota}`);
    }

    protected post<T, TParam>(rota: string, body: TParam | any): Observable<T> {
        return this.http.post<T>(`${this._configService.config.apiUrl}${rota}`, body);
    }

    protected put<T, TParam>(rota: string, body: TParam | any): Observable<T> {
        return this.http.put<T>(`${this._configService.config.apiUrl}${rota}`, body);
    }

    protected patch<T, TParam>(
        rota: string,
        body: TParam | any
    ): Observable<T> {
        return this.http.patch<T>(`${this._configService.config.apiUrl}${rota}`, body);
    }

    protected delete<T>(rota: string): Observable<T> {
        return this.http.delete<T>(`${this._configService.config.apiUrl}${rota}`);
    }
}
