import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { VideoConferenciaComponent } from './video-conferencia.component';
import { FuseDemoModule, FuseSidebarModule } from '@fuse/components';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComponentesModule } from '../_shared/componentes';
import { CommonModule } from '@angular/common';
import { CartorioGuard } from '../_shared/guards/cartorio.guard';

const routes = [
    {
        path: 'video-conferencia',
        canActivate: [CartorioGuard],
        component: VideoConferenciaComponent,
    },
];

@NgModule({
    declarations: [VideoConferenciaComponent],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        MatButtonModule,
        MatIconModule,
        FuseSharedModule,
        FuseDemoModule,
        FuseSidebarModule,
        MatTooltipModule,
        ClipboardModule,
        ComponentesModule,
    ],
})
export class VideoConferenciaModule {}
