<h1 *ngIf="!this.listaAgendamentos || this.listaAgendamentos.length <= 0" mat-dialog-title>
    Verificar agendamentos realizados
</h1>
<mat-dialog-content *ngIf="!this.listaAgendamentos || this.listaAgendamentos.length <= 0" mat-dialog-content>
    <div fxLayout="row" class="text-center mb-16" fxLayoutAlign="center center">
        <span>
            Para verificar os dados do agendamento realizado, faça uma pesquisa
            com uma das informações abaixo.
        </span>
    </div>

    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="center center">
        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="20">
            <mat-label>Protocolo</mat-label>
            <input matInput type="text" (ngModelChange)="this.changeProtocolo()" [(ngModel)]="this.protocolo" />
        </mat-form-field>

        <span ngClass.lt-sm="label-ou-mobile" ngClass.gt-sm="h-36">OU</span>

        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="20">
            <mat-label>CPF</mat-label>
            <input matInput type="text" mask="000.000.000-00" [dropSpecialCharacters]="false"
                (ngModelChange)="this.changeCpf()" [(ngModel)]="this.cpfRequirente" />
        </mat-form-field>

        <span ngClass.lt-sm="label-ou-mobile" ngClass.gt-sm="h-36">OU</span>

        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="40">
            <mat-label>Nome do requirente</mat-label>
            <input matInput type="text" (ngModelChange)="this.changeNomeRequirente()"
                [(ngModel)]="this.nomeRequirente" />
        </mat-form-field>

        <button mat-stroked-button aria-label="Pesquisar agendamento" matTooltip="Pesquisar agendamento" color="accent"
            fxFlex="100" fxFlex.gt-sm class="botao-pesquisa ml-4" (click)="this.pesquisar()">
            Pesquisar
            <mat-icon>search</mat-icon>
        </button>
    </div>
</mat-dialog-content>

<div fxLayout="column" *ngIf="!!this.listaAgendamentos && this.listaAgendamentos.length > 0">
    <h1 class="titulo-agendamentos-realizados" mat-dialog-title>
        Agendamentos realizados
    </h1>

    <mat-dialog-content fxLayout="row wrap" fxLayoutAlign="center center" fxFlex="100" mat-dialog-content>
        <div class="widget-group p-8 container-widgets" fxLayout="row wrap" fxLayoutAlign="space-evenly stretch"
            fxFlex="100">
            <fuse-widget class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25" class="p-4"
                *ngFor="let agendamento of this.listaAgendamentos">
                <div class="fuse-widget-front">
                    <div class="pl-16 pr-8 py-16 h-40" fxLayout="row" fxLayoutAlign="space-between center">
                        <div class="h3">
                            {{ agendamento.dataAgendamento }}
                            - {{ agendamento.horaAgendamento }}
                        </div>
                    </div>

                    <div class="pt-8 pb-8" fxLayout="column" fxLayoutAlign="center center">
                        <div>
                            {{ agendamento.protocolo }}
                        </div>
                        <div>
                            {{ agendamento.servicoSolicitado }}
                        </div>
                        <div class="secondary-text font-weight-500">
                            {{
                            this.descricaoTipoAtendimento(
                            agendamento.tipoAtendimento
                            )
                            }}
                        </div>
                        <div>Nome: {{ agendamento.nomeRequirente }}</div>
                        <div>CPF: {{ agendamento.cpf }}</div>
                        <div>Tel.: {{ agendamento.telefone }}</div>
                    </div>

                    <div class="p-8 border-top" fxLayout="column" fxLayoutAlign="space-evenly stretch"
                        *ngIf="agendamento.podeAlterarAgendamento && agendamento.status === StatusAtendimentoUsuario.Pendente">
                        <button mat-stroked-button aria-label="Alterar Data/Horário do agendamento"
                            matTooltip="Alterar Data/Horário do agendamento" color="accent" fxFlex="100" class="mb-4"
                            (click)="
                                this.solicitarAlteracaodeAgendamento(
                                    agendamento
                                )
                            ">
                            Alterar Data/Horário
                            <mat-icon>alarm</mat-icon>
                        </button>

                        <button mat-stroked-button aria-label="Cancelar agendamento" matTooltip="Cancelar Data/Horário"
                            color="accent" fxFlex="100" (click)="
                                this.solicitarCancelamentoAgendamento(
                                    agendamento
                                )
                            ">
                            Cancelar agendamento
                            <mat-icon>alarm_off</mat-icon>
                        </button>
                    </div>

                    <div class="p-8 border-top" fxLayout="row" fxLayoutAlign="center center"
                        *ngIf="!agendamento.podeAlterarAgendamento || agendamento.status !== StatusAtendimentoUsuario.Pendente">

                        <span *ngIf="!agendamento.podeAlterarAgendamento && agendamento.clienteNaoCompareceu"
                            class="nao-pode-alterar ml-8" fxLayout="row" fxLayoutAlign="center center" fxFill>
                            Este agendamento não foi executado, o solicitante {{ agendamento.tipoAtendimento ===
                            TipoAtendimentoCartorio.Presencial
                            ? 'não compareceu no cartório.'
                            : 'não participou do atendimento virtual.' }}
                        </span>

                        <span *ngIf="agendamento.status === StatusAtendimentoUsuario.Pendente && 
                                    !agendamento.podeAlterarAgendamento && !agendamento.clienteNaoCompareceu"
                            class="nao-pode-alterar ml-8" fxLayout="row" fxLayoutAlign="center center" fxFill>
                            Este agendamento não pode ser alterado pois ele está
                            agendado para hoje. Caso deseje cancelar este
                            agendamento, entre em contato com o cartório por
                            meio da opção "RETIRAR DÙVIDAS VIA WHATSAPP"
                        </span>

                        <span
                            *ngIf="agendamento.status === StatusAtendimentoUsuario.Atendido || agendamento.status === StatusAtendimentoUsuario.AtendimentoCancelado"
                            class="ml-8" fxLayout="row" fxLayoutAlign="center center" fxFill
                            [ngClass]="{'atendimento-realizado': agendamento.status === StatusAtendimentoUsuario.Atendido,
                                'atendimento-cancelado' : agendamento.status === StatusAtendimentoUsuario.AtendimentoCancelado }">
                            {{ agendamento.status === StatusAtendimentoUsuario.Atendido
                            ? 'Atendimento foi finalizado com sucesso.'
                            : 'Atendimento foi cancelado.' }}
                        </span>

                    </div>
                </div>
            </fuse-widget>
        </div>
    </mat-dialog-content>
</div>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" class="text-right">
    <button mat-stroked-button color="accent" class="h-42" mat-dialog-close>
        Fechar
    </button>
</mat-dialog-actions>