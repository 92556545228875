import { StatusAtendimentoUsuario } from './../../../_shared/models/_enums/status-atendimento-usuario.enum';
import { GravacaoAgendamentoAtendimentoDTO } from './../../../_shared/models/dtos/gravacao-agendamento-atendimento-dto.model';
import {
    Component,
    ViewEncapsulation,
    Inject,
} from '@angular/core';
import { InformacoesAgendamentoDTO } from 'app/main/_shared/models/dtos/informacoes-agendamento-dto.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AtendimentoControllerService } from 'app/main/_shared/servicos/controllers';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { DialogService } from 'app/main/_shared/servicos/dialogs';
import { TipoAtendimentoCartorio } from 'app/main/_shared/models/_enums';

@Component({
    selector: 'cancelar-agendamento',
    templateUrl: './cancelar-agendamento.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CancelarAgendamentoComponent {
    public datasDisponiveis: Array<Date> = [];
    public horariosDisponiveis: Array<string> = [];
    public horarioSelecionado: string;
    public dataSelecionada: Date;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public dados: {
            cns: string;
            dadosAgendamento: InformacoesAgendamentoDTO;
        },
        private _dialogRef: MatDialogRef<CancelarAgendamentoComponent>,
        private _atendimentoController: AtendimentoControllerService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _dialogService: DialogService
    ) {}

    public get tipoAtendimento(): typeof TipoAtendimentoCartorio {
        return TipoAtendimentoCartorio;
    }

    public realizarCancelamentoDeAgendamento(): void {
        this._dialogService
            .dialogPerguntaAsync(
                'Atenção',
                'Ao cancelar o atendimento horário deste agendamento será liberado, podendo impossibilitar o agendamento nesta mesma data e horário. ' +
                    'Tem certeza que deseja CANCELAR este agendamento?'
            )
            .subscribe((resposta: boolean) => {
                if (resposta) {
                    this._atendimentoController
                        .cancelarAgendamentoAtendimento(this.dados.cns, this.dados.dadosAgendamento.idAgendamento)
                            .subscribe(() => {
                                this._dialogRef.close();
                                this._dialogService.dialogInformacao(
                                    'Atenção',
                                    `O agendamento de protocolo ${this.dados.dadosAgendamento.protocolo} foi cancelado com sucesso !`
                                );
                            }, (erro) => {
                                console.log(erro);
                            }
                        );
                }
            });
    }
}
