<h1 mat-dialog-title>Configurações de expediente</h1>
<mat-dialog-content mat-dialog-content>
    <div
        fxLayoutAlign="center center"
        class="text-center texto-destaque"
        *ngIf="!this.carregando && this.expedienteForm.controls.length === 0"
    >
        Expediente não foi configurado
    </div>
    <div
        fxLayoutAlign="center center"
        class="text-center texto-destaque"
        *ngIf="this.carregando"
    >
        Carregando informações
    </div>
    <form [formGroup]="this.form">
        <mat-accordion
            class="m-8"
            *ngIf="
                this.expedienteForm && this.expedienteForm.controls.length > 0
            "
        >
            <ng-container
                *ngFor="let item of this.controlesOrdenados; let i = index"
            >
                <mat-expansion-panel
                    [formGroup]="item"
                    [expanded]="this.verificarExpansao(item)"
                    (opened)="this.selecionarDia(item)"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title
                            fxLayout="row"
                            fxLayoutAlign="space-between center"
                        >
                            <span>{{
                                this.retornarDescricaoDiaSemana(item.value.dia)
                            }}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <mat-form-field fxFlex>
                            <mat-label>Hora inicio do expediente</mat-label>
                            <input
                                matInput
                                formControlName="horaInicioExpediente"
                                type="time"
                            />
                        </mat-form-field>

                        <mat-form-field fxFlex>
                            <mat-label>Hora final do expediente</mat-label>
                            <input
                                matInput
                                formControlName="horaFimExpediente"
                                type="time"
                            />
                        </mat-form-field>
                    </div>
                    <mat-action-row>
                        <button
                            mat-raised-button
                            color="accent"
                            aria-label="Excluir dia"
                            (click)="this.excluirDiaDoExpediente(i)"
                        >
                            Excluir dia
                        </button>
                    </mat-action-row>
                </mat-expansion-panel>
            </ng-container>
        </mat-accordion>
    </form>
    <div fxLayout="row" fxLayoutAlign="end center">
        <button
            mat-raised-button
            color="accent"
            class="ml-8"
            [matMenuTriggerFor]="basic"
            [disabled]="this.diasDisponiveis.length == 0"
        >
            Adicionar dia
        </button>
        <mat-menu #basic="matMenu" yPosition="above">
            <ng-template matMenuContent>
                <button
                    mat-menu-item
                    *ngFor="let diaDisponivel of this.diasDisponiveis"
                    (click)="this.adicionarDiaNoExpediente(diaDisponivel)"
                >
                    {{ this.retornarDescricaoDiaSemana(diaDisponivel) }}
                </button>
            </ng-template>
        </mat-menu>
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end end" class="text-right">
    <button mat-stroked-button color="accent" (click)="this.fechar()">
        Fechar
    </button>
    <button
        mat-raised-button
        color="accent"
        class="ml-8"
        [disabled]="this.form.invalid"
        (click)="this.gravarDados()"
    >
        Salvar
    </button>
</mat-dialog-actions>
