<div id="error-500" fxLayout="column" fxLayoutAlign="center center">
    <div class="content" fxLayout="column" fxLayoutAlign="center center">
        <div class="error-code">500</div>

        <div class="message">Ok, você quebrou a internet!</div>

        <div class="sub-message">
            Brincadeirinha, infelizmente nós estamos tendo uma instabilidade
            interna, tente novamente em alguns minutos.
        </div>
    </div>
</div>
