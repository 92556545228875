import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Usuario } from '../models';
import { TipoLogin } from '../models/_enums';
import CryptoES from 'crypto-es';
import * as moment from 'moment';
import * as uuid from 'uuid';
import { ConfigService } from 'app/config/config.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private _usuarioAtualSubject: BehaviorSubject<Usuario>;
    private http: HttpClient;
    public usuarioAtualObservable: Observable<Usuario>;

    constructor(handler: HttpBackend, private _configService: ConfigService) {
        this.http = new HttpClient(handler);
        this._usuarioAtualSubject = new BehaviorSubject<Usuario>(
            JSON.parse(sessionStorage.getItem('usuarioAtual'))
        );
        this.usuarioAtualObservable = this._usuarioAtualSubject.asObservable();
    }

    public get usuarioAtual(): Usuario {
        return this._usuarioAtualSubject.value;
    }

    public set usuarioAtual(user: Usuario) {
        this.setUsuario(user);
    }

    login(
        email: string,
        senha: string,
        tipo: TipoLogin,
        cns: string = null
    ): Observable<Usuario> {
        const usuario: Usuario = {
            id: null,
            email: btoa(email),
            nome: '',
            senha: btoa(senha),
            cns: cns,
            tipoLogin: tipo,
        };

        return this.http
            .post<Usuario>(`${this._configService.config.apiUrl}autenticacao/login`, usuario, {
                headers: {
                    "x-host-token": this.gerarHostToken(),
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .pipe(
                map((user) => {
                    this.setUsuario(user);
                    return user;
                })
            );
    }

    

    logout(): void {
        sessionStorage.removeItem('usuarioAtual');
        this._usuarioAtualSubject.next(null);
    }

    private setUsuario(usuario: Usuario): void {
        sessionStorage.setItem('usuarioAtual', JSON.stringify(usuario));
        this._usuarioAtualSubject.next(usuario);
    }

    private gerarHostToken() : string {
        var token = "MSRV|";
        const dataAtual: moment.Moment = moment(new Date());

        token += dataAtual.locale(moment.locale('pt-BR')).format('DD-MM-YYYY-HH-mm-ss') + "|";
        token += this.getRandomInt(675, 9856).toString() + "|";
        token += uuid.v4();

        const text = CryptoES.enc.Utf8.parse(token);
        const Key = CryptoES.enc.Utf8.parse(environment.dadosCriptografia.chave);
        const IV = CryptoES.enc.Utf8.parse(environment.dadosCriptografia.vetorInicializacao);

        const encryptedText = CryptoES.AES.encrypt(text, Key, { iv: IV, mode: CryptoES.mode.CBC, padding: CryptoES.pad.Pkcs7 });
        return encryptedText.toString();
    }

    private getRandomInt(min: number, max: number): number{
        min = Math.ceil(min);
        max = Math.floor(max);

        return Math.floor(Math.random() * (max - min)) + min;
    }
}
