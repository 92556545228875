<h1 mat-dialog-title>Atendimento ao usuário</h1>
<mat-dialog-content mat-dialog-content>
    <form
        fxLayout="column"
        fxLayoutAlign="center stretch"
        name="form"
        [formGroup]="this.form"
    >
        <mat-tab-group
            #tabGroup
            (selectedTabChange)="handleTabChange($event)"
            [selectedIndex]="0"
        >
            <mat-tab label="Dados do agendamento">
                <div
                    fxFlex="100"
                    fxLayout="column"
                    fxLayout.gt-sm="row"
                    class="pt-24"
                >
                    <mat-form-field
                        appearance="outline"
                        fxFlex="100"
                        fxFlex.gt-sm="25"
                        class="negrito"
                    >
                        <mat-label>Protocolo</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="protocolo"
                        />
                    </mat-form-field>
                    <mat-form-field
                        fxFlex="20"
                        appearance="outline"
                        fxFlex="100"
                        fxFlex.gt-sm="20"
                        class="negrito"
                    >
                        <mat-label>Data</mat-label>
                        <input
                            matInput
                            [matDatepicker]="picker"
                            formControlName="dataAgendamento"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="picker"
                            disabled="true"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                            touchUi
                            #picker
                            disabled="true"
                        ></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="100"
                        fxFlex.gt-sm="15"
                        class="negrito"
                    >
                        <mat-label>Hora agendada</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="horaAgendamento"
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="100"
                        fxFlex.gt-sm="25"
                        class="negrito"
                    >
                        <mat-label>Serviço solicitado</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="descricaoTipoServico"
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex.xs="100"
                        fxFlex.gt-xs="15"
                        class="negrito"
                    >
                        <mat-label>Atendimento</mat-label>
                        <mat-select
                            formControlName="tipoAtendimento"
                            class="negrito"
                        >
                            <mat-option [value]="this.tipoAtendimento.Virtual"
                                >Virtual</mat-option
                            >
                            <mat-option
                                [value]="this.tipoAtendimento.Presencial"
                                >Presencial</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxFlex="100" fxLayout="column" fxLayout.gt-sm="row">
                    <mat-form-field
                        appearance="outline"
                        fxFlex="100"
                        fxFlex.gt-sm="50"
                        class="negrito"
                    >
                        <mat-label>Nome do requirente</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="nomeRequirente"
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex="100"
                        fxFlex.gt-sm="25"
                        class="negrito"
                    >
                        <mat-label>CPF</mat-label>
                        <input matInput type="text" formControlName="cpf" />
                    </mat-form-field>
                    <mat-form-field
                        appearance="outline"
                        fxFlex="100"
                        fxFlex.gt-sm="25"
                        class="negrito"
                    >
                        <mat-label>Telefone</mat-label>
                        <input
                            matInput
                            type="text"
                            formControlName="telefone"
                        />
                    </mat-form-field>
                </div>

                <h3 class="perguntas pl-4">Perguntas e respostas:</h3>

                <div fxFlex="100" fxLayout="row">
                    <mat-vertical-stepper
                        fxFlex="100"
                        #stepper
                        class="perguntas"
                    >
                        <mat-step
                            *ngFor="
                                let formPergunta of this.perguntasForm.controls;
                                let i = index
                            "
                            [formGroup]="formPergunta"
                            fxFlex="100"
                            fxLayout="column"
                        >
                            <ng-template matStepLabel>{{
                                formPergunta.get('textoPergunta').value
                            }}</ng-template>

                            <div fxFlex="100" fxLayout="column">
                                <p>
                                    <span
                                        *ngIf="
                                            formPergunta.get('tpResposta')
                                                .value !==
                                                this.tipoResposta.Imagem &&
                                            formPergunta.get('tpResposta')
                                                .value !==
                                                this.tipoResposta.Arquivo
                                        "
                                        >{{
                                            formPergunta.get('resposta').value
                                        }}</span
                                    >

                                    <img
                                        *ngIf="
                                            formPergunta.get('tpResposta')
                                                .value ===
                                            this.tipoResposta.Imagem
                                        "
                                        [src]="
                                            formPergunta.get('resposta').value
                                        "
                                        alt="Imagem enviada"
                                        width="200"
                                        height="400"
                                    />

                                    <span
                                        *ngIf="
                                            formPergunta.get('tpResposta')
                                                .value ===
                                            this.tipoResposta.Arquivo
                                        "
                                    >
                                        <a
                                            [href]="
                                                formPergunta.get('resposta')
                                                    .value
                                            "
                                            target="_blank"
                                            class="text-white"
                                        >
                                            <b
                                                >Clique aqui para baixar o
                                                arquivo enviado</b
                                            >
                                        </a>
                                    </span>
                                </p>
                            </div>

                            <div>
                                <button
                                    mat-stroked-button
                                    color="accent"
                                    matStepperPrevious
                                    *ngIf="i > 0"
                                >
                                    Anterior
                                </button>
                                <button
                                    mat-stroked-button
                                    color="accent"
                                    matStepperNext
                                    *ngIf="
                                        i <
                                        this.perguntasForm.controls.length - 1
                                    "
                                    [ngClass]="i < 0 ? 'ml-8' : ''"
                                >
                                    Próxima
                                </button>
                            </div>
                        </mat-step>
                    </mat-vertical-stepper>
                </div>
            </mat-tab>

            <mat-tab label="Atendimento">
                <div
                    fxFlex="100"
                    fxLayout="column"
                    fxLayout.gt-sm="row"
                    class="pt-24"
                >
                    <mat-form-field
                        appearance="outline"
                        fxFlex.xs="100"
                        fxFlex.gt-xs="50"
                    >
                        <mat-label>Status do atendimento</mat-label>
                        <mat-select formControlName="status" class="negrito">
                            <mat-option
                                [value]="this.statusAtendimento.Atendido"
                            >
                                <b>Atendido</b>
                            </mat-option>
                            <mat-option
                                [value]="
                                    this.statusAtendimento.AguardandoResposta
                                "
                            >
                                <b>Aguardando resposta</b>
                            </mat-option>
                            <mat-option
                                [value]="
                                    this.statusAtendimento.AtendimentoCancelado
                                "
                            >
                                <b>Atendimento cancelado</b>
                            </mat-option>
                            <mat-option
                                [value]="this.statusAtendimento.Pendente"
                            >
                                <b>Pendente</b>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        fxFlex.xs="100"
                        fxFlex.gt-xs="50"
                    >
                        <mat-label>Usuário</mat-label>
                        <mat-select formControlName="idUsuario" class="negrito">
                            <mat-option
                                *ngFor="let usuario of this.usuariosAplicacao"
                                [value]="usuario.id"
                            >
                                <b>{{ usuario.nome }}</b>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxFlex="100" [fxShow]="this.carregandoEditor" fxLayoutAlign="space-around center">
                    <mat-progress-spinner 
                        color="accent"
                        mode="indeterminate">
                    </mat-progress-spinner>
                </div>

                <div fxFlex="100" fxLayout="column" [fxShow]="!this.carregandoEditor">
                    <span class="pl-4 mb-12">Observações do atendimento</span>
                    <div
                        appearance="outline"
                        fxFlex="100"
                        class="mb-16 pl-4"
                        fxLayout="column"
                    >
                        <editor
                            *ngIf="isEditorTab()"
                            [init]="this.configuracoesEditor"
                            formControlName="observacaoAtendimento"
                            fxFlex="65000"
                            (onInit)="this.onInitEditor()"
                        >
                        </editor>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </form>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end end" class="text-right">
    <button mat-stroked-button color="accent" mat-dialog-close>Fechar</button>
    <button
        mat-raised-button
        color="accent"
        class="ml-8"
        (click)="this.gravarDados()"
        [disabled]="this.form.invalid || this.carregandoEditor"
    >
        Salvar
    </button>
</mat-dialog-actions>
