import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators,
    AbstractControl,
} from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TipoLogin } from '../_shared/models/_enums';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastService, AuthenticationService } from '../_shared/servicos';
import { VerificaApiService } from '../_shared/servicos/verifica-api.service';
import { first } from 'rxjs/operators';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { setNavigationMenu } from 'app/navigation/navigation';
import { ConfiguracaoGlobalService } from '../_shared/servicos/configuracao-global.service';

@Component({
    selector: 'admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class AdminComponent implements OnInit {
    public adminForm: FormGroup;
    private _cns: string;
    private _returnUrl: string;
    public lembrarSenha: boolean;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _activatedRoute: ActivatedRoute,
        private _toastService: ToastService,
        private _verificaApiService: VerificaApiService,
        private _authenticationService: AuthenticationService,
        private _router: Router,
        private _fuseNavigationService: FuseNavigationService,
        private _configuracaoGlobalService: ConfiguracaoGlobalService,
        public fuseProgressBarService: FuseProgressBarService
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };

        if (!!this._authenticationService.usuarioAtual) {
            this._router.navigate(['/']);
        }
    }

    ngOnInit(): void {
        this.adminForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            senha: ['', Validators.required],
        });

        this.carregarDadosConfiguracaoGlobal();

        this._activatedRoute.paramMap.subscribe((params: ParamMap) => {
            this._cns = params.get('cns') ?? null;
            this._returnUrl = !!this._cns
                ? '/atendimento'
                : '/configuracoes-cartsys';
        });
    }

    get form(): {
        [key: string]: AbstractControl;
    } {
        return this.adminForm.controls;
    }

    onSubmit(): void {
        if (this.adminForm.invalid) {
            return;
        }
        this.fuseProgressBarService.show();

        this._verificaApiService.verificarApiOnline().subscribe(
            (online) => {
                this.realizarLogin();
            },
            (erro) => {
                this.fuseProgressBarService.hide();
                this._toastService.mensagemErro(
                    'A API do sistema está fora do ar.'
                );
            }
        );
    }

    private realizarLogin(): void {
        const tipoLogin = this._cns ? TipoLogin.Cartorio : TipoLogin.Cartsys;
        this._authenticationService
            .login(
                this.form.email.value,
                this.form.senha.value,
                tipoLogin,
                this._cns
            )
            .pipe(first())
            .subscribe(
                () => {
                    setNavigationMenu(
                        this._authenticationService,
                        this._fuseNavigationService
                    );
                    this._router.navigate([this._returnUrl]);
                    this.fuseProgressBarService.hide();
                    this.gravarDadosConfiguracaoGlobal(
                        this.form.email.value,
                        this.form.senha.value
                    );
                },
                (err) => {
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemErro(
                        'Usuário ou senha inválidos'
                    );
                }
            );
    }

    carregarDadosConfiguracaoGlobal(): void {
        const config = this._configuracaoGlobalService.currentConfiguration;
        this.lembrarSenha = config.lembrarSenha;

        if (this.lembrarSenha) {
            const dadosLogin = atob(config.cfgLogin).split('¨');
            this.form.senha.setValue(dadosLogin[1]);
            this.form.email.setValue(dadosLogin[0]);
        }
    }

    gravarDadosConfiguracaoGlobal(email: string, senha: string): void {
        const config = this._configuracaoGlobalService.currentConfiguration;

        config.lembrarSenha = this.lembrarSenha;

        if (this.lembrarSenha) {
            const cfg = btoa(`${email}¨${senha}`);
            config.cfgLogin = cfg;
        } else {
            config.cfgLogin = '';
        }

        this._configuracaoGlobalService.currentConfiguration = config;
    }
}
