<h1 mat-dialog-title>Cancelar agendamento realizado</h1>
<mat-dialog-content mat-dialog-content fxLayout="column">
    <div
        fxFlex="100"
        fxLayout="column"
        fxLayout.gt-sm="row"
        class="pt-24">

        <mat-form-field
            appearance="outline"
            fxFlex="100"
            fxFlex.gt-sm="25"
            class="negrito"
        >
            <mat-label>Protocolo</mat-label>
            <input
                matInput
                type="text"
                [readonly]="true"
                [(ngModel)]="this.dados.dadosAgendamento.protocolo"
            />
        </mat-form-field>
        
        <mat-form-field
            fxFlex="20"
            appearance="outline"
            fxFlex="100"
            fxFlex.gt-sm="20"
            class="negrito"
        >
            <mat-label>Data agendada</mat-label>
            <input
                type="text"
                matInput
                [readonly]="true"
                [(ngModel)]="this.dados.dadosAgendamento.dataAgendamento"
            />
        </mat-form-field>

        <mat-form-field
            appearance="outline"
            fxFlex="100"
            fxFlex.gt-sm="20"
            class="negrito"
        >
            <mat-label>Hora agendada</mat-label>
            <input
                matInput
                type="text"
                [readonly]="true"
                [(ngModel)]="this.dados.dadosAgendamento.horaAgendamento"
            />
        </mat-form-field>

        <mat-form-field
            appearance="outline"
            fxFlex="100"
            fxFlex.gt-sm="35"
            class="negrito"
        >
            <mat-label>Serviço solicitado</mat-label>
            <input
                matInput
                type="text"
                [readonly]="true"
                [(ngModel)]="this.dados.dadosAgendamento.servicoSolicitado"
            />
        </mat-form-field>
    </div>

    <div fxFlex="100" fxLayout="column" fxLayout.gt-sm="row">
        <mat-form-field
            appearance="outline"
            fxFlex="100"
            fxFlex.gt-sm="50"
            class="negrito"
        >
            <mat-label>Nome do requirente</mat-label>
            <input
                matInput
                type="text"
                [readonly]="true"
                [(ngModel)]="this.dados.dadosAgendamento.nomeRequirente"
            />
        </mat-form-field>

        <mat-form-field
            appearance="outline"
            fxFlex="100"
            fxFlex.gt-sm="25"
            class="negrito"
        >
            <mat-label>CPF</mat-label>
            <input matInput 
                type="text" 
                [readonly]="true"
                [(ngModel)]="this.dados.dadosAgendamento.cpf" />
        </mat-form-field>
        <mat-form-field
            appearance="outline"
            fxFlex="100"
            fxFlex.gt-sm="25"
            class="negrito"
        >
            <mat-label>Telefone</mat-label>
            <input
                matInput
                type="text"
                [readonly]="true"
                [(ngModel)]="this.dados.dadosAgendamento.telefone"
            />
        </mat-form-field>        
    </div>
    <hr/>
    <div fxFlex fxLayout="column" fxLayoutAlign="center center">
        <span>O cancelamento é irreversível ao cancelar o agendamento, o horário será liberado para outro atendimento.</span>
    </div>
</mat-dialog-content>

<mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end center"
    class="text-right"
>
    <button
        mat-stroked-button
        aria-label="Cancelar o agendamento"
        matTooltip="Cancelar o agendamento"
        color="accent"
        class="ml-4"
        (click)="this.realizarCancelamentoDeAgendamento()">
        Realizar o cancelamento
        <mat-icon>alarm_off</mat-icon>
    </button>
    <button mat-stroked-button color="accent" class="h-42" mat-dialog-close>
        Fechar
    </button>
</mat-dialog-actions>
