import { NgModule, LOCALE_ID } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { registerLocaleData } from '@angular/common';
import localeBr from '@angular/common/locales/pt';
import { FuseSharedModule } from '@fuse/shared.module';

import { QuickPanelComponent } from 'app/layout/components/quick-panel/quick-panel.component';

@NgModule({
    declarations: [
        QuickPanelComponent
    ],
    imports     : [
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,

        FuseSharedModule,
    ],
    exports: [
        QuickPanelComponent
    ],
    providers:[
        { provide: LOCALE_ID, useValue: 'pt'} 
    ]
})
export class QuickPanelModule
{
    /**
     *
     */
    constructor() {
        registerLocaleData(localeBr, 'pt');
    }
}
