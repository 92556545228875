<h1 mat-dialog-title *ngIf="!!this.data.title">{{ this.data.title }}</h1>
<div mat-dialog-content>
    <p>
        <span *ngIf="this.data.type === 'html'" [innerHTML]="this.data.html"></span>
        <span *ngIf="this.data.type === 'texto'">{{ this.data.msg }}</span>
    </p>
</div>
<footer mat-dialog-actions>
    <div fxLayout="row" fxLayoutAlign="end end" class="text-right">
        <button mat-stroked-button mat-dialog-close (click)="this.eventoFechar()">
            Fechar
        </button>
    </div>
</footer>