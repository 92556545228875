import { Injectable } from '@angular/core';
import { BaseControllerService } from './base-controller.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '..';
import { Observable } from 'rxjs';
import {
    TipoAtendimentoCartorio,
    TipoExclusaoAgendamento,
} from '../../models/_enums';
import { PerguntaRespostaAtendimento } from '../../models/dtos/pergunta-resposta-atendimento.model';
import { DatePipe } from '@angular/common';
import {
    HorarioAtendimento,
    AgendamentoAtendimento,
} from '../../models/cartorio';
import { GravacaoAgendamentoAtendimentoDTO } from '../../models/dtos/gravacao-agendamento-atendimento-dto.model';
import { __asyncGenerator } from 'tslib';
import { FiltroAgendamentosDTO } from '../../models/dtos/filtro-agendamentos-dto.model';
import { FiltroAgendamentoEspecificoDTO } from '../../models/dtos/filtro-agendamento-especifico-dto.model';
import { InformacoesAgendamentoDTO } from '../../models/dtos/informacoes-agendamento-dto.model';
import { ConfigService } from 'app/config/config.service';

@Injectable({
    providedIn: 'root',
})
export class AtendimentoControllerService extends BaseControllerService {
    constructor(
        _http: HttpClient,
        _authenticationService: AuthenticationService,
        configService: ConfigService,
        private _datepipe: DatePipe
    ) {
        super(_http, _authenticationService, configService);
    }

    listarHorariosAtendimentoDisponiveis(
        cns: string,
        dataAgendamento: Date,
        tipoAtendimento: TipoAtendimentoCartorio,
        idTipoServico: string
    ): Observable<Array<string>> {
        const hoje = new Date();
        dataAgendamento.setHours(hoje.getHours());
        dataAgendamento.setMinutes(hoje.getMinutes());
        dataAgendamento.setSeconds(hoje.getSeconds());
        const dataFormatada = this._datepipe.transform(
            dataAgendamento,
            'dd-MM-yyyy-HH-mm-ss'
        );

        return this.get(
            `atendimento/listarHorariosAtendimentoDisponiveis/${cns}/${dataFormatada}/${tipoAtendimento}/${idTipoServico}`
        );
    }

    listarDatasDisponiveis(
        cns: string,
        dataInicio: Date,
        tipoAtendimento: TipoAtendimentoCartorio,
        idTipoServico: string
    ): Observable<Array<Date>> {
        const dataFormatada = this._datepipe.transform(
            dataInicio,
            'dd-MM-yyyy-HH-mm-ss'
        );

        return this.get(
            `atendimento/listarDatasDisponiveis/${cns}/${dataFormatada}/${tipoAtendimento}/${idTipoServico}`
        );
    }

    listarExpedienteUsuariosCartorio(cns: string): Observable<Array<string>> {
        return this.get<Array<string>>(
            `atendimento/listarexpedienteusuarioscartorio/${cns}`
        );
    }

    listarPerguntasComResposta(
        idAgendamento: string
    ): Observable<Array<PerguntaRespostaAtendimento>> {
        return this.get(
            `atendimento/listarPerguntasComResposta/${idAgendamento}`
        );
    }

    gravarHorarioAtendimentoUsuario(
        cns: string,
        idUsuario: string,
        horariosDoUsuario: Array<HorarioAtendimento>
    ): Observable<void> {
        return this.post(
            `atendimento/gravarHorarioAtendimentoUsuario/${cns}/${idUsuario}`,
            horariosDoUsuario
        );
    }

    confirmarAgendamento(
        cns: string,
        agendamento: AgendamentoAtendimento
    ): Observable<{ protocolo: string }> {
        return this.post(
            `atendimento/confirmarAgendamento/${cns}`,
            agendamento
        );
    }

    listarAgendamentos(
        filtros: FiltroAgendamentosDTO
    ): Observable<Array<AgendamentoAtendimento>> {
        return this.post(`atendimento/listarAgendamentos`, filtros);
    }

    gravarAgendamentoAtendimento(
        cns: string,
        gravacaoAgendamento: GravacaoAgendamentoAtendimentoDTO
    ): Observable<void> {
        return this.put(
            `atendimento/gravarAgendamentoAtendimento/${cns}/${gravacaoAgendamento.id}`,
            gravacaoAgendamento
        );
    }

    pesquisarAgendamentoEspecifico(
        filtros: FiltroAgendamentoEspecificoDTO
    ): Observable<Array<InformacoesAgendamentoDTO>> {
        return this.post(`atendimento/PesquisarAgendamentoEspecifico`, filtros);
    }

    alterarDataHoraAgendamento(
        cns: string,
        idAgendamento: string,
        dataAgendamento: Date,
        horaAgendamento: string
    ): Observable<void> {
        return this.patch(`atendimento/alterarDataHoraAgendamento`, {
            cns: cns,
            idAgendamento,
            dataAgendamento,
            horaAgendamento,
        });
    }

    cancelarAgendamentoAtendimento(
        cns: string,
        idAgendamento: string
    ): Observable<void> {
        return this.delete(
            `atendimento/cancelarAgendamentoAtendimento/${cns}/${idAgendamento}`
        );
    }

    quantidadeAgendamentosParaCancelamento(dataInicio: Date, dataFim: Date, tipoExclusao: TipoExclusaoAgendamento) : Observable<number> {
        const dataInicioFormatada = this._datepipe.transform(
            dataInicio,
            'dd-MM-yyyy'
        );

        const dataFimFormatada = this._datepipe.transform(
            dataFim,
            'dd-MM-yyyy'
        );

        return this.get(
            `atendimento/quantidadeAgendamentosParaCancelamento/${dataInicioFormatada}/${dataFimFormatada}/${tipoExclusao}`
        );
    }

    realizarCancelamentoDefinitivo(dataInicio: Date, dataFim: Date, tipoExclusao: TipoExclusaoAgendamento): Observable<void> {
        
        const dataInicioFormatada = this._datepipe.transform(
            dataInicio,
            'dd-MM-yyyy'
        );

        const dataFimFormatada = this._datepipe.transform(
            dataFim,
            'dd-MM-yyyy'
        );

        return this.delete(`atendimento/realizarCancelamentoDefinitivo/${dataInicioFormatada}/${dataFimFormatada}/${tipoExclusao}`);
    }
}
