import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormGroup } from '@angular/forms';
import { DialogService } from 'app/main/_shared/servicos/dialogs';
import { DialogFormBase } from 'app/main/_shared/contratos';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerguntaCartorio } from 'app/main/_shared/models/cartorio';
import { PerguntaCartorioControllerService } from 'app/main/_shared/servicos/controllers';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastService, AuthenticationService } from 'app/main/_shared/servicos';
import {
    TipoAtendimentoUsuario,
    TipoResposta,
    TipoMascara,
} from 'app/main/_shared/models/_enums';
import { TipoServicoAtendimento } from 'app/main/_shared/models/cartorio/tipo-servico-atendimento.model';
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatStepper } from '@angular/material/stepper';
import { EMPTY_GUID } from 'app/main/_shared';
import { configuracaoPadraoEditor } from 'app/main/_shared/funcoes/configuracoes-editor';
import { MatChipInputEvent } from '@angular/material/chips';
import { ConfigService } from 'app/config/config.service';

@Component({
    selector: 'manutencao-tipos-servico',
    templateUrl: './manutencao-tipos-servico.component.html',
    styleUrls: ['./manutencao-tipos-servico.component.scss'],
})
export class ManutencaoTiposServicoComponent extends DialogFormBase<
    ManutencaoTiposServicoComponent
> {
    private _tipoServicoAtendimento: TipoServicoAtendimento = new TipoServicoAtendimento();
    private _cns = '';
    private _inserindo = false;
    public configuracoesEditor = configuracaoPadraoEditor('250', this._configService.config.urlDominio);
    public carregandoEditor: boolean;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    public opcoesPerguntas: { [key: number]: string[] } = {};

    @ViewChild('stepper')
    public stepper: MatStepper;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private _dados: any,
        private _authenticationService: AuthenticationService,
        private _perguntaCartorioController: PerguntaCartorioControllerService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _toastService: ToastService,
        private _configService: ConfigService,
        formBuilder: FormBuilder,
        dialogService: DialogService,
        dialogRef: MatDialogRef<ManutencaoTiposServicoComponent>
    ) {
        super(formBuilder, dialogService, dialogRef);
    }

    get tipoAtendimento(): typeof TipoAtendimentoUsuario {
        return TipoAtendimentoUsuario;
    }

    get tipoResposta(): typeof TipoResposta {
        return TipoResposta;
    }

    get tipoMascara(): typeof TipoMascara {
        return TipoMascara;
    }

    get perguntasForm(): FormArray {
        return this.form.get('arrayPerguntas') as FormArray;
    }
    
    processamentoOnAfterViewInit(): void {
        setTimeout(() => {
            this.carregandoEditor = true;
        });
    }

    construirForm(formBuilder: FormBuilder): void {
        this.form = formBuilder.group({
            descricao: ['', [Validators.required, Validators.maxLength(100)]],
            mensagemFinalAtendimento: [
                '',
                [Validators.required, Validators.maxLength(65000)],
            ],
            tipoAtendimento: [
                TipoAtendimentoUsuario.VirtualEPresencial,
                [Validators.required],
            ],
            arrayPerguntas: formBuilder.array([]),
        });
    }

    carregarDados(): void {
        this.carregarDadosTipoServicoAtendimento();
        if (!!this._tipoServicoAtendimento.id) {
            this.carregarPerguntasTipoServico(this._tipoServicoAtendimento.id);
        }
    }

    carregarDadosTipoServicoAtendimento(): void {
        this._cns = this._authenticationService.usuarioAtual.cns;
        this._tipoServicoAtendimento = this._dados.tipoServico ?? {
            id: '',
            cnsCartorio: this._cns,
        };

        this._inserindo = !this._dados.tipoServico;
        this.form.patchValue(this._tipoServicoAtendimento);
    }

    excluirPergunta(indice: number): void {
        this.perguntasForm.removeAt(indice);
    }

    carregarPerguntasTipoServico(idTipoServico: string): void {
        this._fuseProgressBarService.show();
        this._perguntaCartorioController
            .listarPerguntasTipoServico(this._cns, idTipoServico)
            .subscribe(
                (perguntas) => {
                    for (const pergunta of perguntas) {
                        this.adicionarPergunta(pergunta);   
                    }

                    this._fuseProgressBarService.hide();
                },
                (erro) => {
                    console.log(erro);
                    this._toastService.mensagemErro(
                        'Ocorreu um erro ao carregar as perguntas do tipo de serviço'
                    );
                    this._fuseProgressBarService.hide();
                }
            );
    }

    criarPerguntaForm(): FormGroup {
        const form = this._formBuilder.group({
            id: [''],
            idTipoServico: [this._tipoServicoAtendimento.id],
            indice: [this.perguntasForm.length],
            texto: ['', [Validators.required, Validators.maxLength(200)]],
            tipo: [TipoResposta.Texto, Validators.required],
            mascara: [
                { value: TipoMascara.Nenhuma, disabled: true },
                Validators.required,
            ]
        });

        this.subscribeOnChanges(form);

        return form;
    }

    private subscribeOnChanges(form: FormGroup): void {
        form.get('tipo').valueChanges.subscribe((tipo) => {
            const tipoSelecionado = tipo as TipoResposta;
            if (tipoSelecionado !== TipoResposta.Mascara) {
                form.get('mascara').setValue(TipoMascara.Nenhuma);
                form.get('mascara').disable();
            } else {
                form.get('mascara').enable();
            }
        });
    }

    desabilitarTipoMascara(): boolean {
        if (
            !this.form ||
            !this.form.get('tipo') ||
            !this.form.get('tipo').value
        ) {
            return true;
        }

        const tipo = this.form.get('tipo').value as TipoResposta;

        if (tipo === TipoResposta.Mascara) {
            return false;
        }

        this.form.get('mascara').setValue(TipoMascara.Nenhuma);
        return true;
    }

    adicionarPergunta(pergunta?: PerguntaCartorio): void {
        const formGroup = this.criarPerguntaForm();

        if (!!pergunta) {
            formGroup.patchValue(pergunta);

            if (!!pergunta.opcoesMultiplaEscolha && pergunta.opcoesMultiplaEscolha != null) {
                const opcoesStr = pergunta.opcoesMultiplaEscolha.split(",");

                if (!!opcoesStr) {
                    this.opcoesPerguntas[pergunta.indice] = opcoesStr;
                }
            } else {
                this.opcoesPerguntas[pergunta.indice] = [];
            }
        }

        this.perguntasForm.push(formGroup);

        setTimeout(() => {
            if (!pergunta) {
                this.stepper.selectedIndex =
                    this.perguntasForm.controls.length - 1;
            }
        }, 500);
    }

    gravarDados(): void {
        this._tipoServicoAtendimento.cnsCartorio = this._cns;
        this._tipoServicoAtendimento.descricao = this.form.get('descricao')
            .value as string;
        this._tipoServicoAtendimento.mensagemFinalAtendimento = this.form.get(
            'mensagemFinalAtendimento'
        ).value as string;

        this._tipoServicoAtendimento.tipoAtendimento = this.form.get(
            'tipoAtendimento'
        ).value as TipoAtendimentoUsuario;

        if (!this._tipoServicoAtendimento.id) {
            this._tipoServicoAtendimento.id = EMPTY_GUID;
        }

        const perguntas = this.carregarPerguntas();

        if (this._inserindo) {
            this._dialogService
                .dialogPerguntaAsync(
                    'Atenção',
                    'Deseja vincular este tipo de serviço à todos os usuários existentes?'
                )
                .subscribe((simOuNao) => {
                    this.processarGravacaoTipoServico(simOuNao, perguntas);
                });
        } else {
            this.processarGravacaoTipoServico(false, perguntas);
        }
    }

    private processarGravacaoTipoServico(
        vincularUsuariosATipoServico: boolean,
        perguntas: Array<PerguntaCartorio>
    ): void {
        this._fuseProgressBarService.show();
        this._perguntaCartorioController
            .gravarPerguntasTipoServico(
                this._cns,
                vincularUsuariosATipoServico,
                this._tipoServicoAtendimento,
                perguntas
            )
            .subscribe(
                () => {
                    this._toastService.mensagemSuccesso(
                        'Dados armazenados com sucesso.'
                    );
                    this._fuseProgressBarService.hide();
                    this._dialogRef.close();
                },
                (erro) => {
                    this._toastService.mensagemErro(
                        'Ocorreu um erro ao realizar a gravação dos dados.'
                    );
                    console.log(erro);
                    this._fuseProgressBarService.hide();
                }
            );
    }

    carregarPerguntas(): Array<PerguntaCartorio> {
        const array = new Array<PerguntaCartorio>();

        this.perguntasForm.controls.forEach((control) => {          
            let opcoesString = "";
            
            if (this.opcoesPerguntas[control.get('indice').value as number] != undefined && this.opcoesPerguntas[control.get('indice').value as number].length > 0) {
                opcoesString = this.opcoesPerguntas[control.get('indice').value as number].join(",");
            }

            const pergunta = {
                id: control.get('id').value as string,
                cns: this._cns,
                idTipoServico: this._tipoServicoAtendimento.id,
                indice: control.get('indice').value as number,
                mascara: control.get('mascara').value as TipoMascara,
                tipo: control.get('tipo').value as TipoResposta,
                texto: control.get('texto').value as string,
                opcoesMultiplaEscolha: opcoesString
            };

            if (!pergunta.id) {
                pergunta.id = EMPTY_GUID;
            }

            array.push(pergunta);
        });

        return array;
    }

    onInitEditor(): void {
        setTimeout(() => {
            this.carregandoEditor = false;
        });
    }

    removerOpcaoMultiplaEscolha(opcao: string, indice: number): void {
        if (!!this.opcoesPerguntas[indice] && this.opcoesPerguntas[indice].length == 0)
            return;

        const index = this.opcoesPerguntas[indice].indexOf(opcao);

        if (index >= 0) {
            this.opcoesPerguntas[indice].splice(index, 1);
        }
    }

    addicionarOpcaoMultiplaEscolha(event: MatChipInputEvent, indice: number): void {
        const input = event.input;
        const value = event.value;
    
        if ((value || "").trim()) {
            const index = this.opcoesPerguntas[indice].indexOf(value);

            if (index >= 0) {
                this._toastService.mensagemErro("Opção já foi adicionada");
                input.value = "";
                return;
            }
            
            this.opcoesPerguntas[indice].push(value);
        }
    
        if (input) {
          input.value = "";
        }
    }
}
