import { TipoAtendimentoCartorio, StatusAtendimentoUsuario } from '../_enums';
import { RespostaAtendimento } from './resposta-atendimento.model';

export class AgendamentoAtendimento {
    id: string;
    cns: string;
    nomeRequirente: string;
    telefone: string;
    cpf: string;
    protocolo: string;
    dataAgendamento: Date;
    horaAgendamento: string;
    tipoAtendimento: TipoAtendimentoCartorio;
    idTipoServico: string;
    idUsuario?: string;
    status: StatusAtendimentoUsuario;
    observacaoAtendimento: string;
    respostas: Array<RespostaAtendimento>;
    dataHoraCriacaoAgendamento: Date;
    horarioLiberado: boolean;
}
