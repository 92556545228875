import {
    Component,
    OnInit,
    OnDestroy,
    Pipe,
    PipeTransform,
} from '@angular/core';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { DadosCartorio } from 'app/main/_shared/models';
import { CartorioControllerService } from 'app/main/_shared/servicos/controllers/cartorio-controller.service';
import { TipoCartorio } from 'app/main/_shared/models/_enums';
import { DialogService } from 'app/main/_shared/servicos/dialogs';
import { ToastService } from 'app/main/_shared/servicos';
import { ManutencaoCartorioComponent } from './manutencao-cartorio/manutencao-cartorio.component';
import { Overlay } from '@angular/cdk/overlay';
import { ConfiguracoesGeraisComponent } from './configuracoes-gerais/configuracoes-gerais.component';

@Pipe({ name: 'cartorio' })
export class CartorioPipe implements PipeTransform {
    transform(items: DadosCartorio[], filter: string): any {
        if (!items || !filter || filter === '') {
            return items;
        }

        return items.filter(
            (item) =>
                item.cns.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                item.nome.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                item.tipo
                    .toString()
                    .toLowerCase()
                    .indexOf(filter.toLowerCase()) !== -1
        );
    }
}

@Component({
    selector: 'configuracoes-cartsys',
    templateUrl: './configuracoes-cartsys.component.html',
    styleUrls: ['./configuracoes-cartsys.component.scss'],
})
export class ConfiguracoesCartsysComponent implements OnInit, OnDestroy {
    public cartorios = new Array<DadosCartorio>();
    public pesquisaEmAndamento = false;
    public pesquisa = '';

    constructor(
        private _fuseProgressBarService: FuseProgressBarService,
        private _cartorioControllerService: CartorioControllerService,
        private _dialogService: DialogService,
        private _toastService: ToastService,
        private _overlay: Overlay
    ) {}

    ngOnInit(): void {
        this.listarDadosCartorios();
    }

    ngOnDestroy(): void {}

    listarDadosCartorios(): void {
        this._fuseProgressBarService.show();
        this._cartorioControllerService.listarDadosCartorios().subscribe(
            (cartorios) => {
                this.cartorios = cartorios;
                this._fuseProgressBarService.hide();
            },
            (erro) => {
                console.log(erro);
                this._toastService.mensagemErro(
                    'Ocorreu um erro ao carregar os dados de cartório'
                );
                this._fuseProgressBarService.hide();
            }
        );
    }

    pesquisando(event: any): void {
        this.pesquisa = event.srcElement.value;
    }

    habilitarBusca(): void {
        this.pesquisa = '';
        this.pesquisaEmAndamento = !this.pesquisaEmAndamento;
    }

    retornarDescricaoTipoCartorio(tipo: TipoCartorio): string {
        switch (tipo) {
            case TipoCartorio.Imoveis: {
                return 'Cartório de Imóveis';
            }
            case TipoCartorio.Notas: {
                return 'Cartório de Notas';
            }
            case TipoCartorio.Protesto: {
                return 'Cartório de Protesto';
            }
        }
    }

    editarCartorio(cartorio: DadosCartorio): void {
        this._dialogService
            .dialogGenerico(ManutencaoCartorioComponent, {
                scrollStrategy: this._overlay.scrollStrategies.reposition(),
                data: {
                    cartorio,
                },
            })
            .subscribe((gravacao) => {
                this.listarDadosCartorios();
            });
    }

    deletarCartorio(cartorio: DadosCartorio): void {
        this._dialogService.dialogPerguntaHtml(
            'Atenção',
            '<h3>Deseja deletar este cartório?</h3> <p>A remoção causará a perca de todas as informações vinculadas ao cartório selecionado.</p>',
            () => {
                this._fuseProgressBarService.show();
                this._cartorioControllerService
                    .deletarCartorio(cartorio.cns)
                    .subscribe(
                        () => {
                            this._fuseProgressBarService.hide();
                            this.listarDadosCartorios();
                        },
                        (erro) => {
                            console.log(erro);
                            this._toastService.mensagemErro(
                                'Ocorreu um erro ao carregar os dados de cartório'
                            );
                            this._fuseProgressBarService.hide();
                        }
                    );
            }
        );
    }

    adicionarCartorio(): void {
        this._dialogService
            .dialogGenerico(ManutencaoCartorioComponent, {
                scrollStrategy: this._overlay.scrollStrategies.reposition(),
                data: {
                    cartorio: null,
                },
            })
            .subscribe((gravacao) => {
                this.listarDadosCartorios();
            });
    }

    alterarConfiguracoesGerais(): void {
        this._dialogService.dialogGenerico(ConfiguracoesGeraisComponent, {
            scrollStrategy: this._overlay.scrollStrategies.reposition(),
        });
    }
}
