import {
    Component,
    AfterViewInit,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import { gerarIdRandomico, configuracoesJitsiPadrao } from '../../funcoes';
import { DialogService } from '../../servicos/dialogs';
import { Overlay } from '@angular/cdk/overlay';
import { EnviarWhatsAppComponent } from './enviar-whatsapp/enviar-whatsapp.component';
import { ToastService } from '../../servicos';
import { environment } from 'environments/environment';
import { ConfigService } from 'app/config/config.service';

// tslint:disable-next-line: no-any
declare var JitsiMeetExternalAPI: any;

@Component({
    selector: 'meeting',
    templateUrl: './meeting.component.html',
    styleUrls: ['./meeting.component.scss'],
})
export class MeetingComponent implements AfterViewInit, OnDestroy {
    private _jitsiDomain = 'meet.jit.si';
    private _dominioConferenciaCliente;
    public urlSala: string;
    private _api: any;

    public micHabilitado = true;
    public cameraHabilitada = true;
    public compartilhamentoHabilitado = false;
    @Input()
    public nomeSala = '';
    @Input()
    public conferenciaCliente = false;
    @Output()
    public eventoAoFechar = new EventEmitter();

    constructor(
        private _dialogService: DialogService,
        private _toastService: ToastService,
        private _overlay: Overlay,
        private _configService: ConfigService
    ) {}

    ngOnDestroy(): void {
        try {
            this._api.executeCommand('hangup');
            this._api.dispose();
        } catch {}
    }

    ngAfterViewInit(): void {
        this._dominioConferenciaCliente = `${this._configService.config.urlDominio}video-cliente`;
        this.iniciarVideoConferencia();
    }

    public iniciarVideoConferencia(): void {
        setTimeout(() => {
            const idSala =
                this.nomeSala !== '' ? this.nomeSala : gerarIdRandomico(8);
            if (!this.conferenciaCliente) {
                this.urlSala = `${this._dominioConferenciaCliente}/${idSala}`;
            }

            const config = configuracoesJitsiPadrao(idSala, '#localVideo');
            this._api = new JitsiMeetExternalAPI(this._jitsiDomain, config);

            this._api.on('readyToClose', () => {
                document.getElementById("localVideo").style.display = "none";
                
                console.log('saindo . . .');
                this.urlSala = '';
                if (!!this.eventoAoFechar) {
                    this.eventoAoFechar.emit('');
                }
                this._api.dispose();
            });

            this._api.on('audioMuteStatusChanged', ({ muted }) => {
                this.micHabilitado = !muted;
            });

            this._api.on(
                'screenSharingStatusChanged',
                ({ on, details: { sourceType } }) => {
                    this.compartilhamentoHabilitado = on;
                }
            );

            this._api.on('videoMuteStatusChanged', ({ muted }) => {
                this.cameraHabilitada = !muted;
            });

            this._api.executeCommand('subject', 'Video conferência - Cartório');

            const frame = document.getElementById('localVideo')
                .firstElementChild;
            frame.innerHTML =
                frame.innerHTML +
                '<style>.watermark { display: none !important; } .leftwatermark { display: none !important; }</style>';
        }, 500);
    }

    public fecharVideoConferencia(): void {
        this._api.executeCommand('hangup');
    }

    public alterarMicrofone(): void {
        this._api.executeCommand('toggleAudio');
    }

    public alterarCamera(): void {
        this._api.executeCommand('toggleVideo');
    }

    public alterarCompartilhamentoTela(): void {
        this._api.executeCommand('toggleShareScreen');
    }

    public enviarLinkPeloWhatsApp(): void {
        this._dialogService.dialogGenerico(EnviarWhatsAppComponent, {
            data: this.urlSala,
            width: '300px',
            height: '170px',
        });
    }

    public copiarLink(): void {
        this._toastService.mensagemSuccesso(
            `Link ${this.urlSala} copiado com sucesso.`
        );
    }
}
