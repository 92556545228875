import { EMPTY_GUID } from '../../constantes';

export class RespostaAtendimento {
    idPergunta: string;
    idAgendamento: string;
    resposta: string;

    constructor(pergunta: string, resp: string) {
        this.idPergunta = pergunta;
        this.resposta = resp;
        this.idAgendamento = EMPTY_GUID;
    }
}
