<div id="video-cliente" fxLayout="column">

    <div id="video-cliente-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="video-cliente-form" *ngIf="this.aguarde">
            <div class="video-cliente-form-header" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
                fxLayoutAlign.gt-xs="start center">
                <div class="title">Aguarde enquanto a conexão é estabilizada</div>
            </div>
        </div>
        <div id="video-cliente-form" *ngIf="this.agradecimento">
            <div class="video-cliente-form-header" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
                fxLayoutAlign.gt-xs="start center">
                <div class="title">Obrigado por manter contato conosco.</div>
            </div>
        </div>

        <meeting class="w-80-p h-90-p" [nomeSala]="this.idSala" *ngIf="this.exibirVideo"
            (eventoAoFechar)="this.fecharVideoConferencia()" [conferenciaCliente]="true">
        </meeting>

    </div>

</div>