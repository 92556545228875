<h1 mat-dialog-title>
    Processo de exclusão de dados
</h1>
<mat-dialog-content mat-dialog-content>
    <div fxLayout="row" class="text-center mb-16" fxLayoutAlign="center center">
        <span>
            Filtre os dados por período para possibilitar a exclusão
        </span>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <!--#region Filtro Data -->
        <mat-form-field fxFlex="20" appearance="outline">
            <mat-label>De</mat-label>
            <input
                matInput
                disabled
                [matDatepicker]="picker"
                [(ngModel)]="this.dataInicio"
                [max]="this.dataFim"
            />

            <mat-datepicker-toggle
                matSuffix
                [for]="picker"
                disabled="false">
            </mat-datepicker-toggle>

            <mat-datepicker
                touchUi
                #picker
                disabled="false">
            </mat-datepicker>
        </mat-form-field>

        <mat-form-field fxFlex="20" appearance="outline">
            <mat-label>Para</mat-label>
            <input
                matInput
                disabled
                [matDatepicker]="picker2"
                [(ngModel)]="this.dataFim"
                [min]="this.dataInicio"
                [max]="this.dataMaxima"
            />

            <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
                disabled="false">
            </mat-datepicker-toggle>
            
            <mat-datepicker
                touchUi
                #picker2
                disabled="false">
            </mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="40" class="mr-8">
            <mat-label>Tipo de exclusão de agendamentos</mat-label>
            <mat-select [(ngModel)]="this.tipoExclusao">
                <mat-option
                    [value]="this.tipoExclusaoAgendamento.Cancelado"
                >
                    Excluir cancelados
                </mat-option>
                <mat-option
                    [value]="this.tipoExclusaoAgendamento.Finalizado"
                >
                    Excluir realizados/finalizados
                </mat-option>
            </mat-select>
        </mat-form-field>
        
        <button
            mat-stroked-button
            aria-label="Pesquisar agendamentos"
            matTooltip="Pesquisar agendamentos"
            color="accent"
            class="btn-search-cancelamento"
            fxFlex
            (click)="this.pesquisar()"
        >
            <mat-icon>search</mat-icon>
        </button>
    </div>

    <hr *ngIf="this.agendamentosEncontrados > 0"/>

    <div fxLayout="column" fxLayoutAlign="space-around center" *ngIf="this.agendamentosEncontrados > 0">
        <span>Agendamentos encontrados: {{this.agendamentosEncontrados}}</span>
        <span class="texto-destaque-exclusao">Atenção este procedimento realiza uma exclusão definitiva. Após a execução, os dados não poderão mais ser recuperados.</span>
    </div>

    <hr/>
</mat-dialog-content>

<mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end center"
    class="text-right"
>
    <button
        mat-raised-button
        color="accent"
        fxFlex.gt-sm="35"
        [disabled]="!this.agendamentosEncontrados"
        (click)="this.cancelarAgendamentos()">
        Excluir agendamentos
    </button>

    <button mat-stroked-button color="accent" class="h-42" mat-dialog-close>
        Fechar
    </button>
</mat-dialog-actions>
