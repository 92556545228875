<h1 mat-dialog-title>Tipos de serviço do usuário</h1>
<mat-dialog-content mat-dialog-content fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="outline" fxFlex="40" class="mr-8">
            <mat-label>Tipo de serviço</mat-label>
            <mat-select
                [(ngModel)]="this.tipoServicoSelecionado"
                [disabled]="
                    !this.tiposServicoDisponiveis ||
                    this.tiposServicoDisponiveis.length === 0
                "
            >
                <mat-option
                    [value]="tipoServico"
                    *ngFor="let tipoServico of this.tiposServicoDisponiveis"
                >
                    {{ tipoServico.descricao }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <button
            mat-fab
            matTooltip="Adicionar tipo de serviço"
            color="accent"
            class="botao-adicionar"
            (click)="this.adicionarTipoServico()"
            [disabled]="
                !this.tiposServicoDisponiveis ||
                this.tiposServicoDisponiveis.length === 0 ||
                !this.tipoServicoSelecionado
            "
        >
            <mat-icon class="material-icons">
                add
            </mat-icon>
        </button>
    </div>
    <div
        fxLayout="row"
        fxLayoutAlign="start center"
        *ngIf="this.semTiposServicoNoInicio"
    >
        <span class="error">
            Não existem tipos de serviço de acordo com o tipo de atendimento do
            usuário
        </span>
    </div>

    <table mat-table [dataSource]="this.dataSourceTiposServico" fxFlex="100">
        <ng-container matColumnDef="descricao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Descrição
            </th>
            <td mat-cell *matCellDef="let tipoServico">
                {{ tipoServico.descricao }}
            </td>
        </ng-container>

        <ng-container matColumnDef="tipoAtendimento">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Tipo de atendimento
            </th>
            <td mat-cell *matCellDef="let tipoServico">
                {{ this.retornarDescricaoTipoAtendimento(tipoServico) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="buttons">
            <th mat-header-cell *matHeaderCellDef class="pl-12">
                Ações
            </th>
            <td mat-cell *matCellDef="let tipoServico" class="pr-24">
                <div fxLayout="row">
                    <button
                        mat-button
                        class="p-0 w-36"
                        matTooltip="Excluir tipo de serviço"
                        (click)="
                            this.excluirTipoServicoAtendimento(tipoServico)
                        "
                    >
                        <mat-icon class="material-icons">
                            delete
                        </mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>

        <tr
            mat-header-row
            *matHeaderRowDef="this.displayedColumnsTiposServico"
        ></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: this.displayedColumnsTiposServico"
        ></tr>
    </table>
    <mat-paginator
        fxFlex="100"
        #paginatorTiposServico
        [pageSizeOptions]="[3, 5, 10, 15]"
    ></mat-paginator>
</mat-dialog-content>

<mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end end"
    class="text-right mt-24"
>
    <button mat-stroked-button color="accent" mat-dialog-close>
        Fechar
    </button>
    <button
        mat-raised-button
        color="accent"
        class="ml-8"
        (click)="this.gravarDados()"
    >
        Salvar
    </button>
</mat-dialog-actions>
