import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AuthenticationService } from '../servicos';
import CryptoES from 'crypto-es';
import * as moment from 'moment';
import * as uuid from 'uuid';
import { ConfigService } from 'app/config/config.service';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
    constructor(public _authenticationService: AuthenticationService, private _configService: ConfigService) {}

    // tslint:disable-next-line: no-any
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const currentUser = this._authenticationService.usuarioAtual;

        if (request.url.includes(this._configService.config.apiUrl) && !request.url.includes("assets/config.json")) {
            let headers = {
                "x-host-token": this.gerarHostToken()
            };

            if (currentUser && currentUser.token) {
                headers["Authorization"] = `Bearer ${currentUser.token}`;
                headers["user_info"] = btoa(currentUser.email);
            }

            request = request.clone({
                setHeaders: headers,
            });
        }

        return next.handle(request);
    }

    private gerarHostToken() : string {
        var token = "MSRV|";
        const dataAtual: moment.Moment = moment(new Date());

        token += dataAtual.locale(moment.locale('pt-BR')).format('DD-MM-YYYY-HH-mm-ss') + "|";
        token += this.getRandomInt(675, 9856).toString() + "|";
        token += uuid.v4();

        const text = CryptoES.enc.Utf8.parse(token);
        const Key = CryptoES.enc.Utf8.parse(environment.dadosCriptografia.chave);
        const IV = CryptoES.enc.Utf8.parse(environment.dadosCriptografia.vetorInicializacao);

        const encryptedText = CryptoES.AES.encrypt(text, Key, { iv: IV, mode: CryptoES.mode.CBC, padding: CryptoES.pad.Pkcs7 });
        return encryptedText.toString();
    }

    private getRandomInt(min: number, max: number): number{
        min = Math.ceil(min);
        max = Math.floor(max);

        return Math.floor(Math.random() * (max - min)) + min;
    }
}
