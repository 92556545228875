import { DialogService } from './../../../_shared/servicos/dialogs/dialog.service';
import { Component, OnInit } from '@angular/core';
import { ToastService, AuthenticationService } from 'app/main/_shared/servicos';
import { AtendimentoControllerService } from 'app/main/_shared/servicos/controllers';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatDialogRef } from '@angular/material/dialog';
import { TipoExclusaoAgendamento } from 'app/main/_shared/models/_enums';

@Component({
    selector: 'exclusao-dados',
    templateUrl: './exclusao-dados.component.html',
    styleUrls: ['./exclusao-dados.component.scss']
})
export class ExclusaoDadosComponent implements OnInit{
    private _cns: string;
    public dataInicio: Date;
    public dataFim: Date;
    public agendamentosEncontrados: number;
    public tipoExclusao: TipoExclusaoAgendamento;
    public dataMaxima: Date;

    get tipoExclusaoAgendamento(): typeof TipoExclusaoAgendamento {
        return TipoExclusaoAgendamento;
    }

    constructor(
        private _atendimentoControllerService: AtendimentoControllerService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _authenticationService: AuthenticationService,
        private _toastService: ToastService,
        private _dialogService: DialogService,
        private _dialogRef: MatDialogRef<ExclusaoDadosComponent>,
    ) {
        this._cns = this._authenticationService.usuarioAtual.cns;
        this.tipoExclusao = TipoExclusaoAgendamento.Cancelado;
    }

    ngOnInit(): void {
        this.dataMaxima = new Date();
        this.dataMaxima.setDate(this.dataMaxima.getDate() - 1);

        this.dataInicio = new Date();
        this.dataFim = new Date();

        this.dataInicio.setDate(this.dataInicio.getDate() - 1);
        this.dataInicio.setMonth(this.dataInicio.getMonth() - 1);
    }

    public pesquisar(): void {
        this._atendimentoControllerService.quantidadeAgendamentosParaCancelamento(this.dataInicio, this.dataFim, this.tipoExclusao)
            .subscribe(quantidade => {
                this.agendamentosEncontrados = quantidade;

                if (!this.agendamentosEncontrados) {
                    this._toastService.mensagemErro(
                        'Não foram encontrados agendamentos de acordo com a pesquisa selecionada.'
                    );
                }

                this._fuseProgressBarService.hide();
            },
            (erro) => {
                console.log(erro);
                this._toastService.mensagemErro(
                    'Ocorreu um problema ao pesquisar os agendamentos'
                );
                this._fuseProgressBarService.hide();
            }
        );
    }

    public cancelarAgendamentos(): void {
        this._dialogService
            .dialogPerguntaAsync(
                'Atenção',
                'Tem certeza que deseja cancelar os agendamentos correspondentes à pesquisa selecionada?'
            )
            .subscribe((simOuNao) => {
                this.processarCancelamento(simOuNao);
            });
    }

    private processarCancelamento(simOuNao: boolean): void {
        if (!simOuNao)
            return;

        this._atendimentoControllerService.realizarCancelamentoDefinitivo(this.dataInicio, this.dataFim, this.tipoExclusao)
        .subscribe(() => {
            this._toastService.mensagemSuccesso(
                'Cancelamento realizado com sucesso'
            );

            this.agendamentosEncontrados = 0;
            this._fuseProgressBarService.hide();
        },
        (erro) => {
            console.log(erro);
            this._toastService.mensagemErro(
                'Ocorreu um problema ao pesquisar os agendamentos'
            );
            this._fuseProgressBarService.hide();
        }
    );
    }
}
