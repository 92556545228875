import { FuseNavigation } from '@fuse/types';
import { AuthenticationService } from 'app/main/_shared/servicos';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { TipoLogin } from 'app/main/_shared/models/_enums';

export const navigationEmpty: FuseNavigation[] = [];

export const navigationCartsys: FuseNavigation[] = [
    {
        id: 'configuracoes-cartsys',
        title: 'Cartsys',
        type: 'item',
        icon: 'settings',
        url: '/configuracoes-cartsys',
    },
];

export const navigationCartorio: FuseNavigation[] = [
    {
        id: 'atendimento-usuario',
        title: 'Atendimento ao usuário',
        type: 'item',
        icon: 'account_box',
        url: '/atendimento',
    },
    {
        id: 'video-conferencia',
        title: 'Vídeo Conferência',
        type: 'item',
        icon: 'video_call',
        url: '/video-conferencia',
    },
];

export const navigationAdminCartorio: FuseNavigation[] = [
    {
        id: 'configuracoes-cartorio',
        title: 'Cartório',
        type: 'item',
        icon: 'settings',
        url: '/configuracoes-cartorio',
    },
    {
        id: 'atendimento-usuario',
        title: 'Atendimento ao usuário',
        type: 'item',
        icon: 'account_box',
        url: '/atendimento',
    },
];

export function setNavigationMenu(
    authenticationService: AuthenticationService,
    fuseNavigationService: FuseNavigationService
): void {
    const tipoLogin = authenticationService.usuarioAtual
        ? authenticationService.usuarioAtual.tipoLogin
        : null;

    if (fuseNavigationService.getNavigation('main')) {
        fuseNavigationService.unregister('main');
    }

    switch (tipoLogin) {
        case TipoLogin.AdminCartorio: {
            fuseNavigationService.register('main', navigationAdminCartorio);
            break;
        }
        case TipoLogin.Cartorio: {
            fuseNavigationService.register('main', navigationCartorio);
            break;
        }
        case TipoLogin.Cartsys: {
            fuseNavigationService.register('main', navigationCartsys);
            break;
        }
        default: {
            fuseNavigationService.register('main', navigationEmpty);
            break;
        }
    }
    fuseNavigationService.setCurrentNavigation('main');
}
