import { JITSI_DEFAULT_CONFIG } from '../constantes';
import { DiaSemana, TipoAtendimentoUsuario } from '../models/_enums';

export function gerarIdRandomico(tamanho: number): string {
    let id = '';
    const caracteresValidos = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const tamanhoVetor = caracteresValidos.length;

    for (let i = 0; i < tamanho; i++) {
        id += caracteresValidos.charAt(
            Math.floor(Math.random() * tamanhoVetor)
        );
    }

    return id;
}

export function configuracoesJitsiPadrao(
    idSala: string,
    seletorComponente: string
): object {
    const config = JITSI_DEFAULT_CONFIG;
    config.roomName = idSala;
    config.parentNode = document.querySelector(seletorComponente);

    return config;
}

export function retornarDescricaoDiaSemana(dia: DiaSemana): string {
    switch (dia) {
        case DiaSemana.Domingo:
            return 'Domingo';
        case DiaSemana.Segunda:
            return 'Segunda-feira';
        case DiaSemana.Terca:
            return 'Terça-feira';
        case DiaSemana.Quarta:
            return 'Quarta-feira';
        case DiaSemana.Quinta:
            return 'Quinta-feira';
        case DiaSemana.Sexta:
            return 'Sexta-feira';
        case DiaSemana.Sabado:
            return 'Sábado';
    }
}

export function retornarDescricaoTipoAtendimento(
    tipoAtendimento: TipoAtendimentoUsuario
): string {
    switch (tipoAtendimento) {
        case TipoAtendimentoUsuario.ApenasPresencial:
            return 'Apenas presencial';
        case TipoAtendimentoUsuario.ApenasVirtual:
            return 'Apenas virtual';
        default:
            return 'Virtual e presencial';
    }
}