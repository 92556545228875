<h1 mat-dialog-title>Manutenção de usuários</h1>
<mat-dialog-content mat-dialog-content>
    <form
        id="usuario-form"
        fxLayout="column"
        fxLayoutAlign="center stretch"
        name="form"
        [formGroup]="this.form"
    >
        <div fxFlex="100" fxLayout="column" fxLayout.gt-sm="row">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50">
                <mat-label>Nome</mat-label>
                <input matInput type="text" formControlName="nome" />
                <mat-icon matSuffix class="disabled-text"
                    >account_circle</mat-icon
                >
                <mat-error *ngIf="form.get('nome').hasError('required')">
                    Nome obrigatório
                </mat-error>
                <mat-error *ngIf="form.get('nome').hasError('maxlength')">
                    Tamanho máximo (100)
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50">
                <mat-label>E-mail</mat-label>
                <input matInput type="email" formControlName="email" />
                <mat-icon matSuffix class="secondary-text">email</mat-icon>
                <mat-error *ngIf="form.get('email').hasError('required')">
                    Email é obrigatório
                </mat-error>
                <mat-error *ngIf="form.get('email').hasError('email')">
                    E-mail inválido
                </mat-error>
            </mat-form-field>
        </div>

        <div fxFlex="100" fxLayout="column" fxLayout.gt-sm="row">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="50">
                <mat-label>Senha</mat-label>
                <input
                    matInput
                    type="password"
                    formControlName="senha"
                    maxlength="20"
                />
                <mat-icon matSuffix class="disabled-text">vpn_key</mat-icon>
                <mat-error *ngIf="form.get('senha').hasError('required')">
                    Senha obrigatória
                </mat-error>
                <mat-error *ngIf="form.get('senha').hasError('maxlength')">
                    Tamanho máximo (20)
                </mat-error>
            </mat-form-field>

            <mat-form-field
                appearance="outline"
                fxFlex.xs="100"
                fxFlex.gt-xs="50"
            >
                <mat-label>Tipo de atendimento realizado</mat-label>
                <mat-select formControlName="tipoAtendimento">
                    <mat-option [value]="this.tipoAtendimento.ApenasPresencial"
                        >Apenas presencial</mat-option
                    >
                    <mat-option [value]="this.tipoAtendimento.ApenasVirtual"
                        >Apenas virtual</mat-option
                    >
                    <mat-option
                        [value]="this.tipoAtendimento.VirtualEPresencial"
                        >Virtual e presencial</mat-option
                    >
                </mat-select>
                <mat-error
                    *ngIf="form.get('tipoAtendimento').hasError('required')"
                >
                    Tipo de atendimento obrigatório
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end end" class="text-right">
    <button mat-stroked-button color="accent" mat-dialog-close>
        Fechar
    </button>
    <button
        mat-raised-button
        color="accent"
        class="ml-8"
        (click)="this.gravarDados()"
        [disabled]="this.form.invalid"
    >
        Salvar
    </button>
</mat-dialog-actions>
