import { TipoResposta, TipoMascara } from '../_enums';

export class PerguntaCartorio {
    id: string;
    idTipoServico: string;
    indice: number;
    cns: string;
    texto: string;
    tipo: TipoResposta;
    opcoesMultiplaEscolha: string;
    mascara: TipoMascara;
}
