import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from './models/dialog-data';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
    selector: 'dialogInformacao',
    templateUrl: './views/dialog-informacao.html',
})
export class DialogInformacaoComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: DialogData,
        public dialogRef: MatDialogRef<DialogInformacaoComponent>
    ) {}

    eventoFechar(): void {
        if (this.data.fnFechar != null) {
            this.data.fnFechar();
        }
        this.dialogRef.close();
    }
}
