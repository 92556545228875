import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    AfterViewInit,
} from '@angular/core';
import {
    trigger,
    state,
    style,
    animate,
    transition,
} from '@angular/animations';
import {
    HttpClient,
    HttpResponse,
    HttpRequest,
    HttpEventType,
    HttpErrorResponse,
} from '@angular/common/http';
import { Subscription } from 'rxjs';
import { of } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { FileImageService } from '../../servicos/file-image.service';
import { ConfigService } from 'app/config/config.service';

@Component({
    selector: 'material-file-upload',
    templateUrl: './material-file-upload.component.html',
    styleUrls: ['./material-file-upload.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('in', style({ opacity: 100 })),
            transition('* => void', [animate(300, style({ opacity: 0 }))]),
        ]),
    ],
})
export class MaterialFileUploadComponent implements OnInit {
    @Input()
    text = 'Upload';
    @Input() tipo: 'img' | 'file' = 'img';

    // tslint:disable-next-line: no-output-native
    @Output() complete = new EventEmitter<string>();

    private param = 'arquivos';

    public files: Array<FileUploadModel> = [];

    constructor(
        private _http: HttpClient,
        private _fileImageService: FileImageService,
        private _configService: ConfigService
    ) {}

    ngOnInit(): void {}

    public onClick(): void {
        this._fileImageService
            .uploadFile(this.tipo)
            .then(({ image, fileName, orientation }) => {
                if (this.tipo === 'img') {
                    this._fileImageService
                        .compressFile(image, orientation, 50, 50)
                        .then((result) => {
                            const newFile = this._fileImageService.dataURItoBlob(
                                result,
                                fileName
                            );

                            this.files.push({
                                data: newFile,
                                state: 'in',
                                inProgress: false,
                                progress: 0,
                                canRetry: false,
                                canCancel: true,
                            });

                            this.uploadFiles();
                        });
                } else {
                    const newFile = this._fileImageService.dataURItoBlob(
                        image,
                        fileName
                    );

                    this.files.push({
                        data: newFile,
                        state: 'in',
                        inProgress: false,
                        progress: 0,
                        canRetry: false,
                        canCancel: true,
                    });
                    this.uploadFiles();
                }
            });
    }

    public cancelFile(file: FileUploadModel): void {
        file.sub.unsubscribe();
        this.removeFileFromArray(file);
    }

    public retryFile(file: FileUploadModel): void {
        this.uploadFile(file);
        file.canRetry = false;
    }

    private uploadFile(file: FileUploadModel): void {
        const fd = new FormData();
        fd.append(this.param, file.data);
        const target = this.tipo === 'img' ? 'imagem' : 'arquivo';

        const url = `${this._configService.config.apiUrl}upload/${target}`;

        const req = new HttpRequest('POST', url, fd, {
            reportProgress: true,
        });

        file.inProgress = true;
        file.sub = this._http
            .request(req)
            .pipe(
                map((event) => {
                    switch (event.type) {
                        case HttpEventType.UploadProgress:
                            file.progress = Math.round(
                                (event.loaded * 100) / event.total
                            );
                            break;
                        case HttpEventType.Response:
                            return event;
                    }
                }),
                tap((message) => {}),
                last(),
                catchError((error: HttpErrorResponse) => {
                    file.inProgress = false;
                    file.canRetry = true;
                    return of(`Falha ao fazer o upload de ${file.data.name}.`);
                })
            )
            .subscribe((event: any) => {
                if (typeof event === 'object') {
                    this.removeFileFromArray(file);
                    this.complete.emit(event.body);
                }
            });
    }

    private uploadFiles(): void {
        this.files.forEach((file) => {
            this.uploadFile(file);
        });
    }

    private removeFileFromArray(file: FileUploadModel): void {
        const index = this.files.indexOf(file);
        if (index > -1) {
            this.files.splice(index, 1);
        }
    }
}

export class FileUploadModel {
    data: File;
    state: string;
    inProgress: boolean;
    progress: number;
    canRetry: boolean;
    canCancel: boolean;
    sub?: Subscription;
}
