<div fxLayout="column" class="top-messages">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <button
            mat-icon-button
            aria-label="Voltar"
            [disabled]="this.carregandoMsg"
            (click)="this.voltar()"
        >
            <mat-icon>arrow_back</mat-icon>
        </button>
        <span class="title">
            Agendamento de atendimento
            {{
                this.tipoAtendimento == tipoAtendimentoCartorio.Presencial
                    ? 'presencial'
                    : 'virtual'
            }} <span fxHide.gt-sm> -  {{ this.dadosCartorio.nome }}</span>
        </span>
    </div>
</div>

<!-- #region Lista de mensagens -->
<mat-list
    class="lista-mensagens"
    [ngClass]="
        this.selecionandoAgendamento ? 'lista-mensagens-agendamento' : ''
    "
    id="listaMensagens"
>
    <mat-list-item class="message-item">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" matLine>
            <img
                class="message-avatar"
                src="assets/images/avatars/profile.jpg"
            />
            <div
                fxLayout="column"
                fxFlex
                class="conversa-app"
                fxLayoutAlign="start start"
            >
                <span
                    ><b>{{ this.dadosCartorio.nomeAtendenteVirtual }}</b></span
                >
                <p class="conteudo-message">
                    Olá !<br/>Meu nome é <b>{{this.dadosCartorio.nomeAtendenteVirtual}}</b> eu sou seu atendente virtual.<br/><br/>
                    <a style="color:white; cursor: pointer;" (click)="this.exibirOrientacoesLgpd()" >Clique aqui para ver orientações sobre a lei geral de proteção de dados.</a>
                </p>
            </div>
        </div>
    </mat-list-item>

    <mat-list-item class="message-item">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100" matLine>
            <img
                class="message-avatar"
                src="assets/images/avatars/profile.jpg"
            />
            <div
                fxLayout="column"
                fxFlex
                class="conversa-app"
                fxLayoutAlign="start start"
            >
                <p
                    class="conteudo-message"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    *ngIf="!this.tipoServicoSelecionado"
                >
                    <span class="pb-12">
                        <b>
                            Selecione o tipo de atendimento que deseja receber
                        </b>
                    </span>
                    <button
                        mat-raised-button
                        color="white"
                        class="botao-tipo-servico"
                        *ngFor="let tipoServico of this.tiposServico"
                        (click)="this.selecionarTipoServico(tipoServico)"
                    >
                        {{ tipoServico.descricao }}
                    </button>
                </p>
                <p
                    class="conteudo-message"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    *ngIf="!!this.tipoServicoSelecionado"
                >
                    <span fxLayout="column">
                        <b>Tipo de serviço selecionado:</b>
                        <p
                            class="tipo-servico-selecionado"
                            fxFlexAlign="center center"
                            fxFlex="100"
                        >
                            {{ this.tipoServicoSelecionado.descricao }}
                        </p>
                    </span>
                </p>
            </div>
        </div>
    </mat-list-item>

    <mat-list-item class="message-item" *ngFor="let message of this.messages">
        <div
            fxLayout="row"
            fxLayoutAlign="start center"
            *ngIf="message.doAplicativo && message.conteudo"
            fxFlex="100"
            matLine
        >
            <img
                class="message-avatar"
                src="assets/images/avatars/profile.jpg"
            />
            <div
                fxLayout="column"
                fxFlex
                class="conversa-app"
                fxLayoutAlign="start start"
            >
                <span
                    ><b>{{ this.dadosCartorio.nomeAtendenteVirtual }}</b></span
                >
                <p class="conteudo-message">
                    {{ message.conteudo }}
                </p>
            </div>
        </div>

        <div
            fxLayout="row"
            fxLayoutAlign="end start"
            *ngIf="!message.doAplicativo"
            fxFlex="100"
            matLine
        >
            <div matLine fxLayout="column" fxFlex class="conversa-visitante">
                <span><b>Visitante</b></span>
                <p
                    class="conteudo-message"
                    *ngIf="
                        message.tipo !== tipoResposta.Imagem &&
                        message.tipo !== tipoResposta.Arquivo
                    "
                >
                    <span>{{ message.conteudo }}</span>
                </p>

                <p
                    class="conteudo-message"
                    *ngIf="message.tipo === tipoResposta.Arquivo"
                >
                    <span>
                        <a
                            [href]="message.conteudo"
                            target="_blank"
                            class="text-white"
                        >
                            <b>Arquivo enviado</b>
                        </a>
                    </span>
                </p>

                <p
                    class="conteudo-message"
                    *ngIf="message.tipo === tipoResposta.Imagem"
                    fxFlexAlign="center center"
                >
                    <img
                        [src]="message.conteudo"
                        alt="Imagem selecionada"
                        width="200"
                        height="400"
                    />
                </p>
            </div>
        </div>
    </mat-list-item>

    <mat-list-item class="message-item item-carregando">
        <div
            fxLayout="row"
            fxLayoutAlign="start center"
            *ngIf="this.carregandoMsg"
            fxFlex="100"
            matLine
        >
            <img
                class="message-avatar"
                src="assets/images/avatars/profile.jpg"
            />

            <div fxLayout="column" fxFlex fxLayoutAlign="start start">
                <img class="carregando-msg" src="assets/icons/dots.gif" />
            </div>
        </div>
    </mat-list-item>
</mat-list>
<!-- #endregion -->

<!-- #region Inputs -->
<div class="pt-15 chat-footer-container" fxFlex="0 0 auto" fxLayout="column">
    <div
        class="w-100-p"
        fxFlex="0 0 auto"
        fxLayout="row"
        fxLayoutAlign="center center"
        *ngIf="!this.selecionandoAgendamento && !!this.tipoServicoSelecionado"
    >
        <div
            fxFlex="90"
            fxLayout="row"
            fxLayoutAlign="start center"
            class="w-90-p"
        >
            <mat-form-field
                fxFlex
                floatLabel="never"
                appearance="standard"
                *ngIf="
                    !this.perguntaAtual ||
                    this.perguntaAtual.tipo == tipoResposta.Texto
                "
            >
                <input
                    id="inputResposta"
                    matInput
                    placeholder="Digite sua mensagem"
                    [(ngModel)]="this.texto"
                    (keyup.enter)="this.addMessage($event)"
                    #inputResposta="ngModel"
                    type="text"
                    [disabled]="this.carregandoMsg"
                />
            </mat-form-field>

            <div
                fxFlex
                class="h-64"
                *ngIf="
                    !!this.perguntaAtual &&
                    this.perguntaAtual.tipo == tipoResposta.Imagem
                "
            >
                <material-file-upload
                    text="Selecionar Imagem"
                    tipo="img"
                    (complete)="this.uploadImagem($event)"
                >
                </material-file-upload>
            </div>

            <div
                fxFlex
                class="h-64"
                *ngIf="
                    !!this.perguntaAtual &&
                    this.perguntaAtual.tipo == tipoResposta.Arquivo
                "
            >
                <material-file-upload
                    text="Selecionar o arquivo"
                    tipo="file"
                    (complete)="this.uploadArquivo($event)"
                >
                </material-file-upload>
            </div>

            <mat-form-field
                fxFlex
                floatLabel="never"
                appearance="standard"
                *ngIf="
                    !!this.perguntaAtual &&
                    this.perguntaAtual.tipo == tipoResposta.Mascara &&
                    this.perguntaAtual.mascara == this.tipoMascara.Telefone
                "
            >
                <input
                    id="inputResposta"
                    matInput
                    placeholder="Informe o telefone"
                    [dropSpecialCharacters]="false"
                    [(ngModel)]="this.telefone"
                    [mask]="this.mascaraTelefone"
                    #inputResposta="ngModel"
                    (input)="this.alteracaoTelefone($event.target.value)"
                    [disabled]="this.carregandoMsg"
                    (keyup.enter)="this.addMessage($event)"
                />
            </mat-form-field>

            <mat-form-field
                fxFlex
                floatLabel="never"
                appearance="standard"
                *ngIf="
                    !!this.perguntaAtual &&
                    this.perguntaAtual.tipo == tipoResposta.Mascara &&
                    this.perguntaAtual.mascara == this.tipoMascara.Cpf
                "
            >
                <input
                    id="inputResposta"
                    matInput
                    placeholder="Informe o CPF"
                    mask="000.000.000-00"
                    [dropSpecialCharacters]="false"
                    [(ngModel)]="this.cpf"
                    [disabled]="this.carregandoMsg"
                    #inputResposta="ngModel"
                    (keyup.enter)="this.addMessage($event)"
                    required
                />
                <div
                    role="alert"
                    class="mat-error erro-cpf"
                    *ngIf="!this.cpfValido"
                >
                    CPF Inválido
                </div>
            </mat-form-field>

            <mat-form-field
                fxFlex
                floatLabel="never"
                appearance="standard"
                *ngIf="
                    !!this.perguntaAtual &&
                    this.perguntaAtual.tipo == tipoResposta.Mascara &&
                    this.perguntaAtual.mascara == this.tipoMascara.Cep
                "
            >
                <input
                    id="inputResposta"
                    matInput
                    placeholder="Informe o CEP"
                    mask="00000-000"
                    [(ngModel)]="this.cep"
                    ngClass="this.cepValido ? '' : 'ng-invalid'"
                    [dropSpecialCharacters]="false"
                    [disabled]="this.carregandoMsg"
                    #inputResposta="ngModel"
                    (keyup.enter)="this.addMessage($event)"
                />
            </mat-form-field>

            <mat-form-field
                fxFlex
                floatLabel="never"
                appearance="standard"
                *ngIf="
                    !!this.perguntaAtual &&
                    this.perguntaAtual.tipo == tipoResposta.MultiplaEscolha
                "
            >
                <mat-label>Selecione uma opção</mat-label>
                <mat-select [(value)]="this.texto">
                    <mat-option *ngFor="let opcao of this.opcoesSelect" [value]="opcao">
                        {{opcao}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field
                fxFlex
                floatLabel="never"
                appearance="standard"
                *ngIf="
                    !!this.perguntaAtual &&
                    this.perguntaAtual.tipo == tipoResposta.Numero
                "
            >
                <input
                    id="inputResposta"
                    matInput
                    placeholder="Número"
                    [(ngModel)]="this.numero"
                    [disabled]="this.carregandoMsg"
                    #inputResposta="ngModel"
                    (keyup.enter)="this.addMessage($event)"
                />
            </mat-form-field>

            <button
                mat-icon-button
                type="submit"
                aria-label="Enviar mensagem"
                [disabled]="this.carregandoMsg || !this.inputsValidos"
                *ngIf="
                    !!this.perguntaAtual &&
                    this.perguntaAtual.tipo !== tipoResposta.Imagem &&
                    this.perguntaAtual.tipo !== tipoResposta.Arquivo
                "
                (click)="this.addMessage($event)"
            >
                <mat-icon>send</mat-icon>
            </button>
        </div>
    </div>

    <div
        class="w-100-p"
        fxFlex="0 0 auto"
        fxLayout="column"
        fxLayoutAlign="center center"
        *ngIf="this.selecionandoAgendamento"
    >
        <h2>Selecione o horário desejado:</h2>
        <div>
            <mat-form-field fxFlex="45">
                <mat-label>Data</mat-label>
                <input
                    matInput
                    [matDatepickerFilter]="this.filtroDatas"
                    disabled
                    [matDatepicker]="picker"
                    (dateChange)="this.selecionarData($event)"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                    disabled="false"
                ></mat-datepicker-toggle>
                <mat-datepicker
                    touchUi
                    #picker
                    disabled="false"
                ></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="50">
                <mat-label>Horário</mat-label>
                <mat-select
                    [disabled]="this.horariosDisponiveis.length === 0"
                    [(ngModel)]="this.horarioSelecionado"
                >
                    <mat-option
                        [value]="horario"
                        *ngFor="let horario of this.horariosDisponiveis"
                    >
                        {{ horario }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <button
            mat-raised-button
            color="accent"
            class="botao-tipo-servico"
            [disabled]="!this.dataSelecionada || !this.horarioSelecionado"
            (click)="this.confirmarAgendamento()"
        >
            Confirmar agendamento
        </button>
    </div>
</div>
<!-- #endregion -->
