import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeetingComponent } from './meeting/meeting.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    FuseSidebarModule,
    FuseWidgetModule,
    FuseDemoModule,
    FuseSearchBarModule,
} from '@fuse/components';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { EnviarWhatsAppComponent } from './meeting/enviar-whatsapp/enviar-whatsapp.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialFileUploadComponent } from './material-file-upload/material-file-upload.component';
import {
    MatProgressBar,
    MatProgressBarModule,
} from '@angular/material/progress-bar';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatTabsModule,
        MatTooltipModule,
        MatToolbarModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        FuseSharedModule,
        FuseWidgetModule,
        FuseDemoModule,
        FuseSidebarModule,
        FuseSearchBarModule,
        NgxMaskModule,
        ClipboardModule,
        MatProgressBarModule,
    ],
    declarations: [
        EnviarWhatsAppComponent,
        MeetingComponent,
        MaterialFileUploadComponent,
    ],
    exports: [MeetingComponent, MaterialFileUploadComponent],
})
export class ComponentesModule {}
