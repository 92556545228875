<h1 mat-dialog-title>Configurações gerais</h1>
<mat-dialog-content mat-dialog-content inner-scroll>
    <form
        id="configuracoes-gerais-form"
        fxLayout="column"
        fxLayoutAlign="center stretch"
        name="form"
        [formGroup]="this.form"
    >
        <div fxFlex="100" fxLayout="row">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm>
                <mat-label>Url consulta de andamentos (Notas)</mat-label>
                <input
                    matInput
                    formControlName="urlConsultaAndamentosNotas"
                    maxlength="100"
                />
                <mat-error
                    *ngIf="
                        form
                            .get('urlConsultaAndamentosNotas')
                            .hasError('required')
                    "
                >
                    Url consulta de andamentos (Notas) é obrigatória
                </mat-error>
                <mat-error
                    *ngIf="
                        form
                            .get('urlConsultaAndamentosNotas')
                            .hasError('maxlength')
                    "
                >
                    Tamanho máximo (100)
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="100" fxLayout="row">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm>
                <mat-label>Url consulta de andamentos (Imóveis)</mat-label>
                <input
                    matInput
                    formControlName="urlConsultaAndamentosImoveis"
                    maxlength="100"
                />
                <mat-error
                    *ngIf="
                        form
                            .get('urlConsultaAndamentosImoveis')
                            .hasError('required')
                    "
                >
                    Url consulta de andamentos (Imóveis) é obrigatória
                </mat-error>
                <mat-error
                    *ngIf="
                        form
                            .get('urlConsultaAndamentosImoveis')
                            .hasError('maxlength')
                    "
                >
                    Tamanho máximo (100)
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="100" fxLayout="row">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm>
                <mat-label>Url consulta de andamentos (Protesto)</mat-label>
                <input
                    matInput
                    formControlName="urlConsultaAndamentosProtesto"
                    maxlength="100"
                />
                <mat-error
                    *ngIf="
                        form
                            .get('urlConsultaAndamentosProtesto')
                            .hasError('required')
                    "
                >
                    Url consulta de andamentos (Protesto) é obrigatória
                </mat-error>
                <mat-error
                    *ngIf="
                        form
                            .get('urlConsultaAndamentosProtesto')
                            .hasError('maxlength')
                    "
                >
                    Tamanho máximo (100)
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end end"
    class="text-right actions-configuracoes-gerais"
>
    <button mat-stroked-button color="accent" mat-dialog-close>
        Fechar
    </button>
    <button
        mat-raised-button
        color="accent"
        class="ml-8"
        (click)="this.gravarDados()"
        [disabled]="this.form.invalid"
    >
        Salvar
    </button>
</mat-dialog-actions>
