import { Component, AfterViewInit, OnInit } from '@angular/core';
import { AgendamentoAtendimento } from '../_shared/models/cartorio';
import {
    TipoAtendimentoCartorio,
    TipoAtendimentoUsuario,
    StatusAtendimentoUsuarioPesquisa,
    StatusAtendimentoUsuario
} from '../_shared/models/_enums';
import { TipoServicoAtendimento } from '../_shared/models/cartorio/tipo-servico-atendimento.model';
import { TipoServicoControllerService } from '../_shared/servicos/controllers/tipo-servico-controller.service';
import { DialogService } from '../_shared/servicos/dialogs';
import { ManutencaoAtendimentoComponent } from './manutencao-atendimento/manutencao-atendimento.component';
import { Overlay } from '@angular/cdk/overlay';
import { AuthenticationService, ToastService } from '../_shared/servicos';
import { AtendimentoControllerService } from '../_shared/servicos/controllers';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { mascaraTelefone } from '../_shared/funcoes';
import { FiltroAgendamentosDTO } from '../_shared/models/dtos/filtro-agendamentos-dto.model';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'atendimento-component',
    templateUrl: './atendimento.component.html',
    styleUrls: ['./atendimento.component.scss'],
})
export class AtendimentoComponent implements OnInit, AfterViewInit {
    public agendamentos: Array<AgendamentoAtendimento> = [];
    public tiposServico: Array<TipoServicoAtendimento> = [];
    public statusSelecionado: StatusAtendimentoUsuarioPesquisa =
        StatusAtendimentoUsuarioPesquisa.Todos;
    public tipoAtendimentoSelecionado: TipoAtendimentoUsuario;
    public dataInicio: Date;
    public dataFim: Date;
    public protocolo = '';
    public cpfRequirente = '';
    public nomeRequirente = '';
    public telefone = '';
    public mascaraTelefone = '(00) 0000-0000';
    public idTipoServicoSelecionado: '*';

    constructor(
        private _atendimentoController: AtendimentoControllerService,
        private _tipoServicoController: TipoServicoControllerService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _toastService: ToastService,
        private _dialogService: DialogService,
        private _authenticationService: AuthenticationService,
        private _datepipe: DatePipe,
        private _overlay: Overlay
    ) {}

    ngOnInit(): void {
        setTimeout(() => {
            this.dataInicio = new Date();
            this.dataFim = new Date(
                this.dataInicio.getFullYear(),
                this.dataInicio.getMonth(),
                this.dataInicio.getDate() + 5
            );
        });
    }

    ngAfterViewInit(): void {
        this._fuseProgressBarService.show();
        setTimeout(() => {
            this.idTipoServicoSelecionado = '*';
        });

        setTimeout(() => {
            const tipoAtendimento = this._authenticationService.usuarioAtual
                .tipoAtendimento;

            if (tipoAtendimento != null && tipoAtendimento !== undefined) {
                this.tipoAtendimentoSelecionado = tipoAtendimento;
            } else {
                this.tipoAtendimentoSelecionado =
                    TipoAtendimentoUsuario.VirtualEPresencial;
            }

            this._tipoServicoController
                .listarTiposServicoAtendimento(
                    this._authenticationService.usuarioAtual.cns,
                    TipoAtendimentoUsuario.VirtualEPresencial
                )
                .subscribe(
                    (tiposServico) => {
                        this.tiposServico = tiposServico;
                        this._fuseProgressBarService.hide();
                        this.pesquisar();
                    },
                    (erro) => {
                        console.log(erro);
                        this._toastService.mensagemErro(
                            'Não foi possível carregar os dados.'
                        );
                        this._fuseProgressBarService.hide();
                    }
                );
        });
    }

    public descricaoStatusAtendimento(agendamento: AgendamentoAtendimento): string {
        const status = agendamento.status;
        const hoje = new Date();
        const dataAgendamento = new Date(agendamento.dataAgendamento);

        if (dataAgendamento < hoje && status == StatusAtendimentoUsuario.Pendente) {
            return "Não executado";
        }

        switch (status) {
            case StatusAtendimentoUsuario.Pendente:
                return 'Pendente';
            case StatusAtendimentoUsuario.AguardandoResposta:
                return 'Aguardando resposta';
            case StatusAtendimentoUsuario.Atendido:
                return 'Atendido';
            default:
                return 'Cancelado';
        }
    }

    public classeStatusAtendimento(
        status: StatusAtendimentoUsuario
    ): string {
        switch (status) {
            case StatusAtendimentoUsuario.Pendente:
            case StatusAtendimentoUsuario.AguardandoResposta:
                return 'laranja';
            case StatusAtendimentoUsuario.Atendido:
                return 'verde';
            case StatusAtendimentoUsuario.AtendimentoCancelado:
                return 'vermelho';
        }
    }

    public get statusAtendimentoUsuario(): typeof StatusAtendimentoUsuarioPesquisa {
        return StatusAtendimentoUsuarioPesquisa;
    }

    public get tipoAtendimentoUsuario(): typeof TipoAtendimentoUsuario {
        return TipoAtendimentoUsuario;
    }

    public descricaoTipoAtendimento(
        tipoAtendimento: TipoAtendimentoCartorio
    ): string {
        switch (tipoAtendimento) {
            case TipoAtendimentoCartorio.Presencial:
                return 'Presencial';
            case TipoAtendimentoCartorio.Virtual:
                return 'Virtual';
        }
    }

    public realizarAtendimento(agendamento: AgendamentoAtendimento): void {
        this._dialogService
            .dialogGenerico(ManutencaoAtendimentoComponent, {
                scrollStrategy: this._overlay.scrollStrategies.noop(),
                height: 'auto',
                maxHeight: '80%',
                data: {
                    agendamento: agendamento,
                    descricaoTipoServico: this.descricaoTipoServico(
                        agendamento.idTipoServico
                    ),
                },
            })
            .subscribe(() => {
                this.pesquisar();
            });
    }

    public descricaoTipoServico(idTipoServico: string): string {
        const tipoServico = this.tiposServico.filter(
            (tipo) => tipo.id === idTipoServico
        )[0];
        return tipoServico.descricao;
    }

    public pesquisar(): void {
        this._fuseProgressBarService.show();
        const inicio = this._datepipe.transform(this.dataInicio, 'dd-MM-yyyy');
        const fim = this._datepipe.transform(this.dataFim, 'dd-MM-yyyy');

        const filtros: FiltroAgendamentosDTO = {
            cns: this._authenticationService.usuarioAtual.cns,
            inicio,
            fim,
            tipoAtendimento: this.tipoAtendimentoSelecionado,
            status: this.statusSelecionado,
            protocolo: this.protocolo ?? null,
            cpfRequirente: this.cpfRequirente,
            nomeRequirente: this.nomeRequirente,
            telefone: this.telefone,
            idTipoServico:
                this.idTipoServicoSelecionado === '*'
                    ? null
                    : this.idTipoServicoSelecionado,
        };

        this._atendimentoController.listarAgendamentos(filtros).subscribe(
            (agendamentos) => {
                this._fuseProgressBarService.hide();
                this.agendamentos = agendamentos;
            },
            (erro) => {
                console.log(erro);
                this._toastService.mensagemErro(
                    'Não foi possível carregar os dados.'
                );
                this._fuseProgressBarService.hide();
            }
        );
    }

    public changeMascaraTelefone(telefone: string): void {
        this.mascaraTelefone = mascaraTelefone(telefone);
        this.cpfRequirente = '';
        this.nomeRequirente = '';
        this.protocolo = '';
    }

    public changeCpf(): void {
        this.telefone = '';
        this.nomeRequirente = '';
        this.protocolo = '';
    }

    public changeProtocolo(): void {
        this.cpfRequirente = '';
        this.telefone = '';
        this.nomeRequirente = '';
    }

    public changeNomeRequirente(): void {
        this.cpfRequirente = '';
        this.telefone = '';
        this.protocolo = '';
    }
}
