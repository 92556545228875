import { DialogFormBase } from 'app/main/_shared/contratos';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ConfiguracoesGeraisCartsys } from 'app/main/_shared/models';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastService } from 'app/main/_shared/servicos';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'app/main/_shared/servicos/dialogs';
import { ConfiguracoesGeraisControllerService } from 'app/main/_shared/servicos/controllers/configuracoes-gerais-controller.service';

@Component({
    selector: 'configuracoes-gerais-cartsys',
    templateUrl: './configuracoes-gerais.component.html',
    styleUrls: ['./configuracoes-gerais.component.scss'],
})
export class ConfiguracoesGeraisComponent extends DialogFormBase<
    ConfiguracoesGeraisComponent
> {
    private _config: ConfiguracoesGeraisCartsys;

    constructor(
        private _configController: ConfiguracoesGeraisControllerService,
        private _toastService: ToastService,
        public fuseProgressBarService: FuseProgressBarService,
        public formBuilder: FormBuilder,
        public dialogService: DialogService,
        public dialogRef: MatDialogRef<ConfiguracoesGeraisComponent>
    ) {
        super(formBuilder, dialogService, dialogRef);
    }

    construirForm(formBuilder: FormBuilder): void {
        this.form = formBuilder.group({
            urlConsultaAndamentosNotas: [
                '',
                [Validators.required, Validators.maxLength(150)],
            ],
            urlConsultaAndamentosImoveis: [
                '',
                [Validators.required, Validators.maxLength(150)],
            ],
            urlConsultaAndamentosProtesto: [
                '',
                [Validators.required, Validators.maxLength(150)],
            ],
        });
    }

    carregarDados(): void {
        this.fuseProgressBarService.show();
        this._configController.listarConfiguracoesGerais().subscribe(
            (config: ConfiguracoesGeraisCartsys) => {
                this._config = config;

                this.form.patchValue(this._config);
                this.fuseProgressBarService.hide();
            },
            (err) => {
                this._toastService.mensagemErro(
                    'Ocorreu um erro ao carregar os dados'
                );
                console.log(err);
                this.fuseProgressBarService.hide();
            }
        );
    }

    gravarDados(): void {
        this.fuseProgressBarService.show();

        this._config = { ...this.form.value };

        this._configController
            .alterarConfiguracoesGerais(this._config)
            .subscribe(
                (config: ConfiguracoesGeraisCartsys) => {
                    this._config = config;

                    this.form.patchValue(this._config);

                    this._toastService.mensagemSuccesso(
                        'Dados armazenados com sucesso !'
                    );

                    this._dialogRef.close();
                    this.fuseProgressBarService.hide();
                },
                (err) => {
                    this._toastService.mensagemErro(
                        'Ocorreu um erro ao carregar os dados'
                    );
                    console.log(err);
                    this.fuseProgressBarService.hide();
                }
            );
    }
}
