export const JITSI_DEFAULT_CONFIG = {
    roomName: '',
    parentNode: null,
    configOverwrite: {
        fileRecordingsEnabled: false,
        fileRecordingsServiceEnabled: false,
        enableWelcomePage: false,
        enableClosePage: false,
        defaultLanguage: 'ptBR',
        enableCalendarIntegration: false,
        prejoinPageEnabled: false,
        enableInsecureRoomNameWarning: false,
        makeJsonParserHappy: ' ',
    },
    interfaceConfigOverwrite: {
        DEFAULT_BACKGROUND: '#8aa8a2',
        DISABLE_VIDEO_BACKGROUND: true,
        INITIAL_TOOLBAR_TIMEOUT: 20000,
        TOOLBAR_TIMEOUT: 4000,
        TOOLBAR_ALWAYS_VISIBLE: false,
        DEFAULT_REMOTE_DISPLAY_NAME: 'Cliente',
        DEFAULT_LOCAL_DISPLAY_NAME: 'Cartório',
        SHOW_JITSI_WATERMARK: false,
        JITSI_WATERMARK_LINK: '',
        SHOW_WATERMARK_FOR_GUESTS: false,
        SHOW_BRAND_WATERMARK: false,
        BRAND_WATERMARK_LINK: '',
        SHOW_POWERED_BY: false,
        SHOW_DEEP_LINKING_IMAGE: false,
        GENERATE_ROOMNAMES_ON_WELCOME_PAGE: false,
        DISPLAY_WELCOME_PAGE_CONTENT: false,
        DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,
        APP_NAME: '',
        NATIVE_APP_NAME: '',
        PROVIDER_NAME: '',
        LANG_DETECTION: false,
        INVITATION_POWERED_BY: false,
        AUTHENTICATION_ENABLE: false,
        TOOLBAR_BUTTONS: [
            'fullscreen',
            'fodeviceselection',
            'profile',
            'etherpad',
            'settings',
            'filmstrip',
            'videobackgroundblur',
            'download',
            'mute-everyone',
        ],
        SETTINGS_SECTIONS: ['devices', 'language', 'moderator', 'profile'],
        VIDEO_LAYOUT_FIT: 'both',
        filmStripOnly: false,
        VERTICAL_FILMSTRIP: false,
        CLOSE_PAGE_GUEST_HINT: false,
        SHOW_PROMOTIONAL_CLOSE_PAGE: false,
        RANDOM_AVATAR_URL_PREFIX: false,
        RANDOM_AVATAR_URL_SUFFIX: false,
        FILM_STRIP_MAX_HEIGHT: 120,
        ENABLE_FEEDBACK_ANIMATION: false,
        DISABLE_FOCUS_INDICATOR: false,
        DISABLE_DOMINANT_SPEAKER_INDICATOR: false,
        DISABLE_TRANSCRIPTION_SUBTITLES: false,
        DISABLE_RINGING: true,
        AUDIO_LEVEL_PRIMARY_COLOR: 'rgba(255,255,255,0.4)',
        AUDIO_LEVEL_SECONDARY_COLOR: 'rgba(255,255,255,0.2)',
        POLICY_LOGO: null,
        LOCAL_THUMBNAIL_RATIO: 16 / 9,
        REMOTE_THUMBNAIL_RATIO: 1,
        LIVE_STREAMING_HELP_LINK: 'https://jitsi.org/live',
        MOBILE_APP_PROMO: true,
        MAXIMUM_ZOOMING_COEFFICIENT: 1.3,
        CONNECTION_INDICATOR_AUTO_HIDE_ENABLED: true,
        CONNECTION_INDICATOR_AUTO_HIDE_TIMEOUT: 5000,
        CONNECTION_INDICATOR_DISABLED: false,
        VIDEO_QUALITY_LABEL_DISABLED: false,
        RECENT_LIST_ENABLED: true,
        OPTIMAL_BROWSERS: ['chrome', 'chromium', 'firefox', 'nwjs', 'electron'],
        UNSUPPORTED_BROWSERS: [],
        AUTO_PIN_LATEST_SCREEN_SHARE: 'remote-only',
        DISABLE_PRESENCE_STATUS: false,
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: false,
        SHOW_CHROME_EXTENSION_BANNER: false,
    },
};

export const WIDTH_XS = 599;
export const WIDTH_SM = 959;
export const WIDTH_MD = 1279;
export const WIDTH_LG = 1919;
export const WIDTH_XL = 5000;

export const MODAL_WIDTH_XS = '15%';
export const MODAL_WIDTH_SM = '30%';
export const MODAL_WIDTH_MD = '50%';
export const MODAL_WIDTH_LG = '70%';
export const MODAL_WIDTH_XL = '90%';

export const MODAL_HEIGHT_XS = '15%';
export const MODAL_HEIGHT_SM = '30%';
export const MODAL_HEIGHT_MD = '50%';
export const MODAL_HEIGHT_LG = '70%';
export const MODAL_HEIGHT_XL = '95%';

export const MASCARA_CELULAR = '(00) 00000-0000';
export const MASCARA_TELEFONE = '(00) 0000-0000';
export const MASCARA_TELEFONE_CELULAR = '(00) 0000-00009';

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
