<h1 mat-dialog-title>Manutenção de cartório</h1>
<mat-dialog-content mat-dialog-content inner-scroll>
    <form
        id="cartorio-form"
        fxLayout="column"
        fxLayoutAlign="center stretch"
        name="form"
        [formGroup]="this.form"
    >
        <div fxFlex="100" fxLayout="column" fxLayout.gt-sm="row">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm="40">
                <mat-label>CNS</mat-label>
                <input
                    (focusout)="this.validarCns()"
                    matInput
                    type="text"
                    maxlength="14"
                    formControlName="cns"
                />
                <mat-icon matSuffix class="disabled-text">vpn_key</mat-icon>
                <mat-error
                    *ngIf="
                        form.get('cns').hasError('cnsExistente') &&
                        !form.get('cns').hasError('required')
                    "
                >
                    Este CNS já foi utilizado
                </mat-error>
                <mat-error *ngIf="form.get('cns').hasError('required')">
                    CNS obrigatório
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm>
                <mat-label>Nome do cartório</mat-label>
                <input matInput formControlName="nome" maxlength="100" />
                <mat-icon matSuffix class="secondary-text"
                    >account_balance</mat-icon
                >
                <mat-error *ngIf="form.get('nome').hasError('required')">
                    Nome é obrigatório
                </mat-error>
                <mat-error *ngIf="form.get('nome').hasError('maxlength')">
                    Tamanho máximo (100)
                </mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="column" fxFlex="100" fxLayout.gt-sm="row">
            <mat-form-field
                appearance="outline"
                fxFlex.xs="100"
                fxFlex.gt-xs="40"
            >
                <mat-label>Tipo de cartório</mat-label>
                <mat-select formControlName="tipo">
                    <mat-option [value]="this.tipoCartorio.Imoveis">
                        Cartório de imóveis
                    </mat-option>
                    <mat-option [value]="this.tipoCartorio.Notas">
                        Cartório de notas
                    </mat-option>
                    <mat-option [value]="this.tipoCartorio.Protesto">
                        Cartório de protesto
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix class="secondary-text"
                    >location_city</mat-icon
                >
                <mat-error *ngIf="form.get('tipo').hasError('required')">
                    Tipo de cartório obrigatório
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm>
                <mat-label>Endereço</mat-label>
                <input matInput formControlName="endereco" maxlength="100" />
                <mat-icon matSuffix class="secondary-text">home</mat-icon>
                <mat-error *ngIf="form.get('endereco').hasError('maxlength')">
                    Tamanho máximo (100)
                </mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="column" fxFlex="100" fxLayout.gt-sm="row">
            <mat-form-field
                appearance="outline"
                fxFlex.xs="100"
                fxFlex.gt-xs="30"
            >
                <mat-label>Telefone</mat-label>
                <input
                    matInput
                    [mask]="this.mascaraTelefone"
                    formControlName="telefone"
                />
                <mat-icon matSuffix class="secondary-text">phone</mat-icon>
                <mat-error *ngIf="form.get('telefone').hasError('required')">
                    Telefone obrigatório
                </mat-error>
            </mat-form-field>

            <mat-form-field
                appearance="outline"
                fxFlex.xs="100"
                fxFlex.gt-xs="70"
            >
                <mat-label>Nome do administrador</mat-label>
                <input
                    matInput
                    formControlName="nomeAdministrador"
                    maxlength="100"
                />
                <mat-icon matSuffix class="secondary-text"
                    >account_circle</mat-icon
                >
                <mat-error
                    *ngIf="form.get('nomeAdministrador').hasError('required')"
                >
                    Nome do administrador é obrigatório
                </mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="column" fxFlex="100" fxLayout.gt-sm="row">
            <mat-form-field
                appearance="outline"
                fxFlex.xs="100"
                fxFlex.gt-xs="50"
            >
                <mat-label>E-mail</mat-label>
                <input
                    matInput
                    formControlName="emailAdministrador"
                    type="email"
                    maxlength="100"
                />
                <mat-icon matSuffix class="secondary-text">email</mat-icon>
                <mat-error
                    *ngIf="form.get('emailAdministrador').hasError('required')"
                >
                    Email do administrador é obrigatório
                </mat-error>
                <mat-error
                    *ngIf="form.get('emailAdministrador').hasError('email')"
                >
                    E-mail inválido
                </mat-error>
            </mat-form-field>

            <mat-form-field
                appearance="outline"
                fxFlex.xs="100"
                fxFlex.gt-xs="50"
            >
                <mat-label>Senha do administrador</mat-label>
                <input
                    matInput
                    formControlName="senhaAdministrador"
                    type="password"
                    maxlength="50"
                />
                <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                <mat-error
                    *ngIf="form.get('senhaAdministrador').hasError('required')"
                >
                    Senha do administrador é obrigatória
                </mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="column" fxFlex="100" fxLayout.gt-sm="row">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-sm>
                <mat-label>Parametro para consulta de andamentos</mat-label>
                <input
                    matInput
                    formControlName="parametroConsultaAndamento"
                    maxlength="100"
                />
                <mat-icon matSuffix class="secondary-text"
                    >insert_link</mat-icon
                >
                <mat-error
                    *ngIf="
                        form
                            .get('parametroConsultaAndamento')
                            .hasError('required')
                    "
                >
                    Parâmetro é obrigatório
                </mat-error>
                <mat-error
                    *ngIf="
                        form
                            .get('parametroConsultaAndamento')
                            .hasError('maxlength')
                    "
                >
                    Tamanho máximo (50)
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end end"
    class="text-right actions-manutencao-cartorio"
>
    <button
        mat-stroked-button
        color="accent"
        mat-dialog-close
        (click)="this.eventoFechar()"
    >
        Fechar
    </button>
    <button
        mat-raised-button
        color="accent"
        class="ml-8"
        (click)="this.gravarDados()"
        [disabled]="this.form.invalid"
    >
        Salvar
    </button>
</mat-dialog-actions>
