import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { mascaraTelefone } from 'app/main/_shared/funcoes';
import { ToastService } from 'app/main/_shared/servicos';
import { CartorioControllerService } from 'app/main/_shared/servicos/controllers';

@Component({
    selector: 'enviar-whatsapp',
    templateUrl: './enviar-whatsapp.component.html',
    styleUrls: ['./enviar-whatsapp.component.scss'],
})
export class EnviarWhatsAppComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    public mascaraTelefone: string;
    private _urlSala: string;

    constructor(
        private _dialogRef: MatDialogRef<EnviarWhatsAppComponent>,
        public fuseProgressBarService: FuseProgressBarService,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA)
        private dados: any
    ) {
        this._urlSala = dados;
    }

    ngOnInit(): void {
        this.construirForm();
    }

    ngOnDestroy(): void {}

    private construirForm(): void {
        this.form = this._formBuilder.group({
            telefone: ['', [Validators.required]],
        });

        this.form.get('telefone').valueChanges.subscribe((val) => {
            this.mascaraTelefone = mascaraTelefone(val);
        });
    }

    public enviarMensagemPeloWhatsapp(): void {
        const telefone = this.form.get('telefone').value;

        const mensagem = `Acesse este link para receber o atendimento online do seu cartório ${this._urlSala}`;

        window.open(
            `https://api.whatsapp.com/send?phone=+55${telefone}&text=${mensagem}`
        );

        this._dialogRef.close();
    }
}
