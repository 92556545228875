import { configuracaoPadraoEditor } from 'app/main/_shared/funcoes/configuracoes-editor';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CartorioControllerService } from 'app/main/_shared/servicos/controllers';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastService } from 'app/main/_shared/servicos';
import { ConfigService } from 'app/config/config.service';

@Component({
    selector: 'orientacoes-lgpd',
    templateUrl: './orientacoes-lgpd.component.html',
    styleUrls: ['./orientacoes-lgpd.component.scss'],
})
export class OrientacoesLgpdComponent implements AfterViewInit {
    public configuracoesEditor = configuracaoPadraoEditor('500', this._configService.config.urlDominio);
    public carregandoEditor: boolean;
    public orientacoesLgpd: string;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public dados: {
            cns: string;
            orientacoesLgpd: string;
        },
        private _dialogRef: MatDialogRef<OrientacoesLgpdComponent>,
        private _cartorioController: CartorioControllerService,
        private fuseProgressBarService: FuseProgressBarService,
        private _toastService: ToastService,
        private _configService: ConfigService
    ) {
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.orientacoesLgpd = this.dados.orientacoesLgpd;
            this.carregandoEditor = true;
        });
    }

    onInitEditor(): void {
        setTimeout(() => {
            this.carregandoEditor = false;
        });
    }

    gravarDados(): void {
        this.fuseProgressBarService.show();
        this._cartorioController
            .alterarOrientacoesLgpd(this.dados.cns, this.orientacoesLgpd)
            .subscribe(
                () => {
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemSuccesso(
                        'Dados gravados com sucesso'
                    );
                    this.fecharTela();
                },
                (erro) => {
                    console.log(erro);
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemErro(
                        'Ocorreu um erro ao realizar a alteração dos dados do cartório'
                    );
                }
            );
    }

    fecharTela(): void {
        this._dialogRef.close(this.orientacoesLgpd);
    }
}
