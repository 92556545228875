import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TipoServicoAtendimento } from 'app/main/_shared/models/cartorio/tipo-servico-atendimento.model';
import { MatPaginator } from '@angular/material/paginator';
import { retornarDescricaoTipoAtendimento } from 'app/main/_shared/funcoes';
import { TipoServicoControllerService } from 'app/main/_shared/servicos/controllers/tipo-servico-controller.service';
import {
    UsuarioControllerService
} from 'app/main/_shared/servicos/controllers';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UsuarioCartorio } from 'app/main/_shared/models/cartorio';
import { forkJoin } from 'rxjs';
import { ToastService, AuthenticationService } from 'app/main/_shared/servicos';

@Component({
    selector: 'tipos-servico-usuario',
    templateUrl: './tipos-servico-usuario.component.html',
    styleUrls: ['./tipos-servico-usuario.component.scss'],
})
export class TiposServicoUsuarioComponent implements OnInit {
    public displayedColumnsTiposServico: string[] = [
        'descricao',
        'tipoAtendimento',
        'buttons',
    ];
    public dataSourceTiposServico: MatTableDataSource<
        TipoServicoAtendimento
    > = new MatTableDataSource<TipoServicoAtendimento>([]);

    @ViewChild(MatPaginator, { static: true })
    paginatorTiposServico: MatPaginator;
    private _tiposServicoDisponiveis: Array<TipoServicoAtendimento> = [];
    private _usuario: UsuarioCartorio;
    private _cns: string;
    public tipoServicoSelecionado: TipoServicoAtendimento;
    public tiposServicoDoUsuario: Array<TipoServicoAtendimento> = [];
    private _alterou: boolean;
    public semTiposServicoNoInicio = false;

    public get tiposServicoDisponiveis(): Array<TipoServicoAtendimento> {
        return this._tiposServicoDisponiveis.filter(
            (tiposervico) =>
                this.tiposServicoDoUsuario.filter((servico) => {
                    return servico.id === tiposervico.id;
                }).length === 0
        );
    }

    constructor(
        authenticationService: AuthenticationService,
        private _usuarioControllerService: UsuarioControllerService,
        private _tipoServicoControllerService: TipoServicoControllerService,
        private _authenticationService: AuthenticationService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _dialogRef: MatDialogRef<TiposServicoUsuarioComponent>,
        private _toastService: ToastService,
        @Inject(MAT_DIALOG_DATA)
        private _dados: any
    ) {
        this._usuario = _dados.usuario;
        this._cns = authenticationService.usuarioAtual.cns;
    }

    public ngOnInit(): void {
        this._alterou = false;
        this._fuseProgressBarService.show();
        forkJoin({
            tiposServicoDisponiveis: this._tipoServicoControllerService.listarTiposServicoAtendimento(
                this._cns,
                this._usuario.tipoAtendimento
            ),
            tiposServicoDoUsuario: this._usuarioControllerService.listarTiposServicoDoUsuario(
                this._usuario.id
            ),
        }).subscribe(
            ({ tiposServicoDisponiveis, tiposServicoDoUsuario }) => {
                this._tiposServicoDisponiveis = tiposServicoDisponiveis;
                if (this._tiposServicoDisponiveis.length === 0) {
                    this.semTiposServicoNoInicio = true;
                }

                this.tiposServicoDoUsuario = tiposServicoDoUsuario;

                this.dataSourceTiposServico.data = tiposServicoDoUsuario;
                this.dataSourceTiposServico.paginator = this.paginatorTiposServico;
                this._fuseProgressBarService.hide();
            },
            (erro) => {
                console.log(erro);
                this._toastService.mensagemErro(
                    'Ocorreu um erro ao carregar os dados'
                );
                this._fuseProgressBarService.hide();
            }
        );
    }

    public retornarDescricaoTipoAtendimento(
        tipoServico: TipoServicoAtendimento
    ): string {
        return retornarDescricaoTipoAtendimento(tipoServico.tipoAtendimento);
    }

    public excluirTipoServicoAtendimento(
        tipoServico: TipoServicoAtendimento
    ): void {
        if (this.tiposServicoDoUsuario.length === 0) {
            return;
        }
        const index = this.tiposServicoDoUsuario.findIndex(
            (ts) => ts.id === tipoServico.id
        );

        this.tiposServicoDoUsuario.splice(index, 1);
        this.dataSourceTiposServico.data = this.tiposServicoDoUsuario;

        this._alterou = true;
    }

    public adicionarTipoServico(): void {
        if (!this.tipoServicoSelecionado) {
            return;
        }

        this.tiposServicoDoUsuario.push(this.tipoServicoSelecionado);
        this.tipoServicoSelecionado = undefined;
        this.dataSourceTiposServico.data = this.tiposServicoDoUsuario;
        this._alterou = true;
    }

    public gravarDados(): void {
        if (!this._alterou) {
            this._dialogRef.close();
            return;
        }

        this._fuseProgressBarService.show();
        const idsTiposServicoUsuario = this.tiposServicoDoUsuario.map(
            (tiposervico) => tiposervico.id
        );
        this._usuarioControllerService
            .gravarTiposServicoUsuario(
                this._authenticationService.usuarioAtual.cns,
                this._usuario.id,
                idsTiposServicoUsuario
            )
            .subscribe(
                () => {
                    this._fuseProgressBarService.hide();
                    this._toastService.mensagemSuccesso(
                        'Dados armazenados com sucesso'
                    );
                    this._dialogRef.close();
                },
                (erro) => {
                    this._fuseProgressBarService.hide();
                    console.log(erro);
                    this._toastService.mensagemErro(
                        'Ocorreu um problema ao gravar os dados de tipos de serviço do usuário.'
                    );
                }
            );
    }
}
