import { Component, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'video-conferencia',
    templateUrl: './video-conferencia.component.html',
    styleUrls: ['./video-conferencia.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class VideoConferenciaComponent {
    public conferenciaEmAndamento = false;

    public iniciarVideoConferencia(): void {
        this.conferenciaEmAndamento = true;
    }

    public fecharVideoConferencia(): void {
        this.conferenciaEmAndamento = false;
    }
}
