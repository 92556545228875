import { BaseControllerService } from './base-controller.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '..';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from 'app/config/config.service';

@Injectable({
    providedIn: 'root',
})
export class AutenticacaoControllerService extends BaseControllerService {
    constructor(
        _http: HttpClient,
        _authenticationService: AuthenticationService,
        configService: ConfigService
    ) {
        super(_http, _authenticationService, configService);
    }

    alterarSenha(senhaAtual: string, senhaNova: string): Observable<void> {
        const alterarSenhaDTO = {
            senhaAtual: btoa(senhaAtual),
            novaSenha: btoa(senhaNova),
            tipoLogin: this._authenticationService.usuarioAtual.tipoLogin,
            cns: this._authenticationService.usuarioAtual.cns,
            idUsuario: this._authenticationService.usuarioAtual.id,
        };

        return this.post(`autenticacao/alterarSenha`, alterarSenhaDTO);
    }
}
