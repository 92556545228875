<div class="page-layout carded fullwidth inner-scroll">
    <div class="top-bg accent"></div>
    <div class="center">
        <div class="header accent p-24">
            <h2 class="m-0">Atendimento ao usuário</h2>
        </div>
        <div class="content-card">
            <h3 class="text-center label-filtros">Filtros:</h3>
            <div
                class="toolbar"
                fxFlex="1 0 auto"
                fxLayout="row"
                fxLayoutAlign="center center"
                class="conteudo-filtros"
            >
                <div fxLayout="column" fxFlex="100">
                    <div fxLayout="row">
                        <!--#region Filtro Data -->
                        <mat-form-field fxFlex="15" appearance="outline">
                            <mat-label>De</mat-label>
                            <input
                                matInput
                                disabled
                                [matDatepicker]="picker"
                                [(ngModel)]="this.dataInicio"
                                [max]="this.dataFim"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="picker"
                                disabled="false"
                            ></mat-datepicker-toggle>
                            <mat-datepicker
                                touchUi
                                #picker
                                disabled="false"
                            ></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field fxFlex="15" appearance="outline">
                            <mat-label>Para</mat-label>
                            <input
                                matInput
                                disabled
                                [matDatepicker]="picker2"
                                [(ngModel)]="this.dataFim"
                                [min]="this.dataInicio"
                            />
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="picker2"
                                disabled="false"
                            ></mat-datepicker-toggle>
                            <mat-datepicker
                                touchUi
                                #picker2
                                disabled="false"
                            ></mat-datepicker>
                        </mat-form-field>

                        <!--#endregion Filtro Data -->

                        <!--#region Tipo de atendimento -->
                        <mat-form-field appearance="outline" fxFlex="20">
                            <mat-label>Tipo de atendimento</mat-label>
                            <mat-select
                                [(ngModel)]="this.tipoAtendimentoSelecionado"
                            >
                                <mat-option
                                    [value]="
                                        this.tipoAtendimentoUsuario
                                            .VirtualEPresencial
                                    "
                                >
                                    Virtual e presencial
                                </mat-option>

                                <mat-option
                                    [value]="
                                        this.tipoAtendimentoUsuario
                                            .ApenasVirtual
                                    "
                                >
                                    Virtual
                                </mat-option>

                                <mat-option
                                    [value]="
                                        this.tipoAtendimentoUsuario
                                            .ApenasPresencial
                                    "
                                >
                                    Presencial
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!--#endregion Tipo de atendimento -->

                        <!--#region Status do atendimento-->
                        <mat-form-field appearance="outline" fxFlex="25">
                            <mat-label>Status do atendimento</mat-label>
                            <mat-select [(ngModel)]="this.statusSelecionado">
                                <mat-option
                                    [value]="
                                        this.statusAtendimentoUsuario.Pendente
                                    "
                                    >Pendente</mat-option
                                >
                                <mat-option
                                    [value]="
                                        this.statusAtendimentoUsuario
                                            .AguardandoResposta
                                    "
                                    >Aguardando resposta</mat-option
                                >
                                <mat-option
                                    [value]="
                                        this.statusAtendimentoUsuario.Atendido
                                    "
                                    >Atendido</mat-option
                                >
                                <mat-option
                                    [value]="
                                        this.statusAtendimentoUsuario
                                            .AtendimentoCancelado
                                    "
                                    >Cancelado</mat-option
                                >
                                <mat-option
                                    [value]="
                                        this.statusAtendimentoUsuario.Todos
                                    "
                                    >Todos</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                        <!--#endregion Status do atendimento-->

                        <!--#region Tipo de serviço solicitado-->
                        <mat-form-field appearance="outline" fxFlex>
                            <mat-label>Tipo de serviço solicitado</mat-label>
                            <mat-select
                                [(ngModel)]="this.idTipoServicoSelecionado"
                            >
                                <mat-option [value]="'*'"> Todos </mat-option>

                                <mat-option
                                    *ngFor="
                                        let tipoServico of this.tiposServico
                                    "
                                    [value]="tipoServico.id"
                                >
                                    {{ tipoServico.descricao }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!--#endregion Tipo de serviço solicitado-->
                    </div>

                    <span
                        class="text-center ou-filtros"
                        fxFlex="100"
                        fxFlexAlign="center center"
                    >
                        OU
                    </span>

                    <div fxLayout="row">
                        <mat-form-field
                            appearance="outline"
                            fxFlex="20"
                            class="negrito"
                        >
                            <mat-label>Protocolo</mat-label>
                            <input
                                matInput
                                type="text"
                                (ngModelChange)="this.changeProtocolo()"
                                [(ngModel)]="this.protocolo"
                            />
                        </mat-form-field>

                        <mat-form-field
                            appearance="outline"
                            fxFlex="15"
                            class="negrito"
                        >
                            <mat-label>CPF</mat-label>
                            <input
                                matInput
                                type="text"
                                mask="000.000.000-00"
                                [dropSpecialCharacters]="false"
                                (ngModelChange)="this.changeCpf()"
                                [(ngModel)]="this.cpfRequirente"
                            />
                        </mat-form-field>

                        <mat-form-field
                            appearance="outline"
                            fxFlex="40"
                            class="negrito"
                        >
                            <mat-label>Nome do requirente</mat-label>
                            <input
                                matInput
                                type="text"
                                (ngModelChange)="this.changeNomeRequirente()"
                                [(ngModel)]="this.nomeRequirente"
                            />
                        </mat-form-field>

                        <mat-form-field
                            appearance="outline"
                            fxFlex="15"
                            class="negrito"
                        >
                            <mat-label>Telefone</mat-label>
                            <input
                                matInput
                                type="text"
                                [mask]="this.mascaraTelefone"
                                [dropSpecialCharacters]="false"
                                (ngModelChange)="
                                    this.changeMascaraTelefone($event)
                                "
                                [(ngModel)]="this.telefone"
                            />
                        </mat-form-field>

                        <button
                            mat-stroked-button
                            aria-label="Pesquisar agendamentos"
                            matTooltip="Pesquisar agendamentos"
                            color="accent"
                            fxFlex
                            class="botao-filtro ml-4 mr-4"
                            (click)="this.pesquisar()"
                        >
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div
                class="content widgets-atendimentos"
                fxLayout="row wrap"
                fxLayoutAlign="start start"
                fxFlex="100"
            >
                <div
                    class="widget-group p-12 container-widgets"
                    fxLayout="row wrap"
                    fxFlex="100"
                >
                    <fuse-widget
                        class="widget"
                        fxLayout="column"
                        fxFlex="100"
                        fxFlex.gt-xs="50"
                        fxFlex.gt-md="25"
                        *ngFor="let agendamento of this.agendamentos"
                    >
                        <div class="fuse-widget-front">
                            <div
                                class="pl-16 pr-8 py-16 h-40"
                                fxLayout="row"
                                fxLayoutAlign="space-between center"
                            >
                                <div class="h3">
                                    {{
                                        agendamento.dataAgendamento
                                            | date: 'dd/MM/yyyy'
                                    }}
                                    - {{ agendamento.horaAgendamento }}
                                </div>

                                <button
                                    mat-icon-button
                                    class="fuse-widget-flip-button"
                                    aria-label="Realizar atendimento"
                                    matTooltip="Realizar atendimento"
                                    (click)="
                                        this.realizarAtendimento(agendamento)
                                    "
                                >
                                    <mat-icon>playlist_add_check</mat-icon>
                                </button>
                            </div>

                            <div
                                class="pt-8 pb-8"
                                fxLayout="column"
                                fxLayoutAlign="center center"
                            >
                                <div>
                                    {{
                                        this.descricaoTipoServico(
                                            agendamento.idTipoServico
                                        )
                                    }}
                                </div>
                                <div class="secondary-text font-weight-500">
                                    Status:
                                    <span
                                        [class]="
                                            this.classeStatusAtendimento(
                                                agendamento.status
                                            )
                                        "
                                    >
                                        <span
                                            *ngIf="
                                                agendamento.horarioLiberado ===
                                                false || agendamento.status == this.statusAtendimentoUsuario.AtendimentoCancelado
                                            "
                                            >{{
                                                this.descricaoStatusAtendimento(
                                                    agendamento
                                                )
                                            }}
                                        </span>
                                        
                                        <span
                                            *ngIf="
                                                agendamento.horarioLiberado ===
                                                true && agendamento.status != this.statusAtendimentoUsuario.AtendimentoCancelado
                                            "
                                        >
                                            Horário liberado
                                        </span>
                                    </span>
                                </div>
                                <div>
                                    Nome: {{ agendamento.nomeRequirente }}
                                </div>
                                <div>CPF: {{ agendamento.cpf }}</div>
                                <div>Tel.: {{ agendamento.telefone }}</div>
                            </div>

                            <div
                                class="p-8 border-top"
                                fxLayout="row"
                                fxLayoutAlign="start center"
                            >
                                <span class="secondary-text text-truncate">
                                    Atendimento:
                                </span>
                                <span class="ml-8">
                                    {{
                                        this.descricaoTipoAtendimento(
                                            agendamento.tipoAtendimento
                                        )
                                    }}</span
                                >
                            </div>
                        </div>
                    </fuse-widget>
                </div>
            </div>
        </div>
    </div>
</div>
