import {
    Component,
    Inject,
    ViewEncapsulation,
    AfterViewInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from 'app/config/config.service';
import { configuracaoEditorReadOnly } from 'app/main/_shared/funcoes/configuracoes-editor';

@Component({
    selector: 'dialog-orientacoes-lgpd',
    templateUrl: './dialog-orientacoes-lgpd.component.html',
    styleUrls: ['./dialog-orientacoes-lgpd.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DialogOrientacoesLgpdComponent implements AfterViewInit {
    public configuracoesEditor = configuracaoEditorReadOnly('500', this._configService.config.urlDominio);
    public mensagemHTML = '';
    public carregandoEditor: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public dados: {
            orientacoes: string;
        },
        private _configService: ConfigService
    ) {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.configuracoesEditor = configuracaoEditorReadOnly('500', this._configService.config.urlDominio);
            this.carregandoEditor = true;
            this.mensagemHTML = this.dados.orientacoes
        });
    }

    onInitEditor(): void {
        setTimeout(() => {
            this.carregandoEditor = false;
        });
    }
}
