import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOC_ORIENTATION } from '../models/_enums';
import { ImageCompress } from '../funcoes/image-compress';

@Injectable({ providedIn: 'root' })
export class FileImageService {
    private render: Renderer2;

    public DOC_ORIENTATION = DOC_ORIENTATION;

    constructor(rendererFactory: RendererFactory2) {
        this.render = rendererFactory.createRenderer(null, null);
    }

    public byteCount(image: string): number {
        return ImageCompress.byteCount(image);
    }

    public compressFile(
        image: string,
        orientation: DOC_ORIENTATION,
        ratio: number = 50,
        quality: number = 50
    ): Promise<string> {
        return ImageCompress.compress(
            image,
            orientation,
            this.render,
            ratio,
            quality
        );
    }

    public uploadFile(
        tipo: 'img' | 'file'
    ): Promise<{
        image: string;
        fileName: string;
        orientation: DOC_ORIENTATION;
    }> {
        return ImageCompress.uploadFile(this.render, tipo);
    }

    public dataURItoBlob(dataURI: string, fileName: string): File {
        let byteString: string;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }

        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new File([ia], fileName, { type: mimeString });
    }
}
