import { BaseControllerService } from './base-controller.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '..';
import { Observable } from 'rxjs';

import { TipoServicoAtendimento } from '../../models/cartorio/tipo-servico-atendimento.model';
import { PerguntaCartorio } from '../../models/cartorio';
import { ConfigService } from 'app/config/config.service';

@Injectable({
    providedIn: 'root',
})
export class PerguntaCartorioControllerService extends BaseControllerService {
    constructor(
        _http: HttpClient,
        _authenticationService: AuthenticationService,
        configService: ConfigService
    ) {
        super(_http, _authenticationService, configService);
    }

    public listarPerguntasTipoServico(
        cns: string,
        idTipoServico: string
    ): Observable<Array<PerguntaCartorio>> {
        return this.get(`perguntacartorio/${cns}/${idTipoServico}`);
    }

    public retornarPerguntaTipoServicoPorIndice(
        cns: string,
        idTipoServico: string,
        indice: number
    ): Observable<PerguntaCartorio> {
        return this.get(`perguntacartorio/${cns}/${idTipoServico}/${indice}`);
    }

    public retornarQuantidadePerguntas(
        cns: string,
        idTipoServico: string
    ): Observable<number> {
        return this.get<number>(
            `perguntacartorio/${cns}/${idTipoServico}/quantidadePerguntas`
        );
    }

    public gravarPerguntasTipoServico(
        cns: string,
        vincularUsuariosATipoServico: boolean,
        tipoServico: TipoServicoAtendimento,
        perguntas: Array<PerguntaCartorio>
    ): Observable<void> {
        const dadosGravacao = {
            vincularUsuariosATipoServico,
            tipoServico,
            perguntas,
        };

        return this.post(
            `perguntacartorio/gravarPerguntasTipoServico/${cns}`,
            dadosGravacao
        );
    }
}
