<div
    class="page-layout carded fullwidth inner-scroll"
    id="configuracoes-cartsys"
>
    <div class="top-bg accent"></div>
    <div class="center">
        <div class="header accent p-24">
            <h2 class="m-0">Gestão cartsys</h2>
        </div>
        <div class="content-card">
            <div
                class="toolbar px-24 py-8"
                fxFlex="1 0 auto"
                fxLayout="row"
                fxLayoutAlign="space-between center"
            >
                <div *ngIf="!this.pesquisaEmAndamento">
                    Cartórios cadastrados
                </div>
                <input
                    id="search-bar-input"
                    placeholder="Pesquisar"
                    class="ml-8 pesquisa-cartorios-input"
                    (input)="this.pesquisando($event)"
                    fxFlex
                    *ngIf="this.pesquisaEmAndamento"
                />
                <label for="search-bar-input" *ngIf="this.pesquisaEmAndamento">
                    <button
                        mat-icon-button
                        aria-label="Expand Search Bar"
                        (click)="this.habilitarBusca()"
                    >
                        <mat-icon class="s-24 secondary-text">close</mat-icon>
                    </button>
                </label>
                <button
                    mat-icon-button
                    (click)="this.habilitarBusca()"
                    aria-label="Collapse Search Bar"
                    *ngIf="!this.pesquisaEmAndamento"
                >
                    <mat-icon class="s-24 secondary-text">search</mat-icon>
                </button>
            </div>
            <div
                class="content"
                fxLayout="row wrap"
                fxLayoutAlign="start start"
            >
                <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100">
                    <fuse-widget
                        class="widget"
                        fxLayout="column"
                        fxFlex="100"
                        fxFlex.gt-xs="50"
                        fxFlex.gt-md="25"
                        *ngFor="
                            let cartorio of this.cartorios
                                | cartorio: this.pesquisa
                        "
                    >
                        <div class="fuse-widget-front">
                            <div
                                class="pl-16 pr-8 py-16 h-40"
                                fxLayout="row"
                                fxLayoutAlign="space-between center"
                            >
                                <div class="cns-widget">
                                    {{ cartorio.cns }}
                                </div>

                                <div>
                                    <button
                                        mat-icon-button
                                        class="fuse-widget-flip-button botao-widget"
                                        matTooltip="Deletar cartório"
                                        aria-label="deletar"
                                        (click)="this.deletarCartorio(cartorio)"
                                    >
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                    <button
                                        mat-icon-button
                                        class="fuse-widget-flip-button botao-widget"
                                        aria-label="Editar"
                                        (click)="this.editarCartorio(cartorio)"
                                    >
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div
                                class="pt-8 pb-32"
                                fxLayout="column"
                                fxLayoutAlign="center center"
                            >
                                <div class="text-center">
                                    {{ cartorio.nome }}
                                </div>
                                <div class="h4 secondary-text font-weight-500">
                                    {{
                                        this.retornarDescricaoTipoCartorio(
                                            cartorio.tipo
                                        )
                                    }}
                                </div>
                            </div>

                            <div
                                class="p-16 border-top"
                                fxLayout="row"
                                fxLayoutAlign="start center"
                            >
                                <span class="h4 secondary-text text-truncate">
                                    Responsável:
                                </span>
                                <span class="h4 ml-8">{{
                                    cartorio.nomeAdministrador
                                }}</span>
                            </div>
                        </div>
                    </fuse-widget>
                </div>

                <footer fxLayoutAlign="end center" fxFlex="100">
                    <div class="footer-fab mr-12" fxLayout="column">
                        <button
                            mat-fab
                            color="accent"
                            class="mb-12"
                            matTooltip="Alterar configurações gerais"
                            aria-label="Alterar configurações gerais"
                            (click)="this.alterarConfiguracoesGerais()"
                        >
                            <mat-icon>settings</mat-icon>
                        </button>

                        <button
                            mat-fab
                            color="accent"
                            matTooltip="Adicionar cartório"
                            aria-label="Adicionar cartório"
                            (click)="this.adicionarCartorio()"
                        >
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</div>
