import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { DadosCartorio } from 'app/main/_shared/models';
import { mascaraTelefone } from 'app/main/_shared/funcoes';
import { ToastService } from 'app/main/_shared/servicos';
import { CartorioControllerService } from 'app/main/_shared/servicos/controllers';
import { TipoCartorio } from 'app/main/_shared/models/_enums';
import { DialogFormBase } from 'app/main/_shared/contratos';
import { DialogService } from 'app/main/_shared/servicos/dialogs';

@Component({
    selector: 'manutencao-cartorio-cartsys',
    templateUrl: './manutencao-cartorio.component.html',
    styleUrls: ['./manutencao-cartorio.component.scss'],
})
export class ManutencaoCartorioComponent extends DialogFormBase<
    ManutencaoCartorioComponent
> {
    public form: FormGroup;
    public mascaraTelefone: string;
    private _cartorio: DadosCartorio;
    public inserir: boolean;

    constructor(
        public fuseProgressBarService: FuseProgressBarService,
        private _cartorioController: CartorioControllerService,
        private _toastService: ToastService,
        @Inject(MAT_DIALOG_DATA)
        private dados: any,
        formBuilder: FormBuilder,
        dialogService: DialogService,
        dialogRef: MatDialogRef<ManutencaoCartorioComponent>
    ) {
        super(formBuilder, dialogService, dialogRef);
    }

    public carregarDados(): void {
        this.inserir = !this.dados.cartorio;

        if (!!this.dados.cartorio) {
            this._cartorio = this.dados.cartorio;
        } else {
            this._cartorio = {
                cns: '',
                emailAdministrador: '',
                nome: '',
                nomeAdministrador: '',
                senhaAdministrador: '',
                telefone: '',
                tipo: TipoCartorio.Imoveis,
                endereco: '',
                mensagemFinalAtendimento:
                    'Agradecemos o seu contato. Qualquer dúvida, entre em contato conosco',
                minutosDuracaoPadraoAtendimento: 30,
                nomeAtendenteVirtual: 'Atendente virtual',
                parametroConsultaAndamento: '',
                telefoneSuporteWhatsApp: '',
                orientacoesLGPD: ''
            };
        }

        this.form.patchValue(this._cartorio);
    }

    public construirForm(formBuilder: FormBuilder): void {
        this.form = formBuilder.group({
            cns: ['', [Validators.maxLength(14)]],
            nome: ['', [Validators.required, Validators.maxLength(100)]],
            tipo: [TipoCartorio.Imoveis, [Validators.required]],
            telefone: ['', [Validators.required]],
            endereco: ['', [Validators.maxLength(100)]],
            nomeAdministrador: [
                '',
                [Validators.required, Validators.maxLength(100)],
            ],
            emailAdministrador: [
                '',
                [
                    Validators.required,
                    Validators.email,
                    Validators.maxLength(100),
                ],
            ],
            senhaAdministrador: [
                '',
                [Validators.required, Validators.maxLength(50)],
            ],
            parametroConsultaAndamento: [
                '',
                [Validators.required, Validators.maxLength(50)],
            ],
        });

        this.form.get('telefone').valueChanges.subscribe((val) => {
            this.mascaraTelefone = mascaraTelefone(val);
        });
    }

    processamentoOnAfterViewInit(): void {
        setTimeout(() => {
            if (this.inserir) {
                this.form.get('cns').enable();
                this.form
                    .get('senhaAdministrador')
                    .setValidators([Validators.required]);
            } else {
                this.form.get('cns').disable();
                this.form.get('senhaAdministrador').clearValidators();
            }
        });
    }

    public get tipoCartorio(): typeof TipoCartorio {
        return TipoCartorio;
    }

    public validarCns(): void {
        if (!this.inserir) {
            return;
        }

        if (this.form.get('cns').value) {
            this.fuseProgressBarService.show();
            this._cartorioController
                .validarCnsCartorioExistente(this.form.get('cns').value)
                .subscribe(
                    (cnsExistente: boolean) => {
                        if (cnsExistente) {
                            this.form.get('cns').setErrors({
                                cnsExistente: true,
                            });
                        }
                        this.fuseProgressBarService.hide();
                    },
                    (err) => {
                        this._toastService.mensagemErro(
                            'Ocorreu um erro ao realizar a validação do CNS'
                        );
                        console.log(err);
                        this.fuseProgressBarService.hide();
                    }
                );
        }
    }

    public eventoFechar(): void {
        this._dialogRef.close();
    }

    public gravarDados(): void {
        this.fuseProgressBarService.show();

        this._cartorio = { ...this.form.value };

        if (!this.inserir) {
            this._cartorio.cns = this.form.get('cns').value;
        }

        this._cartorio.senhaAdministrador = btoa(
            this._cartorio.senhaAdministrador
        );

        const observableGravacao = this.inserir
            ? this._cartorioController.inserirCartorio(this._cartorio)
            : this._cartorioController.alterarCartorio(this._cartorio);

        observableGravacao.subscribe(
            () => {
                this.fuseProgressBarService.hide();
                this._toastService.mensagemSuccesso(
                    'Dados gravados com sucesso'
                );
                this._dialogRef.close();
            },
            (erro) => {
                console.log(erro);
                this.fuseProgressBarService.hide();
                this._toastService.mensagemErro(
                    'Ocorreu um erro ao realizar a gravação dos dados do cartório'
                );
            }
        );
    }
}
