import { StatusAtendimentoUsuario } from '../_enums';
import { EMPTY_GUID } from '../../constantes';

export class GravacaoAgendamentoAtendimentoDTO {
    id: string;
    status: StatusAtendimentoUsuario;
    idUsuario: string;
    observacaoAtendimento: string;
    liberarHorario: boolean;

    constructor(
        id: string,
        status: StatusAtendimentoUsuario,
        idUsuario: string,
        observacaoAtendimento: string
    ) {
        this.id = id;
        this.status = status;
        this.idUsuario = !idUsuario ? EMPTY_GUID : idUsuario;
        this.observacaoAtendimento = observacaoAtendimento;
    }
}
