import {
    Component,
    OnInit,
    Inject,
    AfterViewInit,
    Pipe,
    PipeTransform,
} from '@angular/core';
import {
    FormGroup,
    FormArray,
    FormBuilder,
    Validators,
    FormControl,
    AbstractControl,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExpedienteCartorio } from 'app/main/_shared/models/cartorio';
import { CartorioControllerService } from 'app/main/_shared/servicos/controllers';
import {
    ToastService,
    ValidacaoExpedienteCartorioService,
} from 'app/main/_shared/servicos';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { DiaSemana } from 'app/main/_shared/models/_enums';
import { DialogService } from 'app/main/_shared/servicos/dialogs';
import { retornarDescricaoDiaSemana } from 'app/main/_shared/funcoes';
import { DialogFormBase } from 'app/main/_shared/contratos';

@Component({
    selector: 'expediente-cartorio',
    templateUrl: './expediente-cartorio.component.html',
    styleUrls: ['./expediente-cartorio.component.scss'],
})
export class ExpedienteCartorioComponent extends DialogFormBase<
    ExpedienteCartorioComponent
> {
    private _cns: string;
    private _diaSelecionado: DiaSemana;
    public diasExpediente: Array<ExpedienteCartorio> = [];
    public form: FormGroup;
    public carregando = false;

    get expedienteForm(): FormArray {
        return this.form.get('itensExpediente') as FormArray;
    }

    get diasDisponiveis(): Array<DiaSemana> {
        const retorno: Array<DiaSemana> = [];

        // tslint:disable-next-line: forin
        for (const diaString in DiaSemana) {
            const diaNumber = Number(diaString);

            if (isNaN(diaNumber)) {
                continue;
            }

            const existentes = this.diasNoForm.filter(
                (dia) => dia === diaNumber
            );

            if (!existentes || existentes.length === 0) {
                const diaConvertido = diaNumber as DiaSemana;

                retorno.push(diaConvertido);
            }
        }

        return retorno;
    }

    get diasNoForm(): Array<number> {
        return this.expedienteForm.value.map((item) => Number(item.dia));
    }

    get controlesOrdenados(): Array<AbstractControl> {
        return this.expedienteForm?.controls.sort((a, b) => {
            const aStr = Number(a.get('dia').value);
            const bStr = Number(b.get('dia').value);
            return bStr > aStr ? -1 : 1;
        });
    }

    constructor(
        private _cartorioControllerService: CartorioControllerService,
        private _toastService: ToastService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _validacaoExpedienteService: ValidacaoExpedienteCartorioService,
        dialogRef: MatDialogRef<ExpedienteCartorioComponent>,
        formBuilder: FormBuilder,
        dialogService: DialogService,
        @Inject(MAT_DIALOG_DATA)
        dados: { cns: string }
    ) {
        super(formBuilder, dialogService, dialogRef);
        this._cns = dados.cns;
    }

    construirForm(formBuilder: FormBuilder): void {
        this.form = formBuilder.group({
            itensExpediente: formBuilder.array([]),
        });
    }

    retornarDescricaoDiaSemana(dia: DiaSemana): string {
        return retornarDescricaoDiaSemana(dia);
    }

    carregarDados(): void {
        this.carregando = true;
        setTimeout(() => {
            this._fuseProgressBarService.show();
            this._cartorioControllerService
                .listarConfiguracoesExpediente(this._cns)
                .subscribe(
                    (diasExpediente) => {
                        this.diasExpediente = diasExpediente;
                        for (const diaExp of diasExpediente) {
                            const formGroup = this.criarItemForm();
                            formGroup.patchValue(diaExp);
                            this.expedienteForm.push(formGroup);
                        }
                        this.form.valueChanges.subscribe(() => {
                            this._houveAlteracao = true;
                        });
                        this._fuseProgressBarService.hide();
                        this.carregando = false;
                    },
                    (erro) => {
                        this.carregando = false;
                        this._toastService.mensagemErro(
                            'Ocorreu um erro ao realizar a validação do CNS'
                        );
                        console.log(erro);
                        this._fuseProgressBarService.hide();
                    }
                );
        });
    }

    criarItemForm(): FormGroup {
        return this._formBuilder.group({
            dia: ['', Validators.required],
            horaInicioExpediente: ['', Validators.required],
            horaFimExpediente: ['', Validators.required],
        });
    }

    adicionarDiaNoExpediente(dia: DiaSemana): void {
        const formGroup = this.criarItemForm();
        formGroup.patchValue({ dia });
        Object.keys(formGroup.controls).forEach((key) => {
            formGroup.get(key).markAsDirty();
        });

        this.expedienteForm.push(formGroup);
        this._diaSelecionado = dia;
    }

    excluirDiaDoExpediente(indice: number): void {
        this.expedienteForm.removeAt(indice);
    }

    verificarExpansao(formGroup: FormGroup): boolean {
        const dia = formGroup.get('dia').value as DiaSemana;

        return dia === this._diaSelecionado;
    }

    selecionarDia(formGroup: FormGroup): void {
        this._diaSelecionado = formGroup.get('dia').value as DiaSemana;
    }

    gravarDados(): void {
        if (this._houveAlteracao) {
            this._fuseProgressBarService.show();
            const formArray = this.form.get('itensExpediente').value;

            const arrayExpediente = formArray.map(
                (value: ExpedienteCartorio) => value as ExpedienteCartorio
            );

            const retornoValidacao = this._validacaoExpedienteService.validarGravacaoDadosExpediente(
                arrayExpediente
            );

            if (!retornoValidacao.sucesso) {
                let mensagemErro =
                    retornoValidacao.erros.length === 1
                        ? 'Ocorreu um erro durante o processo de gravação. <br><b>Detalhe:</b>'
                        : 'Ocorreram alguns erros durante a gravação. <br><b>Detalhes:</b>';

                retornoValidacao.erros?.forEach((erro) => {
                    mensagemErro += `<br>${erro}`;
                });

                this._fuseProgressBarService.hide();
                this._dialogService.dialogInformacaoHtml(
                    'Atenção',
                    mensagemErro,
                    'auto',
                    'auto'
                );
                return;
            }

            this._cartorioControllerService
                .gravarExpedienteCartorio(this._cns, arrayExpediente)
                .subscribe(
                    (sucesso) => {
                        this._toastService.mensagemSuccesso(
                            'Expediente alterado com sucesso.'
                        );
                        this._fuseProgressBarService.hide();
                        this._dialogRef.close();
                    },
                    (erro) => {
                        this._toastService.mensagemErro(
                            'Ocorreu um erro ao realizar a gravação.'
                        );
                        console.log(erro);
                        this._fuseProgressBarService.hide();
                    }
                );
            return;
        }

        this._dialogRef.close();
    }
}
