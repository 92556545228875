/* tslint:disable no-any */
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogInformacaoComponent } from './dialog-informacao.component';
import { DialogData } from './models/dialog-data';
import { DialogPerguntaComponent } from './dialog-pergunta.component';
import { ComponentType } from '@angular/cdk/overlay/index';
import { Observable } from 'rxjs';
import {
    WIDTH_XS,
    MODAL_WIDTH_XL,
    WIDTH_SM,
    MODAL_WIDTH_LG,
    WIDTH_MD,
    WIDTH_LG,
    WIDTH_XL,
} from '../..';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(private _dialog: MatDialog) {}

    public dialogInformacao(
        title: string = 'Atenção',
        mensagem: string,
        fnFechar: VoidFunction = null
    ): void {
        const diagData = new DialogData();

        diagData.title = title;
        diagData.msg = mensagem;
        diagData.type = 'texto';
        diagData.fnFechar = fnFechar;

        this._dialog.open(DialogInformacaoComponent, {
            data: diagData,
            disableClose: true,
            restoreFocus: false,
        });
    }

    public dialogInformacao2(
        title: string = 'Atenção',
        mensagem: string,
        fnFechar: VoidFunction = null
    ): void {
        const diagData = new DialogData();

        diagData.title = title;
        diagData.msg = mensagem;
        diagData.type = 'texto';
        diagData.fnFechar = fnFechar;

        this._dialog.open(DialogInformacaoComponent, {
            data: diagData,
            disableClose: true,
            restoreFocus: false,
        }).afterClosed();
    }

    public dialogInformacaoHtml(
        title: string = 'Atenção',
        html: string,
        height: string,
        width: string,
        fnFechar: VoidFunction = null
    ): void {
        const diagData = new DialogData();

        diagData.title = title;
        diagData.html = html;
        diagData.type = 'html';
        diagData.fnFechar = fnFechar;

        this._dialog.open(DialogInformacaoComponent, {
            data: diagData,
            height: height,
            width: width,
        });
    }

    public dialogPergunta(
        title: string = 'Atenção',
        mensagem: string,
        fnSim: VoidFunction = null,
        fnNao: VoidFunction = null,
        width: string = null
    ): void {
        const diagData = new DialogData();

        diagData.title = title;
        diagData.msg = mensagem;
        diagData.type = 'texto';
        diagData.fnSim = fnSim;
        diagData.fnNao = fnNao;

        this._dialog.open(DialogPerguntaComponent, {
            data: diagData,
            width: width,
        });
    }

    public dialogPerguntaAsync(
        title: string = 'Atenção',
        mensagem: string,
        fnSim: VoidFunction = null,
        fnNao: VoidFunction = null,
        width: string = null
    ): Observable<any> {
        const diagData = new DialogData();

        diagData.title = title;
        diagData.msg = mensagem;
        diagData.type = 'texto';
        diagData.fnSim = fnSim;
        diagData.fnNao = fnNao;

        return this._dialog
            .open(DialogPerguntaComponent, {
                data: diagData,
                width: width,
            })
            .afterClosed();
    }

    public dialogPerguntaHtml(
        title: string = 'Atenção',
        html: string,
        fnSim: VoidFunction = null,
        fnNao: VoidFunction = null
    ): void {
        const diagData = new DialogData();

        diagData.title = title;
        diagData.html = html;
        diagData.type = 'html';
        diagData.fnSim = fnSim;
        diagData.fnNao = fnNao;

        this._dialog.open(DialogPerguntaComponent, {
            data: diagData,
        });
    }

    public dialogGenerico<T, D = any, R = any>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        config?: MatDialogConfig<D>
    ): Observable<R | undefined> {
        if (!config.width) {
            config = this._verificaWidth(config);
        }

        config.disableClose =
            config.disableClose !== null && config.disableClose !== undefined
                ? config.disableClose
                : true;
        config.restoreFocus = false;

        return this._dialog.open(componentOrTemplateRef, config).afterClosed();
    }

    public dialogGenericoVoid<T, D = any, R = any>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        config?: MatDialogConfig<D>
    ): void {
        
        if (!config.width) {
            config = this._verificaWidth(config);
        }

        config.disableClose =
            config.disableClose !== null && config.disableClose !== undefined
                ? config.disableClose
                : true;
        config.restoreFocus = false;
        console.log(config);

        this._dialog.open(componentOrTemplateRef, config);
    }

    private _verificaWidth(config: MatDialogConfig): MatDialogConfig {
        switch (true) {
            case innerWidth < WIDTH_XS:
                config.width = config.width ? config.width : MODAL_WIDTH_XL;
                break;
            case innerWidth > WIDTH_XS && innerWidth < WIDTH_SM:
                config.width = config.width ? config.width : MODAL_WIDTH_LG;
                break;
            case innerWidth > WIDTH_SM && innerWidth < WIDTH_MD:
                config.width = config.width ? config.width : MODAL_WIDTH_LG;
                break;
            case innerWidth > WIDTH_MD && innerWidth < WIDTH_LG:
                config.width = config.width ? config.width : MODAL_WIDTH_LG;
                break;
            case innerWidth > WIDTH_LG && innerWidth < WIDTH_XL:
                config.width = config.width ? config.width : MODAL_WIDTH_LG;
                break;
            default:
                config.width = config.width ? config.width : MODAL_WIDTH_LG;
                break;
        }
        return config;
    }
}
