import { Injectable } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    _defaultHorizPosition: MatSnackBarHorizontalPosition = 'right';
    _defaultVertPosition: MatSnackBarVerticalPosition = 'top';
    _defaultDuration = 3000;

    constructor(private _snackBar: MatSnackBar) {}

    mensagemInfo(
        msg: string,
        duration = this._defaultDuration,
        btn = 'OK',
        horizontalPosition = this._defaultHorizPosition,
        verticalPosition = this._defaultVertPosition,
        panelClass = ['toast-info']
    ): void {
        this._snackBar.open(msg, btn, {
            duration: duration,
            horizontalPosition: horizontalPosition,
            verticalPosition: verticalPosition,
            panelClass: panelClass,
        });
    }

    mensagemErro(
        msg: string,
        duration = this._defaultDuration,
        btn = 'OK',
        horizontalPosition = this._defaultHorizPosition,
        verticalPosition = this._defaultVertPosition
    ): void {
        this.mensagemInfo(
            msg,
            duration,
            btn,
            horizontalPosition,
            verticalPosition,
            ['toast-error']
        );
    }

    mensagemAlerta(
        msg: string,
        duration = this._defaultDuration,
        btn = 'OK',
        horizontalPosition = this._defaultHorizPosition,
        verticalPosition = this._defaultVertPosition
    ): void {
        this.mensagemInfo(
            msg,
            duration,
            btn,
            horizontalPosition,
            verticalPosition,
            ['toast-warning']
        );
    }

    mensagemSuccesso(
        msg: string,
        duration = this._defaultDuration,
        btn = 'OK',
        horizontalPosition = this._defaultHorizPosition,
        verticalPosition = this._defaultVertPosition
    ): void {
        this.mensagemInfo(
            msg,
            duration,
            btn,
            horizontalPosition,
            verticalPosition,
            ['toast-success']
        );
    }
}
