import { OnInit, AfterViewInit, OnDestroy, Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from '../servicos/dialogs';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    template: ''
})
export abstract class DialogFormBase<T>
    implements OnInit, AfterViewInit, OnDestroy {
    protected _houveAlteracao: boolean;
    public form: FormGroup;

    constructor(
        protected _formBuilder: FormBuilder,
        protected _dialogService: DialogService,
        protected _dialogRef: MatDialogRef<T>
    ) {
        this._houveAlteracao = false;
    }

    ngOnDestroy(): void {
        this.processamentoOnDestroy();
    }

    ngAfterViewInit(): void {
        this.processamentoOnAfterViewInit();
    }

    ngOnInit(): void {
        this.construirForm(this._formBuilder);
        this.carregarDados();
        this.processamentoOnInit();
    }

    processamentoOnInit(): void {}

    processamentoOnAfterViewInit(): void {}

    processamentoOnDestroy(): void {}

    fechar(): void {
        if (this._houveAlteracao) {
            this._dialogService.dialogPergunta(
                'Atenção',
                'Ao fechar, os dados serão perdidos. Deseja realmente fechar?',
                () => {
                    this._dialogRef.close();
                },
                null
            );

            return;
        }

        this._dialogRef.close();
    }

    abstract construirForm(formBuilder: FormBuilder): void;
    abstract carregarDados(): void;
    abstract gravarDados(): void;
}
