import { OrientacoesLgpdComponent } from './cartorio/orientacoes-lgpd/orientacoes-lgpd.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import {
    ConfiguracoesCartsysComponent,
    CartorioPipe,
} from './cartsys/configuracoes-cartsys.component';
import { ConfiguracoesCartorioComponent } from './cartorio/configuracoes-cartorio.component';
import {
    FuseDemoModule,
    FuseWidgetModule,
    FuseSearchBarModule,
} from '@fuse/components';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { ManutencaoCartorioComponent } from './cartsys/manutencao-cartorio/manutencao-cartorio.component';
import { NgxMaskModule } from 'ngx-mask';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { ExpedienteCartorioComponent } from './cartorio/expediente-cartorio/expediente-cartorio.component';
import { MatMenuModule } from '@angular/material/menu';
import { ManutencaoUsuariosComponent } from './cartorio/manutencao-usuarios/manutencao-usuarios.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AtendimentoUsuarioComponent } from './cartorio/atendimento-usuario/atendimento-usuario.component';
import { MatDividerModule } from '@angular/material/divider';
import { ManutencaoTiposServicoComponent } from './cartorio/manutencao-tipos-servico/manutencao-tipos-servico.component';
import { TiposServicoUsuarioComponent } from './cartorio/tipos-servico-usuario/tipos-servico-usuario.component';
import { CartsysGuard } from '../_shared/guards/cartsys.guard';
import { AdminCartorioGuard } from '../_shared/guards/admin-cartorio.guard';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ConfiguracoesGeraisComponent } from './cartsys/configuracoes-gerais/configuracoes-gerais.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ExclusaoDadosComponent } from './cartorio/exclusao-dados/exclusao-dados.component';
import { MatDatepickerModule } from '@angular/material/datepicker';

const routes = [
    {
        path: 'configuracoes-cartsys',
        canActivate: [CartsysGuard],
        component: ConfiguracoesCartsysComponent,
    },
    {
        path: 'configuracoes-cartorio',
        canActivate: [AdminCartorioGuard],
        component: ConfiguracoesCartorioComponent,
    },
];

@NgModule({
    declarations: [
        CartorioPipe,
        ConfiguracoesCartsysComponent,
        ConfiguracoesCartorioComponent,
        ManutencaoCartorioComponent,
        ExpedienteCartorioComponent,
        ManutencaoUsuariosComponent,
        AtendimentoUsuarioComponent,
        ManutencaoTiposServicoComponent,
        TiposServicoUsuarioComponent,
        ConfiguracoesGeraisComponent,
        OrientacoesLgpdComponent,
        ExclusaoDadosComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatDialogModule,
        MatButtonModule,
        MatTabsModule,
        MatToolbarModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        FuseSharedModule,
        FuseWidgetModule,
        FuseDemoModule,
        FuseSearchBarModule,
        NgxMaskModule,
        MatCardModule,
        MatExpansionModule,
        MatMenuModule,
        MatTableModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatChipsModule,
        MatDividerModule,
        MatStepperModule,
        EditorModule,
        MatProgressSpinnerModule,
        MatDatepickerModule
    ],
    exports: [CartorioPipe],
})
export class ConfiguracoesModule {}
