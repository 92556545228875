import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ConfigService } from 'app/config/config.service';

@Injectable({
    providedIn: 'root',
})
export class VerificaApiService {

    private _http: HttpClient;
    // constructor(private _http: HttpClient, private _configService: ConfigService) 
    // {
    //     _http = new HttpClient();
    // }

    constructor(handler: HttpBackend, private _configService: ConfigService) {
        this._http = new HttpClient(handler);
    }

    public verificarApiOnline(): Observable<boolean> {
        let url = this._configService.config.apiUrl;
        return this._http.get<boolean>(`${url}online`);
    }
}
