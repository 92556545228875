import { DialogOrientacoesLgpdComponent } from './dialog-orientacoes-lgpd/dialog-orientacoes-lgpd.component';
import {
    Component,
    ViewEncapsulation,
    Input,
    AfterViewInit,
    Output,
    EventEmitter,
    HostListener,
} from '@angular/core';

import {
    TipoAtendimentoCartorio,
    TipoResposta,
    TipoMascara,
    TipoAtendimentoUsuario,
    StatusAtendimentoUsuario,
} from 'app/main/_shared/models/_enums';
import {
    PerguntaCartorio,
    RespostaAtendimento,
    AgendamentoAtendimento,
} from 'app/main/_shared/models/cartorio';
import { TipoServicoAtendimento } from 'app/main/_shared/models/cartorio/tipo-servico-atendimento.model';
import { TipoServicoControllerService } from 'app/main/_shared/servicos/controllers/tipo-servico-controller.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastService } from 'app/main/_shared/servicos';
import {
    PerguntaCartorioControllerService,
    AtendimentoControllerService,
} from 'app/main/_shared/servicos/controllers';
import {
    mascaraTelefone,
    cepEhValido,
    cpfEhValido,
} from 'app/main/_shared/funcoes';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DialogService } from 'app/main/_shared/servicos/dialogs';
import { Overlay } from '@angular/cdk/overlay';
import { EMPTY_GUID } from 'app/main/_shared';
import { DadosCartorioHomeDTO } from 'app/main/_shared/models/dtos/dados-cartorio-home-dto.model';
import { DialogFinalAgendamentoComponent } from './dialog-final-agendamento/dialog-final-agendamento.component';

interface Message {
    doAplicativo: boolean;
    conteudo: any;
    tipo: TipoResposta;
}

@Component({
    selector: 'perguntas',
    templateUrl: './perguntas.component.html',
    styleUrls: ['./perguntas.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PerguntasComponent implements AfterViewInit {
    @Input()
    public tipoAtendimento: TipoAtendimentoCartorio =
        TipoAtendimentoCartorio.Virtual;
    @Input()
    public dadosCartorio: DadosCartorioHomeDTO = new DadosCartorioHomeDTO();

    @Output()
    public eventoFechar: EventEmitter<void> = new EventEmitter<void>();

    public carregandoMsg = false;
    public tiposServico = new Array<TipoServicoAtendimento>();
    public mensagemInicial = '';
    public tipoServicoSelecionado?: TipoServicoAtendimento = null;
    public mascaraTelefone = '(00) 0000-0000';
    private _messages = new Array<Message>();
    private _perguntas = new Array<PerguntaCartorio>();
    private _respostas = new Array<RespostaAtendimento>();
    private _idPerguntaRealizada: number;
    private _quantidadePerguntas: number;
    private _respondeuAlgumaCoisa = false;

    public texto: string;
    public imagem: any;
    public arquivo: string;
    public telefone: string;
    public cpf: string;
    public cep: string;
    public numero: string;
    public datasDisponiveis: Array<Date> = new Array<Date>();
    public horariosDisponiveis: Array<string> = new Array<string>();
    public selecionandoAgendamento = false;

    public horarioSelecionado: string;
    public dataSelecionada: Date;

    private _perguntasObrigatorias: { [id: number]: PerguntaCartorio } = {};
    private _idPerguntaObrigatoriaAtual: number;
    private _perguntandoPerguntasObrigatorias = false;

    public get perguntaAtual(): PerguntaCartorio {
        return this._perguntandoPerguntasObrigatorias
            ? this._perguntasObrigatorias[this._idPerguntaObrigatoriaAtual]
            : this._perguntas[this._idPerguntaRealizada];
    }

    public get opcoesSelect(): Array<string> {
        return this.perguntaAtual.opcoesMultiplaEscolha.split(",");
    }

    public get tipoResposta(): typeof TipoResposta {
        return TipoResposta;
    }

    public get tipoMascara(): typeof TipoMascara {
        return TipoMascara;
    }

    constructor(
        private _overlay: Overlay,
        private _tipoServicoController: TipoServicoControllerService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _toastService: ToastService,
        private _dialogService: DialogService,
        private _perguntasCartorioController: PerguntaCartorioControllerService,
        private _atendimentoController: AtendimentoControllerService
    ) {
        this._messages = [];
    }

    public ngAfterViewInit(): void {
        this.carregamentoInicial();
    }

    private carregamentoInicial(): void {
        this._fuseProgressBarService.show();

        const tipoAtendimentoUsuario =
            this.tipoAtendimento === TipoAtendimentoCartorio.Virtual
                ? TipoAtendimentoUsuario.ApenasVirtual
                : TipoAtendimentoUsuario.ApenasPresencial;

        this._tipoServicoController
            .listarTiposServicoAtendimento(
                this.dadosCartorio.cns,
                tipoAtendimentoUsuario
            )
            .subscribe(
                (tiposServico) => {
                    setTimeout(() => {
                        this.tiposServico = tiposServico;
                        if (this.tiposServico.length === 0) {                            
                            this._dialogService
                                .dialogInformacao(
                                    "Atenção",
                                    `Não existem tipos de serviço habilitados para o tipo de atendimento selecionado, ` +
                                    `entre em contato com ${this.dadosCartorio.nome} por meio do telefone ${this.dadosCartorio.telefone}.`,(() => {
                                        this.voltar();
                                    })
                                );
                        }
                    });  
                    this._fuseProgressBarService.hide();
                },
                () => {
                    this._toastService.mensagemErro(
                        'Houve um problema ao acessar este serviço.'
                    );
                    this._fuseProgressBarService.hide();
                }
            );
    }

    get tipoAtendimentoCartorio(): typeof TipoAtendimentoCartorio {
        return TipoAtendimentoCartorio;
    }

    get messages(): Array<Message> {
        return this._messages;
    }

    private scroolToBottom(): void {
        try {
            setTimeout(() => {
                const element = document.getElementById('listaMensagens');
                element.scrollTop = 9999999;

                const input = document.getElementById('inputResposta');

                if (!!input) {
                    input.focus({ preventScroll: false });
                }
            }, 50);
        } catch (err) {}
    }

    public addMessage(event: Event): void {
        event.preventDefault();

        const conteudoResposta = this.validarInputs();

        if (!conteudoResposta) {
            return;
        }

        this._respondeuAlgumaCoisa = true;

        this._messages.push({
            doAplicativo: false,
            conteudo: conteudoResposta,
            tipo: this.perguntaAtual.tipo,
        });

        this._respostas.push(
            new RespostaAtendimento(this.perguntaAtual.id, conteudoResposta)
        );

        this.limparForm();
        if (this._idPerguntaObrigatoriaAtual < 2) {
            this._idPerguntaObrigatoriaAtual++;
        } else {
            this._idPerguntaRealizada++;
            this._perguntandoPerguntasObrigatorias = false;
        }

        if (
            this._perguntandoPerguntasObrigatorias ||
            this._idPerguntaRealizada < this._quantidadePerguntas
        ) {
            this.processarMensagem();
        } else {
            this.solicitarAgendamentoDeHorario();
        }
    }

    public limparForm(): void {
        this.texto = undefined;
        this.imagem = undefined;
        this.arquivo = undefined;
        this.telefone = undefined;
        this.cpf = undefined;
        this.cep = undefined;
        this.numero = undefined;
    }

    private validarInputs(): string {
        switch (this.perguntaAtual.tipo) {
            case TipoResposta.Texto: 
            case TipoResposta.MultiplaEscolha: {
                if (!!this.texto) {
                    return this.texto;
                }
                break;
            }
            case TipoResposta.Arquivo: {
                if (!!this.arquivo) {
                    return this.arquivo;
                }
                break;
            }
            case TipoResposta.Imagem: {
                if (!!this.imagem) {
                    return this.imagem;
                }
                break;
            }
            case TipoResposta.Numero: {
                if (!!this.numero) {
                    return this.numero;
                }
                break;
            }
            case TipoResposta.Mascara: {
                const mascara = this.perguntaAtual.mascara;

                switch (mascara) {
                    case TipoMascara.Cep: {
                        if (this.cepValido) {
                            return this.cep;
                        }
                        break;
                    }
                    case TipoMascara.Cpf: {
                        if (this.cpfValido) {
                            return this.cpf;
                        }
                        break;
                    }
                    case TipoMascara.Telefone: {
                        if (
                            !!this.telefone &&
                            this.telefone.length === this.mascaraTelefone.length
                        ) {
                            return this.telefone;
                        }
                        break;
                    }
                }
                break;
            }
        }

        return null;
    }

    public get cepValido(): boolean {
        return !!this.cep && cepEhValido(this.cep);
    }

    public get cpfValido(): boolean {
        return !!this.cpf && cpfEhValido(this.cpf);
    }

    public get inputsValidos(): boolean {
        return !!this.validarInputs();
    }

    private processarMensagem(): void {
        this.carregandoMsg = true;

        if (this._perguntandoPerguntasObrigatorias) {
            const pergunta = this._perguntasObrigatorias[
                this._idPerguntaObrigatoriaAtual
            ];

            // this._perguntas.push(pergunta);
            this.carregandoMsg = false;

            const message = {
                doAplicativo: true,
                conteudo: pergunta.texto,
                tipo: TipoResposta.Texto,
            };

            this.messages.push(message);
            this.scroolToBottom();
        } else {
            this._perguntasCartorioController
                .retornarPerguntaTipoServicoPorIndice(
                    this.dadosCartorio.cns,
                    this.tipoServicoSelecionado.id,
                    this._idPerguntaRealizada
                )
                .subscribe(
                    (pergunta) => {
                        this._perguntas.push(pergunta);
                        this.carregandoMsg = false;

                        const message = {
                            doAplicativo: true,
                            conteudo: pergunta.texto,
                            tipo: TipoResposta.Texto,
                        };

                        this.messages.push(message);
                        this.scroolToBottom();
                    },
                    (erro) => {
                        this._toastService.mensagemErro(
                            'Houve um problema ao acessar este serviço.'
                        );
                        console.log(erro);
                    }
                );
        }
    }

    public selecionarTipoServico(tipoServico: TipoServicoAtendimento): void {
        this.tipoServicoSelecionado = tipoServico;

        this._perguntasCartorioController
            .retornarQuantidadePerguntas(
                this.dadosCartorio.cns,
                this.tipoServicoSelecionado.id
            )
            .subscribe((quantidade) => {
                this._quantidadePerguntas = quantidade;
                this._idPerguntaRealizada = -1;
                this._perguntandoPerguntasObrigatorias = true;
                this._idPerguntaObrigatoriaAtual = 0;

                this._perguntasObrigatorias[0] = {
                    id: 'NomeRequirente',
                    cns: this.dadosCartorio.cns,
                    idTipoServico: tipoServico.id,
                    indice: 0,
                    tipo: TipoResposta.Texto,
                    mascara: TipoMascara.Nenhuma,
                    opcoesMultiplaEscolha: '',
                    texto: 'Por favor, nos informe o seu nome completo.',
                };

                this._perguntasObrigatorias[1] = {
                    id: 'Cpf',
                    cns: this.dadosCartorio.cns,
                    idTipoServico: tipoServico.id,
                    indice: 1,
                    tipo: TipoResposta.Mascara,
                    mascara: TipoMascara.Cpf,
                    opcoesMultiplaEscolha: '',
                    texto: 'Por favor, nos informe o seu Cpf.',
                };

                this._perguntasObrigatorias[2] = {
                    id: 'Telefone',
                    cns: this.dadosCartorio.cns,
                    idTipoServico: tipoServico.id,
                    indice: 2,
                    tipo: TipoResposta.Mascara,
                    mascara: TipoMascara.Telefone,
                    opcoesMultiplaEscolha: '',
                    texto: 'Por favor, nos informe o seu telefone.',
                };

                this._messages.push({
                    doAplicativo: true,
                    conteudo:
                        'As perguntas a seguir serão utilizadas para que o serviço solicitado seja executado com sucesso.',
                    tipo: TipoResposta.Texto,
                });

                this.processarMensagem();
            });
    }

    public alteracaoTelefone(telefone: string): void {
        this.mascaraTelefone = mascaraTelefone(telefone);
    }

    public uploadImagem(resultadoUpload: any): void {
        this.imagem = resultadoUpload.path;
        this.addMessage(new Event(''));
    }

    public uploadArquivo(resultadoUpload: any): void {
        this.arquivo = resultadoUpload.path;
        this.addMessage(new Event(''));
    }

    public filtroDatas = (d: Date | null): boolean => {
        const diasDisponiveis = this.datasDisponiveis.filter((data) => {
            if (!d) {
                return false;
            }

            const dataFiltro = new Date(data);
            return (
                dataFiltro.getDate() === d.getDate() &&
                dataFiltro.getMonth() === d.getMonth() &&
                dataFiltro.getFullYear() === d.getFullYear()
            );
        });

        return diasDisponiveis.length > 0;
    };

    public solicitarAgendamentoDeHorario(): void {
        this.carregandoMsg = true;

        this._messages.push({
            doAplicativo: true,
            conteudo: 'Obrigado pelas informações!!',
            tipo: TipoResposta.Texto,
        });

        this._messages.push({
            doAplicativo: true,
            conteudo:
                'Agora você pode realizar o agendamento, por favor selecione uma data e horário disponível.',
            tipo: TipoResposta.Texto,
        });

        this.listarDatasDisponiveis();
    }

    private listarDatasDisponiveis(): void {
        this._fuseProgressBarService.show();
        this._atendimentoController
            .listarDatasDisponiveis(
                this.dadosCartorio.cns,
                new Date(),
                this.tipoAtendimento,
                this.tipoServicoSelecionado.id
            )
            .subscribe(
                (datas) => {
                    this.datasDisponiveis = datas;
                    this.carregandoMsg = false;
                    this.scroolToBottom();
                    this._fuseProgressBarService.hide();
                    this.selecionandoAgendamento = true;
                },
                (erro) => {
                    this.carregandoMsg = false;
                    this.scroolToBottom();
                    this._fuseProgressBarService.hide();
                    console.log(erro);
                    this._toastService.mensagemErro(
                        'Ocorreu um erro ao processar as mensagens.'
                    );
                }
            );
    }

    public selecionarData(event: MatDatepickerInputEvent<Date>): void {
        const data = event.value;

        setTimeout(() => {
            this.horariosDisponiveis = [];
            this.horarioSelecionado = undefined;
            this.dataSelecionada = new Date(
                Date.UTC(data.getFullYear(), data.getMonth(), data.getDate())
            );
        }, 50);

        this._fuseProgressBarService.show();
        this._atendimentoController
            .listarHorariosAtendimentoDisponiveis(
                this.dadosCartorio.cns,
                data,
                this.tipoAtendimento,
                this.tipoServicoSelecionado.id
            )
            .subscribe(
                (horarios) => {
                    setTimeout(() => {
                        this.horariosDisponiveis = horarios;
                    }, 50);

                    this.carregandoMsg = false;
                    this.scroolToBottom();
                    this._fuseProgressBarService.hide();
                    this.selecionandoAgendamento = true;
                },
                (erro) => {
                    this.carregandoMsg = false;
                    this.scroolToBottom();
                    this._fuseProgressBarService.hide();
                    console.log(erro);
                    this._toastService.mensagemErro(
                        'Ocorreu um erro ao processar as mensagens.'
                    );
                }
            );
    }

    public confirmarAgendamento(): void {
        this._fuseProgressBarService.show();
        const nomeRequirente = this._respostas[0].resposta;
        const cpf = this._respostas[1].resposta;
        const telefone = this._respostas[2].resposta;

        this._respostas.splice(0, 3);

        const agendamento: AgendamentoAtendimento = {
            id: EMPTY_GUID,
            cns: this.dadosCartorio.cns,
            nomeRequirente: nomeRequirente,
            telefone: telefone,
            cpf: cpf,
            dataAgendamento: this.dataSelecionada,
            horaAgendamento: this.horarioSelecionado,
            idTipoServico: this.tipoServicoSelecionado.id,
            observacaoAtendimento: '',
            protocolo: '',
            idUsuario: null,
            respostas: this._respostas,
            status: StatusAtendimentoUsuario.Pendente,
            tipoAtendimento: this.tipoAtendimento,
            dataHoraCriacaoAgendamento: new Date(),
            horarioLiberado: false,
        };

        this._atendimentoController
            .confirmarAgendamento(this.dadosCartorio.cns, agendamento)
            .subscribe(
                ({ protocolo }) => {
                    this._fuseProgressBarService.hide();
                    this._dialogService
                        .dialogGenerico(DialogFinalAgendamentoComponent, {
                            scrollStrategy: this._overlay.scrollStrategies.noop(),
                            height: 'auto',
                            maxHeight: '80%',
                            data: {
                                sucesso: true,
                                protocolo: protocolo,
                                mensagem: this.tipoServicoSelecionado
                                    .mensagemFinalAtendimento,
                            },
                        })
                        .subscribe(() => {
                            this.eventoFechar.emit();
                        });
                },
                (erro) => {
                    this._fuseProgressBarService.hide();
                    const strErro = erro as string;
                    if (!!strErro && strErro.includes('[CONFLICT]')) {
                        erro = erro.replace('[CONFLICT]', '');
                        this._dialogService
                            .dialogGenerico(DialogFinalAgendamentoComponent, {
                                scrollStrategy: this._overlay.scrollStrategies.noop(),
                                height: 'auto',
                                maxHeight: '80%',
                                width: '40%',
                                maxWidth: '40%',
                                data: {
                                    sucesso: false,
                                    mensagem: erro,
                                },
                            })
                            .subscribe(() => {
                                this.horarioSelecionado = undefined;
                                this.dataSelecionada = undefined;
                                this.listarDatasDisponiveis();
                            });
                    } else {
                        this._toastService.mensagemErro(
                            'Ocorreu um erro ao realizar a gravação dos dados.'
                        );
                    }
                    console.log(erro);
                }
            );
    }

    public voltar(): void {
        if (!this._respondeuAlgumaCoisa) {
            this.eventoFechar.emit();
            this._respondeuAlgumaCoisa = false;
            return;
        }

        this._dialogService.dialogPergunta(
            'Atenção',
            'Ao sair da página atual, todas os dados já informados serão perdidos. Deseja realmente sair da página?',
            () => {
                this.eventoFechar.emit();
            }
        );
    }

    public exibirOrientacoesLgpd() {
        this._dialogService
            .dialogGenerico(DialogOrientacoesLgpdComponent, {
                scrollStrategy: this._overlay.scrollStrategies.noop(),
                height: 'auto',
                maxHeight: '80%',
                data: {
                    orientacoes: this.dadosCartorio.orientacoesLGPD
                },
            });
    }

    @HostListener('window:beforeunload', ['$event']) unloadHandler(
        event: Event
    ): void {
        event.returnValue = false;
    }
}
