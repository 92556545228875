<h1 mat-dialog-title>
    Orientações LGPD
</h1>
<mat-dialog-content mat-dialog-content class="conteudo-mensagem" [fxShow]="!this.carregandoEditor">
    <editor
        [init]="this.configuracoesEditor"
        [(ngModel)]="this.orientacoesLgpd"
        (onInit)="this.onInitEditor()"
    >
    </editor>
</mat-dialog-content>
<mat-dialog-content mat-dialog-content class="conteudo-mensagem" [fxShow]="this.carregandoEditor" fxLayout="row" fxLayoutAlign="center center">
    <mat-progress-spinner 
        color="accent"
        mode="indeterminate">
    </mat-progress-spinner>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end end" class="text-right">
    <button mat-stroked-button color="accent" (click)="this.fecharTela()">
        Fechar
    </button>
    <button
        mat-raised-button
        color="accent"
        class="ml-8"
        (click)="this.gravarDados()"
    >
        Salvar
    </button>
</mat-dialog-actions>
