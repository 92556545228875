<h1 mat-dialog-title>
    Agendamento
    {{
        this.dados.sucesso
            ? 'realizado com sucesso !'
            : 'não pode ser realizado'
    }}
</h1>

<mat-dialog-content mat-dialog-content class="conteudo-mensagem" [fxShow]="this.carregandoEditor" fxLayoutAlign="space-around center">
    <mat-progress-spinner 
        color="accent"
        mode="indeterminate">
    </mat-progress-spinner>
</mat-dialog-content>

<mat-dialog-content mat-dialog-content class="conteudo-mensagem" [fxShow]="!this.carregandoEditor">
    <div *ngIf="!this.dados.sucesso" fxLayout="column">
        <span class="orientacoes-mensagem">
            Detalhes do erro:
            <br />
        </span>
        <div [innerHTML]="this.dados.mensagem"></div>
    </div>

    <editor
        id="printable"
        *ngIf="this.dados.sucesso"
        [disabled]="true"
        [init]="this.configuracoesEditor"
        [(ngModel)]="this.mensagemHTML"
        (onInit)="this.onInitEditor()"
    >
    </editor>
</mat-dialog-content>

<mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end center"
    class="text-right"
>
    <span class="pr-8" *ngIf="this.dados.sucesso">
        Para facilitar o processo imprima estas informações clicando em
        "Imprimir"
    </span>
    <button
        *ngIf="this.dados.sucesso"
        mat-raised-button
        color="accent"
        [useExistingCss]="true"
        printSectionId="printable"
        [disabled]="this.carregandoEditor"
        ngxPrint
    >
        Imprimir
    </button>
    <button mat-stroked-button color="accent" mat-dialog-close [disabled]="this.carregandoEditor" (click)="this.fechar()">
        Fechar
    </button>
</mat-dialog-actions>
