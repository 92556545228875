import { Injectable } from '@angular/core';
import { ExpedienteCartorio } from '../../models/cartorio';
import { retornarDescricaoDiaSemana } from '../../funcoes';

@Injectable({ providedIn: 'root' })
export class ValidacaoExpedienteCartorioService {
    validarGravacaoDadosExpediente(
        arrayExpediente: Array<ExpedienteCartorio>
    ): { sucesso: boolean; erros?: Array<string> } {
        const erros = new Array<string>();

        arrayExpediente.forEach((expediente) => {
            const horaInicioExpediente = new Date(
                `2020-01-01 ${expediente.horaInicioExpediente}`
            );

            const horaInicioDisponibilidade = new Date(
                `2020-01-01 ${expediente.horaInicioDisponibilidadeSistema}`
            );

            const horaFimExpediente = new Date(
                `2020-01-01 ${expediente.horaFimExpediente}`
            );

            const horaFimDisponibilidade = new Date(
                `2020-01-01 ${expediente.horaFimDisponibilidadeSistema}`
            );

            if (horaFimExpediente < horaInicioExpediente) {
                erros.push(
                    `No dia <em>"${retornarDescricaoDiaSemana(
                        expediente.dia
                    )}"</em> a hora de fim do expediente é menor que o início do expediente.`
                );
            }

            if (horaFimDisponibilidade < horaInicioDisponibilidade) {
                erros.push(
                    `No dia <em>"${retornarDescricaoDiaSemana(
                        expediente.dia
                    )}"</em> a hora de fim da disponibilidade do sistema é menor que o início da disponibilidade do sistema.`
                );
            }

            if (horaInicioDisponibilidade > horaInicioExpediente) {
                erros.push(
                    `No dia <em>"${retornarDescricaoDiaSemana(
                        expediente.dia
                    )}"</em> a hora de inicio do expediente é menor que o início da disponibilidade do sistema.`
                );
            }

            if (horaFimDisponibilidade < horaFimExpediente) {
                erros.push(
                    `No dia <em>"${retornarDescricaoDiaSemana(
                        expediente.dia
                    )}"</em> a hora final de disponibilidade do sistema é menor que a hora final do expediente.`
                );
            }
        });

        if (erros.length > 0) {
            return { sucesso: false, erros: erros };
        }

        return { sucesso: true };
    }
}
