import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    DialogInformacaoComponent,
    DialogPerguntaComponent,
    DialogService,
} from './dialogs';
import { MatButtonModule } from '@angular/material/button';
import { ToastService } from './toast.service';
import { AuthenticationService } from './authentication.service';
import { ControllerModule } from './controllers/controller.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ValidacaoExpedienteCartorioService, FileImageService } from '.';
import {
    PerguntaCartorioControllerService,
    AtendimentoControllerService,
} from './controllers';
import { ConfiguracaoGlobalService } from './configuracao-global.service';
import { ConfiguracoesGeraisControllerService } from './controllers/configuracoes-gerais-controller.service';

@NgModule({
    declarations: [DialogInformacaoComponent, DialogPerguntaComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        ControllerModule,
        MatDialogModule,
        MatSnackBarModule,
    ],
    providers: [
        DialogService,
        ToastService,
        AuthenticationService,
        ConfiguracaoGlobalService,
        ValidacaoExpedienteCartorioService,
        PerguntaCartorioControllerService,
        ConfiguracoesGeraisControllerService,
        AtendimentoControllerService,
        FileImageService,
    ],
    exports: [DialogInformacaoComponent, DialogPerguntaComponent],
    entryComponents: [DialogInformacaoComponent, DialogPerguntaComponent],
})
export class ServicosModule {}
