<mat-toolbar id="footer">
    <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>
    
        <span class="footer-text" fxLayout="row wrap" fxHide.lt-md>
            <a href="assets/politica-seguranca.html" target="_blank">
                Site seguro e mantido sob política de segurança e privacidade
            </a>
        </span>

        <span class="footer-link" fxLayout="row wrap">
            <a href="http://www.cartsys.com.br/" target="_blank">
                Cartsys Software
            </a>
        </span>    
    </div> 
</mat-toolbar>
