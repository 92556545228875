import {
    Component,
    Inject,
    ViewEncapsulation,
    AfterViewInit,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from 'app/config/config.service';
import { configuracaoEditorReadOnly } from 'app/main/_shared/funcoes/configuracoes-editor';
import { TipoServicoAtendimento } from 'app/main/_shared/models/cartorio/tipo-servico-atendimento.model';

@Component({
    selector: 'dialog-tirar-duvida',
    templateUrl: './dialog-tirar-duvida.component.html',
    styleUrls: ['./dialog-tirar-duvida.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DialogTirarDuvidaComponent implements AfterViewInit {
    public configuracoesEditor = configuracaoEditorReadOnly('500', this._configService.config.urlDominio);
    public mensagemHTML = '';
    public carregandoEditor: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public dados: {
            tipoServico: TipoServicoAtendimento;
        },
        private _configService: ConfigService
    ) {
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.carregandoEditor = true;
        });
    }

    onInitEditor(): void {
        setTimeout(() => {
            this.carregandoEditor = false;
        });
    }
}
