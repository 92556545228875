<div id="localVideo" fxFlex="100"></div>
<fuse-sidebar class="buttons-jitsi-meet" [folded]="false" lockedOpen="gt-md" position="right" fxLayout="column"
    fxLayoutAlign="center center">
    <button mat-button class="m-8" [matTooltip]="this.micHabilitado ? 'Desabilitar microfone' : 'Habilitar microfone'"
        (click)="this.alterarMicrofone()">
        <mat-icon *ngIf="this.micHabilitado" class="material-icons color_white">mic</mat-icon>
        <mat-icon *ngIf="!this.micHabilitado" class="material-icons color_white">mic_off</mat-icon>
    </button>

    <button mat-button class="m-8" [matTooltip]="this.cameraHabilitada ? 'Desligar câmera' : 'Ligar câmera'"
        (click)="this.alterarCamera()">
        <mat-icon *ngIf="this.cameraHabilitada" class="material-icons color_white">videocam</mat-icon>
        <mat-icon *ngIf="!this.cameraHabilitada" class="material-icons color_white">videocam_off</mat-icon>
    </button>

    <button mat-button class="m-8"
        [matTooltip]="this.compartilhamentoHabilitado ? 'Interromper compartilhamento de tela' : 'Compartilhar a tela'"
        *ngIf="!this.conferenciaCliente" (click)="this.alterarCompartilhamentoTela()">
        <mat-icon *ngIf="this.compartilhamentoHabilitado" class="material-icons color_white">desktop_windows</mat-icon>
        <mat-icon *ngIf="!this.compartilhamentoHabilitado" class="material-icons color_white">desktop_access_disabled
        </mat-icon>
    </button>

    <button mat-button class="m-8 mt-16" *ngIf="!this.conferenciaCliente" matTooltip="Enviar link via Whatsapp"
        (click)="this.enviarLinkPeloWhatsApp()">
        <img src="assets/icons/whatsapp.png" alt="whatsapp" />
    </button>

    <button mat-button class="m-8 mb-16" matTooltip="Copiar link da sala" *ngIf="!this.conferenciaCliente"
        [cdkCopyToClipboard]="this.urlSala" (click)="this.copiarLink()">
        <mat-icon class="material-icons color_white">file_copy</mat-icon>
    </button>

    <button mat-button color="warn" class="m-8" matTooltip="Desligar videoconferência"
        (click)="this.fecharVideoConferencia()">
        <mat-icon>call_end</mat-icon>
    </button>
</fuse-sidebar>