import { CartorioControllerService } from './cartorio-controller.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UsuarioControllerService } from './usuario-controller.service';
import { AutenticacaoControllerService } from './autenticacao-controller..service';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        CartorioControllerService,
        UsuarioControllerService,
        AutenticacaoControllerService,
    ],
    exports: [],
    entryComponents: [],
})
export class ControllerModule {}
