import { BaseControllerService } from './base-controller.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '..';
import { Observable, of } from 'rxjs';
import { UsuarioCartorio, HorarioAtendimento } from '../../models/cartorio';
import { TipoServicoAtendimento } from '../../models/cartorio/tipo-servico-atendimento.model';
import { LookupUsuarioDTO } from '../../models/dtos/lookup-usuario-dto.model';
import { ConfigService } from 'app/config/config.service';

@Injectable({
    providedIn: 'root',
})
export class UsuarioControllerService extends BaseControllerService {
    constructor(
        _http: HttpClient,
        _authenticationService: AuthenticationService,
        configService: ConfigService
    ) {
        super(_http, _authenticationService, configService);
    }

    public listarUsuariosCartorio(
        cns: string
    ): Observable<Array<UsuarioCartorio>> {
        return this.get(`usuario/listarUsuariosCartorio/${cns}`);
    }

    public listarTiposServicoDoUsuario(
        idUsuario: string
    ): Observable<Array<TipoServicoAtendimento>> {
        return this.get(`usuario/listarTiposServicoDoUsuario/${idUsuario}`);
    }

    public listarHorariosAtendimentoUsuario(
        id: string
    ): Observable<Array<HorarioAtendimento>> {
        return this.get<Array<HorarioAtendimento>>(
            `usuario/listarhorariosatendimentousuario/${id}`
        );
    }

    public gravarHorariosAtendimentoUsuario(
        id: string,
        horarios: Array<HorarioAtendimento>
    ): Observable<boolean> {
        return of(true);
    }

    public gravarUsuarioCartorio(
        cns: string,
        vincularTiposServico: boolean,
        usuario: UsuarioCartorio
    ): Observable<void> {
        usuario.cnsCartorio = cns;
        return this.post(
            `usuario/gravarUsuario/${cns}/${vincularTiposServico}`,
            usuario
        );
    }

    public excluirUsuarioCartorio(
        cns: string,
        idUsuario: string
    ): Observable<void> {
        return this.delete(
            `usuario/excluirUsuarioCartorio/${cns}/${idUsuario}`
        );
    }

    public gravarTiposServicoUsuario(
        cns: string,
        idUsuario: string,
        idsTiposServicoUsuario: Array<string>
    ): Observable<void> {
        return this.post(
            `usuario/gravarTiposServicoUsuario/${cns}/${idUsuario}`,
            idsTiposServicoUsuario
        );
    }

    public listarUsuariosCartorioLookup(
        cns: string
    ): Observable<Array<LookupUsuarioDTO>> {
        return this.get(`usuario/listarUsuariosCartorioLookup/${cns}`);
    }
}
