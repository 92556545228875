import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from './models/dialog-data';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
    selector: 'dialogPergunta',
    templateUrl: './views/dialog-pergunta.html',
})
export class DialogPerguntaComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: DialogData,
        public dialogRef: MatDialogRef<DialogPerguntaComponent>
    ) {}

    eventoSim(): void {
        if (this.data.fnSim != null) {
            this.data.fnSim();
        }
        this.dialogRef.close(true);
    }

    eventoNao(): void {
        if (this.data.fnNao != null) {
            this.data.fnNao();
        }
        this.dialogRef.close(false);
    }
}
