import { Component, OnInit, Inject } from '@angular/core';
import { ToastService, AuthenticationService } from 'app/main/_shared/servicos';
import {
    UsuarioControllerService,
    AtendimentoControllerService,
} from 'app/main/_shared/servicos/controllers';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    UsuarioCartorio,
    HorarioAtendimento,
} from 'app/main/_shared/models/cartorio';
import {
    TipoAtendimentoCartorio,
    TipoAtendimentoUsuario,
} from 'app/main/_shared/models/_enums';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'atendimento-usuario',
    templateUrl: './atendimento-usuario.component.html',
    styleUrls: ['./atendimento-usuario.component.scss'],
})
export class AtendimentoUsuarioComponent implements OnInit {
    public usuario: UsuarioCartorio;
    private _cns: string;
    public horariosDoUsuario: Array<HorarioAtendimento> = [];

    private _horariosDisponiveisParaSelecao: Array<string> = [];
    public horarioSelecionado = '';
    public tipoAtendimentoSelecionado: TipoAtendimentoCartorio;

    get horariosParaSelecao(): Array<string> {
        return this._horariosDisponiveisParaSelecao.filter(
            (horario) =>
                this.horariosDoUsuario.filter(
                    (horUsuario) => horUsuario.horaInicio === horario
                ).length === 0
        );
    }

    get tipoAtendimentoCartorio(): typeof TipoAtendimentoCartorio {
        return TipoAtendimentoCartorio;
    }

    get tipoAtendimentoUsuario(): typeof TipoAtendimentoUsuario {
        return TipoAtendimentoUsuario;
    }

    constructor(
        private _usuarioControllerService: UsuarioControllerService,
        private _atendimentoControllerService: AtendimentoControllerService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _authenticationService: AuthenticationService,
        private _toastService: ToastService,
        private _dialogRef: MatDialogRef<AtendimentoUsuarioComponent>,
        @Inject(MAT_DIALOG_DATA)
        private _dados: any
    ) {
        this.usuario = this._dados.usuario;

        if (
            this.usuario.tipoAtendimento !==
            TipoAtendimentoUsuario.VirtualEPresencial
        ) {
            this.tipoAtendimentoSelecionado =
                this.usuario.tipoAtendimento ===
                TipoAtendimentoUsuario.ApenasPresencial
                    ? TipoAtendimentoCartorio.Presencial
                    : TipoAtendimentoCartorio.Virtual;
        }

        this._cns = this._authenticationService.usuarioAtual.cns;
    }

    ngOnInit(): void {
        this.carregarDados();
    }

    carregarDados(): void {
        this._fuseProgressBarService.show();

        forkJoin({
            horariosDoUsuario: this._usuarioControllerService.listarHorariosAtendimentoUsuario(
                this.usuario.id
            ),
            horariosDisponiveis: this._atendimentoControllerService.listarExpedienteUsuariosCartorio(
                this._cns
            ),
        }).subscribe(
            ({ horariosDoUsuario, horariosDisponiveis }) => {
                this.horariosDoUsuario = horariosDoUsuario;
                this._horariosDisponiveisParaSelecao = horariosDisponiveis;
                this._fuseProgressBarService.hide();
            },
            (erro) => {
                console.log(erro);
                this._toastService.mensagemErro(
                    'Ocorreu um problema ao carregar os dados'
                );
                this._fuseProgressBarService.hide();
            }
        );
    }

    gravarDados(): void {
        this._fuseProgressBarService.show();
        this._atendimentoControllerService
            .gravarHorarioAtendimentoUsuario(
                this.usuario.cnsCartorio,
                this.usuario.id,
                this.horariosDoUsuario
            )
            .subscribe(
                () => {
                    this._toastService.mensagemSuccesso(
                        'Dados armazenados com sucesso'
                    );
                    this._fuseProgressBarService.hide();
                    this._dialogRef.close();
                },
                (erro) => {
                    console.log(erro);
                    this._toastService.mensagemErro(
                        'Ocorreu um problema ao gravar os dados'
                    );
                    this._fuseProgressBarService.hide();
                }
            );
    }

    adicionarHorario(): void {
        this.horariosDoUsuario.push(
            new HorarioAtendimento(
                this.usuario.id,
                this.horarioSelecionado,
                this.tipoAtendimentoSelecionado
            )
        );
        this.horarioSelecionado = undefined;

        if (
            this.usuario.tipoAtendimento ===
            TipoAtendimentoUsuario.VirtualEPresencial
        ) {
            this.tipoAtendimentoSelecionado = undefined;
        }
    }

    removerHorario(horario: HorarioAtendimento): void {
        const index = this.horariosDoUsuario.findIndex(
            (hor) =>
                hor.horaInicio === horario.horaInicio &&
                hor.tipoAtendimento === horario.tipoAtendimento
        );

        this.horariosDoUsuario.splice(index, 1);
    }

    public descricaoTipoAtendimento(
        tipoAtendimento: TipoAtendimentoCartorio
    ): string {
        switch (tipoAtendimento) {
            case TipoAtendimentoCartorio.Presencial:
                return 'Presencial';
            case TipoAtendimentoCartorio.Virtual:
                return 'Virtual';
        }
    }
}
