<h1 mat-dialog-title>Atendimento do usuário</h1>
<mat-dialog-content mat-dialog-content>
    <div
        fxLayout="column"
        fxLayout.gt-sm="row"
        fxLayoutAlign="center stretch"
        class="m-16"
    >
        <mat-card fxFlex.gt-sm="100" class="mat-elevation-z4" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="outline" fxFlex="40">
                    <mat-label>Horário</mat-label>
                    <mat-select
                        [disabled]="this.horariosParaSelecao.length === 0"
                        [(ngModel)]="this.horarioSelecionado"
                    >
                        <mat-option
                            [value]="horario"
                            *ngFor="let horario of this.horariosParaSelecao"
                        >
                            {{ horario }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="40" class="mr-8">
                    <mat-label>Tipo de atendimento</mat-label>
                    <mat-select
                        [disabled]="
                            this.usuario.tipoAtendimento !=
                                tipoAtendimentoUsuario.VirtualEPresencial ||
                            this.horariosParaSelecao.length === 0
                        "
                        [(ngModel)]="this.tipoAtendimentoSelecionado"
                    >
                        <mat-option
                            [value]="this.tipoAtendimentoCartorio.Virtual"
                        >
                            Virtual
                        </mat-option>
                        <mat-option
                            [value]="this.tipoAtendimentoCartorio.Presencial"
                        >
                            Presencial
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <button
                    mat-fab
                    matTooltip="Adicionar horário"
                    color="accent"
                    class="botao-adicionar-horario"
                    [disabled]="
                        !this.horarioSelecionado ||
                        this.tipoAtendimentoSelecionado === undefined
                    "
                    (click)="this.adicionarHorario()"
                >
                    <mat-icon class="material-icons">
                        add
                    </mat-icon>
                </button>
            </div>

            <mat-chip-list>
                <mat-chip
                    *ngFor="let horario of this.horariosDoUsuario"
                    (removed)="this.removerHorario(horario)"
                    [color]="
                        horario.tipoAtendimento ===
                        tipoAtendimentoCartorio.Presencial
                            ? 'warn'
                            : 'primary'
                    "
                    selected
                >
                    {{ horario.horaInicio }} -
                    {{ this.descricaoTipoAtendimento(horario.tipoAtendimento) }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </mat-card>
    </div>
</mat-dialog-content>

<mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end end"
    class="text-right mt-24"
>
    <button mat-stroked-button color="accent" mat-dialog-close>
        Fechar
    </button>
    <button
        mat-raised-button
        color="accent"
        class="ml-8"
        (click)="this.gravarDados()"
    >
        Salvar
    </button>
</mat-dialog-actions>
