<div id="reset-password" fxLayout="column">
    <div
        id="reset-password-form-wrapper"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div
            id="reset-password-form"
            [@animate]="{
                value: '*',
                params: { duration: '300ms', y: '100px' }
            }"
        >
            <div class="logo">
                <img src="assets/images/logos/logo.svg" />
            </div>

            <div class="title">ALTERAR SUA SENHA</div>

            <form
                name="resetPasswordForm"
                [formGroup]="resetPasswordForm"
                novalidate
            >
                <mat-form-field appearance="outline">
                    <mat-label>Senha atual</mat-label>
                    <input
                        matInput
                        type="password"
                        formControlName="senhaAtual"
                    />
                    <mat-icon matSuffix class="secondary-text"
                        >vpn_key</mat-icon
                    >
                    <mat-error
                        *ngIf="
                            resetPasswordForm
                                .get('senhaAtual')
                                .hasError('required')
                        "
                    >
                        Senha atual é obrigatória
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Nova senha</mat-label>
                    <input matInput type="password" formControlName="senha" />
                    <mat-icon matSuffix class="secondary-text"
                        >vpn_key</mat-icon
                    >
                    <mat-error>
                        Nova senha é obrigatória
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Nova senha (Confirmação)</mat-label>
                    <input
                        matInput
                        type="password"
                        formControlName="confirmacao"
                    />
                    <mat-icon matSuffix class="secondary-text"
                        >vpn_key</mat-icon
                    >
                    <mat-error
                        *ngIf="
                            resetPasswordForm
                                .get('confirmacao')
                                .hasError('required')
                        "
                    >
                        Confirmação de senha é obrigatória
                    </mat-error>
                    <mat-error
                        *ngIf="
                            !resetPasswordForm
                                .get('confirmacao')
                                .hasError('required') &&
                            resetPasswordForm
                                .get('confirmacao')
                                .hasError('passwordsNotMatching')
                        "
                    >
                        Senha e confirmação devem ser iguais
                    </mat-error>
                </mat-form-field>

                <button
                    mat-raised-button
                    class="submit-button"
                    color="accent"
                    aria-label="Alterar minha senha"
                    [disabled]="resetPasswordForm.invalid"
                    (click)="this.alterarSenha()"
                >
                    Alterar minha senha
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="this.urlRetorno">Voltar</a>
            </div>
        </div>
    </div>
</div>
