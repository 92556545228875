import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { FuseSharedModule } from '@fuse/shared.module';

import { HomeComponent } from 'app/main/home/home.component';
import { MatToolbar, MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { PerguntasComponent } from './perguntas/perguntas.component';
import { NgxMaskModule } from 'ngx-mask';
import { ComponentesModule } from '../_shared/componentes';
import {
    MAT_DATE_LOCALE,
    MatNativeDateModule,
    MatOptionModule,
} from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DialogFinalAgendamentoComponent } from './perguntas/dialog-final-agendamento/dialog-final-agendamento.component';
import { NgxPrintModule } from 'ngx-print';
import { DialogTirarDuvidaComponent } from './dialog-tirar-duvida/dialog-tirar-duvida.component';
import { DialogDetalhesAgendamentoComponent } from './dialog-detalhes-agendamento/dialog-detalhes-agendamento.component';
import { FuseWidgetModule } from '@fuse/components';
import { AlterarHorarioAgendamentoComponent } from './dialog-detalhes-agendamento/alterar-horario-agendamento/alterar-horario-agendamento.component';
import { DialogOrientacoesLgpdComponent } from './perguntas/dialog-orientacoes-lgpd/dialog-orientacoes-lgpd.component';
import { CancelarAgendamentoComponent } from './dialog-detalhes-agendamento/cancelar-agendamento/cancelar-agendamento.component';

const routes = [
    {
        path: '',
        component: HomeComponent,
    },
];

@NgModule({
    declarations: [
        HomeComponent,
        PerguntasComponent,
        DialogFinalAgendamentoComponent,
        DialogTirarDuvidaComponent,
        DialogDetalhesAgendamentoComponent,
        AlterarHorarioAgendamentoComponent,
        CancelarAgendamentoComponent,
        DialogOrientacoesLgpdComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        FuseSharedModule,
        NgxMaskModule,
        ComponentesModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatOptionModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        EditorModule,
        NgxPrintModule,
        FuseWidgetModule,
    ],
    providers: [{ provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }],
})
export class HomeModule {}
