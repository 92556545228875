import { BaseControllerService } from './base-controller.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '..';
import { Observable, of } from 'rxjs';
import { TipoAtendimentoUsuario } from '../../models/_enums';

import { TipoServicoAtendimento } from '../../models/cartorio/tipo-servico-atendimento.model';
import { ConfigService } from 'app/config/config.service';

@Injectable({
    providedIn: 'root',
})
export class TipoServicoControllerService extends BaseControllerService {
    constructor(
        _http: HttpClient,
        _authenticationService: AuthenticationService,
        configService: ConfigService
    ) {
        super(_http, _authenticationService, configService);
    }

    public listarTiposServicoAtendimento(
        cns: string,
        tipoAtendimento: TipoAtendimentoUsuario = null
    ): Observable<Array<TipoServicoAtendimento>> {
        if (tipoAtendimento === null) {
            tipoAtendimento = TipoAtendimentoUsuario.VirtualEPresencial;
        }

        return this.get<Array<TipoServicoAtendimento>>(
            `tiposervico/${cns}/${tipoAtendimento}`
        );
    }

    public inserirTiposervicoAtendimento(
        cns: string,
        tipoServico: TipoServicoAtendimento
    ): Observable<void> {
        return this.post<void, TipoServicoAtendimento>(
            `tiposervico/${cns}`,
            tipoServico
        );
    }

    public gravarTiposervicoAtendimento(
        cns: string,
        tipoServico: TipoServicoAtendimento
    ): Observable<void> {
        return this.put<void, TipoServicoAtendimento>(
            `tiposervico/${cns}`,
            tipoServico
        );
    }

    public excluirTiposervicoAtendimento(
        cns: string,
        idTipoServico: string
    ): Observable<void> {
        return this.delete(`tiposervico/${cns}/${idTipoServico}`);
    }
}
