<button mat-raised-button color="accent" (click)="onClick()">
    <mat-icon>cloud_upload</mat-icon>
    {{ text }}
</button>
<br />
<ul>
    <li *ngFor="let file of files" [@fadeInOut]="file.state">
        <mat-progress-bar [value]="file.progress"></mat-progress-bar>
        <span id="file-label">
            {{ file.data.name }}
            <a
                title="Tentar novamente"
                (click)="retryFile(file)"
                *ngIf="file.canRetry"
            >
                <mat-icon>refresh</mat-icon></a
            >
            <a
                title="Cancelar"
                (click)="cancelFile(file)"
                *ngIf="file.canCancel"
            >
                <mat-icon>cancel</mat-icon></a
            >
        </span>
    </li>
</ul>
