import { Injectable } from '@angular/core';
import { BaseControllerService } from './base-controller.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '..';
import { ConfiguracoesGeraisCartsys } from '../../models';
import { Observable } from 'rxjs';
import { ConfigService } from 'app/config/config.service';

@Injectable({
    providedIn: 'root',
})
export class ConfiguracoesGeraisControllerService extends BaseControllerService {
    constructor(
        _http: HttpClient,
        _authenticationService: AuthenticationService,
        configService: ConfigService
    ) {
        super(_http, _authenticationService, configService);
    }

    listarConfiguracoesGerais(): Observable<ConfiguracoesGeraisCartsys> {
        return this.get<ConfiguracoesGeraisCartsys>('configuracoesGerais');
    }

    alterarConfiguracoesGerais(
        config: ConfiguracoesGeraisCartsys
    ): Observable<ConfiguracoesGeraisCartsys> {
        return this.put<ConfiguracoesGeraisCartsys, ConfiguracoesGeraisCartsys>(
            'configuracoesGerais',
            config
        );
    }
}
