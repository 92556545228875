import { DiaSemana } from '../_enums';

export class ExpedienteCartorio {
    cns: string;
    dia: DiaSemana;
    horaInicioDisponibilidadeSistema: string;
    horaFimDisponibilidadeSistema: string;
    horaInicioExpediente: string;
    horaFimExpediente: string;
}
