import { AbstractControl } from '@angular/forms';
import {
    MASCARA_CELULAR,
    MASCARA_TELEFONE,
    MASCARA_TELEFONE_CELULAR,
} from '..';

export function cnpjEhValido(control: AbstractControl): any {
    const cnpj = control.value;

    if (!cnpj) {
        return false;
    }

    if (cnpj.length !== 14) {
        return { cnpjInvalido: true };
    }

    if (
        cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999'
    ) {
        return { cnpjInvalido: true };
    }

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== Number(digitos.charAt(0))) {
        return { cnpjInvalido: true };
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i) * pos--);
        if (pos < 2) {
            pos = 9;
        }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== Number(digitos.charAt(1))) {
        return { cnpjInvalido: true };
    }

    return true;
}

export function mascaraTelefone(texto: string): string {
    if (!texto) {
        return;
    }

    const match = texto.match(/\d/g);
    let numerosString = '';

    if (match) {
        numerosString = match.join('');
    }

    // Regra digito 9
    if (numerosString.length > 2) {
        if (numerosString[2] === '9') {
            return MASCARA_CELULAR;
        } else {
            return MASCARA_TELEFONE;
        }
    }

    return MASCARA_TELEFONE_CELULAR;
}

export function cepEhValido(cep: string): boolean {
    cep = cep.replace(/\D/g, '');

    if (cep !== '') {
        const validacep = /^[0-9]{8}$/;

        return validacep.test(cep);
    }

    return false;
}

export function cpfEhValido(cpf: string): boolean {
    let soma = 0;
    let resto: number;

    cpf = cpf.replace(/\D/g, '');

    if (
        cpf === '00000000000' ||
        cpf === '11111111111' ||
        cpf === '22222222222' ||
        cpf === '33333333333' ||
        cpf === '44444444444' ||
        cpf === '55555555555' ||
        cpf === '66666666666' ||
        cpf === '77777777777' ||
        cpf === '88888888888' ||
        cpf === '99999999999'
    ) {
        return false;
    }

    for (let i = 1; i <= 9; i++) {
        // tslint:disable-next-line: radix
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
        resto = 0;
    }
    // tslint:disable-next-line: radix
    if (resto !== parseInt(cpf.substring(9, 10))) {
        return false;
    }

    soma = 0;
    for (let i = 1; i <= 10; i++) {
        // tslint:disable-next-line: radix
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
        resto = 0;
    }

    // tslint:disable-next-line: radix
    if (resto !== parseInt(cpf.substring(10, 11))) {
        return false;
    }

    return true;
}
