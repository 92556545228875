import { TipoCartorio } from '../_enums/tipo-cartorio.enum';

export class DadosCartorioHomeDTO {
    cns: string;
    nome: string;
    tipo: TipoCartorio;
    telefone: string;
    mensagemFinalAtendimento: string;
    nomeAtendenteVirtual: string;
    urlConsultaAndamento: string;
    telefoneSuporteWhatsApp: string;
    orientacoesLGPD: string;
}
