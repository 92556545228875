import {
    Component,
    Inject,
    ViewEncapsulation,
    AfterViewInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from 'app/config/config.service';
import { configuracaoEditorReadOnly } from 'app/main/_shared/funcoes/configuracoes-editor';

@Component({
    selector: 'dialog-final-agendamento',
    templateUrl: './dialog-final-agendamento.component.html',
    styleUrls: ['./dialog-final-agendamento.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DialogFinalAgendamentoComponent implements AfterViewInit {
    public configuracoesEditor = configuracaoEditorReadOnly('500', this._configService.config.urlDominio);
    public mensagemHTML = '';
    public carregandoEditor: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public dados: {
            sucesso: boolean;
            protocolo?: string;
            mensagem: string;
        },
        private _configService: ConfigService,
        public dialogRef: MatDialogRef<DialogFinalAgendamentoComponent>
    ) {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.mensagemHTML = this.montarMensagemHTML();
            this.carregandoEditor = true;
        });
    }

    montarMensagemHTML(): string {
        const cabecalho = `<p class="text-center">
                Utilize o número do protocolo gerado para acompanhamento da solicitação
                junto ao cartório.<br />
             </p>
             <p class="protocolo">
                ${this.dados.protocolo}
             </p><p><h3 class="text-center">Orientações para a execução do serviço:</h3>
        `;
        return `${cabecalho}${this.dados.mensagem}</p`;
    }

    onInitEditor(): void {
        setTimeout(() => {
            this.carregandoEditor = false;
        });
    }

    fechar(): void {
        this.dialogRef.close();
    }
}
