<div id="home" fxLayout="column">
    <div class="titulo-cartorio" *ngIf="!!this.dadosCartorio && !!this.dadosCartorio.nome" fxHide.lt-sm>
        <h1>{{this.dadosCartorio.nome}}</h1>
    </div>
    <div
        id="home-form-wrapper"
        fxLayout="column"
        fxLayoutAlign="center start"
        *ngIf="!!this.cns"
    >
        <div
            id="home-form"
            [@animate]="{
                value: '*',
                params: { duration: '300ms', y: '100px' }
            }"
            fxLayout="column"
            fxLayoutAlign="space-around center"
            class="pb-12"
            *ngIf="!this.perguntas && !this.retirarDuvidas"
        >
            <div fxLayout="column" class="top">
                <span class="title">Olá</span>
                <span class="subtitle" fxHide.lt-sm>Seja bem vindo !</span>
                <span class="subtitle" fxHide.gt-sm>Seja bem vindo ao {{ this.dadosCartorio.nome }}!</span>
            </div>

            <div fxLayout="column" fxLayoutAlign=" stretch">
                <button
                    mat-raised-button
                    color="accent"
                    class="subscribe-button m-8"
                    ngClass.gt-sm="button-normal"
                    ngClass.lt-sm="button-mobile"
                    aria-label="Agendamento de atendimento virtual"
                    (click)="this.chatAtendimentoVirtual()"
                >
                    AGENDAMENTO DE ATENDIMENTO VIRTUAL
                </button>
                <button
                    mat-raised-button
                    color="accent"
                    class="subscribe-button m-8"
                    ngClass.gt-sm="button-normal"
                    ngClass.lt-sm="button-mobile"
                    aria-label="Agendamento de atendimento presencial"
                    (click)="this.chatAtendimentoPresencial()"
                >
                    AGENDAMENTO DE ATENDIMENTO PRESENCIAL
                </button>
                <a
                    mat-raised-button
                    color="accent"
                    class="subscribe-button m-8"
                    ngClass.gt-sm="button-normal"
                    ngClass.lt-sm="button-mobile"
                    aria-label="Consultar agendamentos"
                    [href]="this.dadosCartorio.urlConsultaAndamento"
                    target="blank"
                >
                    CONSULTAR ANDAMENTOS
                </a>
                <button
                    mat-raised-button
                    color="accent"
                    class="subscribe-button m-8"
                    ngClass.gt-sm="button-normal"
                    ngClass.lt-sm="button-mobile"
                    aria-label="Orientações para execução de serviços"
                    (click)="this.retirarDuvidas = true"
                >
                    ORIENTAÇÕES PARA EXECUÇÃO DE SERVIÇOS
                </button>

                <button
                    mat-raised-button
                    color="accent"
                    class="subscribe-button m-8"
                    ngClass.gt-sm="button-normal"
                    ngClass.lt-sm="button-mobile"
                    aria-label="Verificar detalhes de agendamento"
                    (click)="this.verificarDetalhesAgendamento()"
                >
                    VERIFICAR AGENDAMENTOS REALIZADOS
                </button>

                <a
                    mat-raised-button
                    color="accent"
                    class="subscribe-button m-8"
                    ngClass.gt-sm="button-normal"
                    ngClass.lt-sm="button-mobile"
                    aria-label="Orientações sobre serviços"
                    *ngIf="!!this.dadosCartorio.telefoneSuporteWhatsApp"
                    [href]="this.urlSuporteWhatsApp"
                    target="blank"
                >
                    RETIRAR DÚVIDAS VIA WHATSAPP
                </a>
            </div>
        </div>

        <div
            id="home-form"
            [@animate]="{
                value: '*',
                params: { duration: '300ms', y: '100px' }
            }"
            fxLayout="column"
            fxLayoutAlign="space-around center"
            class="pb-12"
            *ngIf="!this.perguntas && this.retirarDuvidas"
        >
            <div fxLayout="column" class="top">
                <span class="title">
                    Retirar dúvidas sobre serviços do cartório
                </span>
                <span class="subtitle">{{ this.dadosCartorio.nome }}</span>
            </div>

            <div fxLayout="column" fxLayoutAlign=" stretch">
                <button
                    mat-raised-button
                    *ngFor="let tipoServico of this.tiposServico"
                    color="accent"
                    class="subscribe-button m-8"
                    ngClass.gt-sm="button-normal"
                    ngClass.lt-sm="button-mobile"
                    [attr.aria-label]="tipoServico.descricao"
                    (click)="this.exibirRetirarDuvidas(tipoServico)"
                >
                    {{ tipoServico.descricao }}
                </button>
                <button
                    mat-raised-button
                    color="accent"
                    class="subscribe-button m-8"
                    ngClass.gt-sm="button-normal"
                    ngClass.lt-sm="button-mobile"
                    aria-label="Voltar"
                    (click)="this.retirarDuvidas = false"
                >
                    VOLTAR
                </button>
            </div>
        </div>

        <perguntas
            id="pergunta-form"
            [@animate]="{
                value: '*',
                params: { duration: '300ms', y: '100px' }
            }"
            fxLayout="column"
            fxLayoutAlign="end center"
            class="w-100-p perguntas"
            *ngIf="this.perguntas"
            [dadosCartorio]="this.dadosCartorio"
            [tipoAtendimento]="this.tipoAtendimento"
            (eventoFechar)="this.retornarParaTelaInicial()"
        >
        </perguntas>
    </div>

    <div
        id="home-form-wrapper"
        fxLayout="column"
        fxLayoutAlign="center center"
        *ngIf="!this.cns"
    >
        <div
            id="home-form"
            [@animate]="{
                value: '*',
                params: { duration: '300ms', y: '100px' }
            }"
            fxLayout="column"
            fxLayoutAlign="space-around center"
            class="p-24 m-24"
            *ngIf="!this.dadosConfiguracao"
        >
            <h1 *ngIf="!this.carregando && !this.dadosConfiguracao">
                Página inválida
            </h1>
            <h1 *ngIf="this.carregando">Carregando</h1>
            <mat-progress-spinner
                *ngIf="this.carregando"
                color="accent"
                mode="indeterminate"
            >
            </mat-progress-spinner>
        </div>

        <div
            id="home-form"
            [@animate]="{
                value: '*',
                params: { duration: '300ms', y: '100px' }
            }"
            fxLayout="column"
            fxLayoutAlign="space-around center"
            class="pb-12"
            *ngIf="!!this.dadosConfiguracao"
        >
            <div fxLayout="column" class="top">
                <span class="title">Olá</span>
                <span class="subtitle">Seja bem vindo !</span>
            </div>

            <div fxLayout="column" fxLayoutAlign=" stretch">
                <a
                    mat-raised-button
                    color="accent"
                    class="subscribe-button m-8"
                    ngClass.gt-sm="button-normal"
                    ngClass.lt-sm="button-mobile"
                    aria-label="Consultar andamentos (Cartório de notas)"
                    [href]="this.dadosConfiguracao.urlConsultaAndamentosNotas"
                    target="blank"
                >
                    CONSULTAR ANDAMENTOS (CARTÓRIO DE NOTAS)
                </a>
                <a
                    mat-raised-button
                    color="accent"
                    class="subscribe-button m-8"
                    ngClass.gt-sm="button-normal"
                    ngClass.lt-sm="button-mobile"
                    aria-label="Consultar andamentos (Cartório de imóveis)"
                    [href]="this.dadosConfiguracao.urlConsultaAndamentosImoveis"
                    target="blank"
                >
                    CONSULTAR ANDAMENTOS (CARTÓRIO DE IMOVEIS)
                </a>
                <a
                    mat-raised-button
                    color="accent"
                    class="subscribe-button m-8"
                    ngClass.gt-sm="button-normal"
                    ngClass.lt-sm="button-mobile"
                    aria-label="Consultar andamentos (Cartório de protesto)"
                    [href]="
                        this.dadosConfiguracao.urlConsultaAndamentosProtesto
                    "
                    target="blank"
                >
                    CONSULTAR ANDAMENTOS (CARTÓRIO DE PROTESTO)
                </a>
            </div>
        </div>
    </div>

    <mat-toolbar id="footer">
        <div
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutAlign.gt-xs="space-between center"
            fxFlex
        >
            <span class="footer-link footer-text" ngClass.lt-sm="footer-text-mobile">
                <a href="assets/politica-seguranca.html" target="_blank">
                    Site seguro e mantido sob política de segurança e privacidade
                </a>
            </span>

            <span class="footer-link">
                <a href="http://www.cartsys.com.br/" target="_blank">
                    Cartsys Software
                </a>
            </span>
        </div>
    </mat-toolbar>
</div>
