import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DialogService } from 'app/main/_shared/servicos/dialogs';
import { DialogFormBase } from 'app/main/_shared/contratos';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsuarioCartorio } from 'app/main/_shared/models/cartorio';
import { UsuarioControllerService } from 'app/main/_shared/servicos/controllers';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastService, AuthenticationService } from 'app/main/_shared/servicos';
import {
    TipoAtendimentoUsuario
} from 'app/main/_shared/models/_enums';

@Component({
    selector: 'manutencao-usuarios',
    templateUrl: './manutencao-usuarios.component.html',
    styleUrls: ['./manutencao-usuarios.component.scss'],
})
export class ManutencaoUsuariosComponent extends DialogFormBase<
    ManutencaoUsuariosComponent
> {
    private usuario: UsuarioCartorio = new UsuarioCartorio();
    private _inserindo = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private _dados: any,
        formBuilder: FormBuilder,
        dialogService: DialogService,
        dialogRef: MatDialogRef<ManutencaoUsuariosComponent>,
        private _fuseProgressBarService: FuseProgressBarService,
        private _usuarioController: UsuarioControllerService,
        private _authenticationService: AuthenticationService,
        private _toastService: ToastService
    ) {
        super(formBuilder, dialogService, dialogRef);
    }

    get tipoAtendimento(): typeof TipoAtendimentoUsuario {
        return TipoAtendimentoUsuario;
    }

    construirForm(formBuilder: FormBuilder): void {
        this.form = formBuilder.group({
            nome: ['', [Validators.required, Validators.maxLength(100)]],
            email: ['', [Validators.required, Validators.email]],
            senha: ['', Validators.required],
            tipoAtendimento: [
                TipoAtendimentoUsuario.VirtualEPresencial,
                [Validators.required],
            ],
        });
    }

    carregarDados(): void {
        this.usuario = this._dados.usuario ?? new UsuarioCartorio();
        this._inserindo = !this._dados.usuario;
        this.form.patchValue(this.usuario);
    }

    gravarDados(): void {
        this.usuario.email = this.form.get('email').value;
        this.usuario.nome = this.form.get('nome').value;
        this.usuario.senha = this.form.get('senha').value;
        this.usuario.tipoAtendimento = this.form.get('tipoAtendimento')
            .value as TipoAtendimentoUsuario;

        if (!this.usuario.id) {
            this.usuario.id = null;
        }

        if (this.usuario.senha !== '######') {
            this.usuario.senha = btoa(this.usuario.senha);
        }

        if (this._inserindo) {
            this._dialogService
                .dialogPerguntaAsync(
                    'Atenção',
                    'Deseja vincular todos tipos de serviço à este usuário?'
                )
                .subscribe((simOuNao) => {
                    this.processarGravacaoUsuario(simOuNao);
                });
        } else {
            this.processarGravacaoUsuario(false);
        }
    }

    private processarGravacaoUsuario(vincularTiposServico: boolean): void {
        this._fuseProgressBarService.show();
        this._usuarioController
            .gravarUsuarioCartorio(
                this._authenticationService.usuarioAtual.cns,
                vincularTiposServico,
                this.usuario
            )
            .subscribe(
                () => {
                    this._toastService.mensagemSuccesso(
                        'Dados armazenados com sucesso.'
                    );
                    this._fuseProgressBarService.hide();
                    this._dialogRef.close();
                },
                (erro) => {
                    if (erro.includes('[CONFLICT]')) {
                        erro = erro.replace('[CONFLICT]', '');
                        this._toastService.mensagemErro(erro);
                    } else {
                        this._toastService.mensagemErro(
                            'Ocorreu um erro ao realizar a gravação dos dados.'
                        );
                    }
                    console.log(erro);
                    this._fuseProgressBarService.hide();
                }
            );
    }
}
