import {
    Component,
    Inject,
    ViewEncapsulation,
    AfterViewInit,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DadosCartorioHomeDTO } from 'app/main/_shared/models/dtos/dados-cartorio-home-dto.model';
import { mascaraTelefone } from 'app/main/_shared/funcoes';
import { AtendimentoControllerService } from 'app/main/_shared/servicos/controllers';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { DialogService } from 'app/main/_shared/servicos/dialogs';
import { InformacoesAgendamentoDTO } from 'app/main/_shared/models/dtos/informacoes-agendamento-dto.model';
import {
    StatusAtendimentoUsuario,
    TipoAtendimentoCartorio,
} from 'app/main/_shared/models/_enums';
import { AlterarHorarioAgendamentoComponent } from './alterar-horario-agendamento/alterar-horario-agendamento.component';
import { Overlay } from '@angular/cdk/overlay';
import { CancelarAgendamentoComponent } from './cancelar-agendamento/cancelar-agendamento.component';

@Component({
    selector: 'detalhes-agendamento',
    templateUrl: './dialog-detalhes-agendamento.component.html',
    styleUrls: ['./dialog-detalhes-agendamento.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DialogDetalhesAgendamentoComponent implements AfterViewInit {
    public cpfRequirente = '';
    public nomeRequirente = '';
    public telefone = '';
    public mascaraTelefone = '(00) 0000-0000';
    public protocolo = '';
    public listaAgendamentos: Array<InformacoesAgendamentoDTO> = new Array<
        InformacoesAgendamentoDTO
    >();
    public datasDisponiveis: Array<Date> = [];
    public horariosDisponiveis: Array<string> = [];
    public horarioSelecionado: string;
    public dataSelecionada: Date;
    
    public get TipoAtendimentoCartorio () : typeof TipoAtendimentoCartorio {
        return TipoAtendimentoCartorio;
    }

    public get StatusAtendimentoUsuario(): typeof StatusAtendimentoUsuario {
        return StatusAtendimentoUsuario;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public dados: { dadosCartorio: DadosCartorioHomeDTO },
        private _atendimentoController: AtendimentoControllerService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _dialogService: DialogService,
        private _overlay: Overlay
    ) {}

    ngAfterViewInit(): void {
        this.listaAgendamentos = new Array<InformacoesAgendamentoDTO>();
    }

    public changeMascaraTelefone(telefone: string): void {
        this.mascaraTelefone = mascaraTelefone(telefone);
        this.cpfRequirente = '';
        this.nomeRequirente = '';
        this.protocolo = '';
    }

    public changeCpf(): void {
        this.telefone = '';
        this.nomeRequirente = '';
        this.protocolo = '';
    }

    public changeProtocolo(): void {
        this.cpfRequirente = '';
        this.telefone = '';
        this.nomeRequirente = '';
    }

    public changeNomeRequirente(): void {
        this.cpfRequirente = '';
        this.telefone = '';
        this.protocolo = '';
    }

    public descricaoTipoAtendimento(
        tipoAtendimento: TipoAtendimentoCartorio
    ): string {
        switch (tipoAtendimento) {
            case TipoAtendimentoCartorio.Presencial:
                return 'Atendimento presencial';
            default:
                return 'Atendimento virtual';
        }
    }

    public pesquisar(): void {
        this._fuseProgressBarService.show();
        this._atendimentoController
            .pesquisarAgendamentoEspecifico({
                cpfRequirente: this.cpfRequirente,
                nomeRequirente: this.nomeRequirente,
                protocolo: this.protocolo,
            })
            .subscribe(
                (agendamento: Array<InformacoesAgendamentoDTO>) => {
                    this._fuseProgressBarService.hide();
                    this.listaAgendamentos = agendamento;
                },
                (erro) => {
                    console.log(erro);
                    this._fuseProgressBarService.hide();
                    this._dialogService.dialogInformacao(
                        'Pesquisa de agendamentos',
                        'Nenhum agendamento foi encontrado!'
                    );
                }
            );
    }

    public solicitarAlteracaodeAgendamento(
        agendamento: InformacoesAgendamentoDTO
    ): void {
        this._dialogService
            .dialogPerguntaAsync(
                'Atenção',
                'Ao alterar a data e horário deste agendamento, a data e horários anteriores serão liberados junto ao cartório. ' +
                    'Tem certeza que deseja alterar este agendamento?'
            )
            .subscribe((resposta: boolean) => {
                if (resposta) {
                    this._dialogService
                        .dialogGenerico(AlterarHorarioAgendamentoComponent, {
                            scrollStrategy: this._overlay.scrollStrategies.noop(),
                            data: {
                                cns: this.dados.dadosCartorio.cns,
                                dadosAgendamento: agendamento,
                            },
                        })
                        .subscribe(() => {
                            this.pesquisar();
                        });
                }
            });
    }

    public solicitarCancelamentoAgendamento(agendamento: InformacoesAgendamentoDTO): void {
        this._dialogService
            .dialogGenerico(CancelarAgendamentoComponent, {
                scrollStrategy: this._overlay.scrollStrategies.noop(),
                data: {
                    cns: this.dados.dadosCartorio.cns,
                    dadosAgendamento: agendamento,
                },
            })
            .subscribe(() => {
                this.pesquisar();
            });
    }
}
