<div id="admin" fxLayout="column">
    <div
        id="admin-form-wrapper"
        fxLayout="column"
        fxLayoutAlign="center center"
    >
        <div
            id="admin-form"
            [@animate]="{
                value: '*',
                params: { duration: '300ms', y: '100px' }
            }"
        >
            <div class="logo">
                <img src="assets/images/logos/logo.svg" />
            </div>

            <div class="title">Realize o login</div>

            <form
                name="adminForm"
                [formGroup]="adminForm"
                (ngSubmit)="this.onSubmit()"
                novalidate
            >
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" />
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error
                        *ngIf="adminForm.get('email').hasError('required')"
                    >
                        E-mail é obrigatório
                    </mat-error>
                    <mat-error
                        *ngIf="
                            !adminForm.get('email').hasError('required') &&
                            adminForm.get('email').hasError('email')
                        "
                    >
                        Por favor insira um e-mail válido
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Senha</mat-label>
                    <input matInput type="password" formControlName="senha" />
                    <mat-icon matSuffix class="secondary-text"
                        >vpn_key</mat-icon
                    >
                    <mat-error>
                        Senha é obrigatória
                    </mat-error>
                </mat-form-field>

                <div
                    class="remember-forgot-password"
                    fxLayout="row"
                    fxLayout.xs="column"
                    fxLayoutAlign="space-between center"
                >
                    <mat-checkbox
                        id="login-lembrar-senha"
                        color="primary"
                        class="remember-me ml-6"
                        aria-label="Lembrar senha"
                        [(ngModel)]="this.lembrarSenha"
                        [ngModelOptions]="{ standalone: true }"
                    >
                        Lembrar senha
                    </mat-checkbox>
                </div>

                <button
                    mat-raised-button
                    color="accent"
                    class="submit-button"
                    aria-label="LOG IN"
                    [disabled]="adminForm.invalid"
                >
                    Login
                </button>
            </form>
        </div>
    </div>
</div>
