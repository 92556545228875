<form mat-dialog-content fxLayout="row" name="form" [formGroup]="this.form">
    <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>Telefone</mat-label>
        <input matInput [mask]="this.mascaraTelefone" formControlName="telefone" />
        <mat-icon matSuffix class="secondary-text">phone</mat-icon>
        <mat-error *ngIf="form.get('telefone').hasError('required')">
            Telefone obrigatório
        </mat-error>
    </mat-form-field>
</form>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-stroked-button mat-dialog-close>
        Fechar
    </button>
    <button mat-raised-button color="primary" class="ml-8" mat-dialog-close (click)="this.enviarMensagemPeloWhatsapp()"
        [disabled]="this.form.invalid">
        Enviar
    </button>
</mat-dialog-actions>