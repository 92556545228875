import {
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    AfterViewInit,
} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AutenticacaoControllerService } from '../_shared/servicos/controllers/autenticacao-controller..service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToastService, AuthenticationService } from '../_shared/servicos';
import { Router } from '@angular/router';
import { TipoLogin } from '../_shared/models/_enums';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ResetPasswordComponent
    implements OnInit, OnDestroy, AfterViewInit {
    resetPasswordForm: FormGroup;
    urlRetorno: string;

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _autenticacaoController: AutenticacaoControllerService,
        private _authenticationService: AuthenticationService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _toastService: ToastService,
        private _router: Router
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngAfterViewInit(): void {
        const tipoLogin = this._authenticationService.usuarioAtual.tipoLogin;
        console.log(tipoLogin);

        switch (tipoLogin) {
            case TipoLogin.Cartsys: {
                this.urlRetorno = '/configuracoes-cartsys';
                break;
            }
            default: {
                this.urlRetorno = '/atendimento';
                break;
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.resetPasswordForm = this._formBuilder.group({
            senhaAtual: ['', [Validators.required]],
            senha: ['', Validators.required],
            confirmacao: ['', [Validators.required, confirmPasswordValidator]],
        });

        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.resetPasswordForm
            .get('senha')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm
                    .get('confirmacao')
                    .updateValueAndValidity();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    alterarSenha(): void {
        this._fuseProgressBarService.show();
        this._autenticacaoController
            .alterarSenha(
                this.resetPasswordForm.get('senhaAtual').value as string,
                this.resetPasswordForm.get('senha').value as string
            )
            .subscribe(
                () => {
                    this._fuseProgressBarService.hide();
                    this._toastService.mensagemSuccesso(
                        'Senha alterada com sucesso !'
                    );

                    this._router.navigate([this.urlRetorno]);
                },
                (erro) => {
                    this._fuseProgressBarService.hide();
                    const strErro = erro as string;
                    if (!!strErro && strErro.includes('[INVALID]')) {
                        erro = strErro.replace('[INVALID]', '');
                        this._toastService.mensagemErro(erro);
                    } else {
                        this._toastService.mensagemErro(
                            'Ocorreu um problema ao realizar a alteração da senha'
                        );

                        this._authenticationService.logout();
                        let url = '/';

                        if (
                            this._authenticationService.usuarioAtual
                                .tipoLogin === TipoLogin.Cartorio ||
                            this._authenticationService.usuarioAtual
                                .tipoLogin === TipoLogin.AdminCartorio
                        ) {
                            url = `${this._authenticationService.usuarioAtual.cns}/admin`;
                        } else {
                            url = 'admin';
                        }
                        this._router.navigate([url]);
                    }
                }
            );
    }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (
    control: AbstractControl
): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null;
    }

    const password = control.parent.get('senha');
    const passwordConfirm = control.parent.get('confirmacao');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
