import { OrientacoesLgpdComponent } from './orientacoes-lgpd/orientacoes-lgpd.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
    CartorioControllerService,
    UsuarioControllerService,
} from 'app/main/_shared/servicos/controllers';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { UsuarioCartorio } from 'app/main/_shared/models/cartorio';
import { AuthenticationService, ToastService } from 'app/main/_shared/servicos';
import { DialogService } from 'app/main/_shared/servicos/dialogs';
import { ExpedienteCartorioComponent } from './expediente-cartorio/expediente-cartorio.component';
import { Overlay } from '@angular/cdk/overlay';
import { ManutencaoUsuariosComponent } from './manutencao-usuarios/manutencao-usuarios.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AtendimentoUsuarioComponent } from './atendimento-usuario/atendimento-usuario.component';
import { TipoServicoAtendimento } from 'app/main/_shared/models/cartorio/tipo-servico-atendimento.model';
import {
    retornarDescricaoTipoAtendimento,
    mascaraTelefone,
} from 'app/main/_shared/funcoes';
import { TipoServicoControllerService } from 'app/main/_shared/servicos/controllers/tipo-servico-controller.service';
import { ManutencaoTiposServicoComponent } from './manutencao-tipos-servico/manutencao-tipos-servico.component';
import { DadosCartorio } from 'app/main/_shared/models';
import { TiposServicoUsuarioComponent } from './tipos-servico-usuario/tipos-servico-usuario.component';
import { ExclusaoDadosComponent } from './exclusao-dados/exclusao-dados.component';

@Component({
    selector: 'configuracoes-cartorio',
    templateUrl: './configuracoes-cartorio.component.html',
    styleUrls: ['./configuracoes-cartorio.component.scss'],
})
export class ConfiguracoesCartorioComponent implements OnInit {
    public form: FormGroup;
    public dadosCartorio: DadosCartorio;
    public usuarios: Array<UsuarioCartorio> = new Array<UsuarioCartorio>();
    public displayedColumnsUsuarios: string[] = ['nome', 'email', 'buttons'];
    public mascaraTelefone = '(00) 0000-0000';
    public dataSourceUsuarios: MatTableDataSource<
        UsuarioCartorio
    > = new MatTableDataSource<UsuarioCartorio>([]);

    public displayedColumnsTiposServico: string[] = [
        'descricao',
        'tipoAtendimento',
        'buttons',
    ];
    public dataSourceTiposServico: MatTableDataSource<
        TipoServicoAtendimento
    > = new MatTableDataSource<TipoServicoAtendimento>([]);

    @ViewChild(MatPaginator, { static: true })
    paginatorUsuarios: MatPaginator;

    @ViewChild(MatPaginator, { static: true })
    paginatorTiposServico: MatPaginator;

    constructor(
        private _formBuilder: FormBuilder,
        private _cartorioControllerService: CartorioControllerService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _dialogService: DialogService,
        private _usuarioControllerService: UsuarioControllerService,
        private _tipoServicoControllerService: TipoServicoControllerService,
        private _authenticationService: AuthenticationService,
        private _toastService: ToastService,
        private _overlay: Overlay
    ) { }

    ngOnInit(): void {
        this._fuseProgressBarService.show();

        this.translateMatPaginator();
        this.construirForm();

        this.carregarDadosCartorio();
        this.carregarUsuarios();
        this.carregarTiposServico();
    }

    construirForm(): void {
        this.form = this._formBuilder.group({
            nomeAtendenteVirtual: [
                '',
                [Validators.required, Validators.maxLength(20)],
            ],
            minutosDuracaoPadraoAtendimento: [
                30,
                [Validators.required, Validators.min(15)],
            ],
            telefoneSuporteWhatsApp: ['', []],
        });

        this.form
            .get('telefoneSuporteWhatsApp')
            .valueChanges.subscribe((val) => {
                this.mascaraTelefone = mascaraTelefone(val);
            });
    }

    carregarDadosCartorio(): void {
        this._fuseProgressBarService.show();
        this._cartorioControllerService
            .listarDadosCartorio(this._authenticationService.usuarioAtual.cns)
            .subscribe(
                (dadosCartorio) => {
                    this.dadosCartorio = dadosCartorio;
                    this.form.patchValue(this.dadosCartorio);
                    this._fuseProgressBarService.hide();
                },
                (erro) => {
                    console.log(erro);
                    this._toastService.mensagemErro(
                        'Ocorreu um erro ao carregar os dados de cartório'
                    );
                    this._fuseProgressBarService.hide();
                }
            );
    }

    gravarConfiguracoesBasicas(): void {
        this._fuseProgressBarService.show();

        this.dadosCartorio.nomeAtendenteVirtual = this.form.get(
            'nomeAtendenteVirtual'
        ).value;

        this.dadosCartorio.mensagemFinalAtendimento = '';

        this.dadosCartorio.minutosDuracaoPadraoAtendimento = this.form.get(
            'minutosDuracaoPadraoAtendimento'
        ).value;

        this.dadosCartorio.telefoneSuporteWhatsApp = this.form.get(
            'telefoneSuporteWhatsApp'
        ).value;

        this._cartorioControllerService
            .alterarDadosBasicosCartorio({
                cns: this.dadosCartorio.cns,
                minutosDuracaoPadraoAtendimento: this.dadosCartorio
                    .minutosDuracaoPadraoAtendimento,
                nomeAtendenteVirtual: this.dadosCartorio.nomeAtendenteVirtual,
                telefoneSuporteWhatsApp: this.dadosCartorio
                    .telefoneSuporteWhatsApp
            })
            .subscribe(
                () => {
                    this._toastService.mensagemSuccesso(
                        'Dados alterados com sucesso'
                    );
                    this._fuseProgressBarService.hide();
                },
                (erro) => {
                    console.log(erro);
                    this._toastService.mensagemErro(
                        'Ocorreu um erro ao gravar os dados de cartório'
                    );
                    this._fuseProgressBarService.hide();
                }
            );
    }

    carregarUsuarios(): void {
        this._fuseProgressBarService.show();
        this._usuarioControllerService
            .listarUsuariosCartorio(
                this._authenticationService.usuarioAtual.cns
            )
            .subscribe(
                (usuarios) => {
                    this.usuarios = usuarios;
                    this.dataSourceUsuarios.data = usuarios;
                    this.dataSourceUsuarios.paginator = this.paginatorUsuarios;
                    this._fuseProgressBarService.hide();
                },
                (erro) => {
                    console.log(erro);
                    this._toastService.mensagemErro(
                        'Ocorreu um erro ao carregar os dados de cartório'
                    );
                    this._fuseProgressBarService.hide();
                }
            );
    }

    carregarTiposServico(): void {
        this._fuseProgressBarService.show();
        this._tipoServicoControllerService
            .listarTiposServicoAtendimento(
                this._authenticationService.usuarioAtual.cns
            )
            .subscribe(
                (tiposServico) => {
                    this.dataSourceTiposServico.data = tiposServico;
                    this.dataSourceTiposServico.paginator = this.paginatorTiposServico;
                    this._fuseProgressBarService.hide();
                },
                (erro) => {
                    console.log(erro);
                    this._toastService.mensagemErro(
                        'Ocorreu um erro ao carregar os dados de cartório'
                    );
                    this._fuseProgressBarService.hide();
                }
            );
    }

    translateMatPaginator(): void {
        this.paginatorUsuarios._intl.firstPageLabel = 'Primeria página';
        this.paginatorUsuarios._intl.itemsPerPageLabel = 'Itens/página';
        this.paginatorUsuarios._intl.lastPageLabel = 'Última página';
        this.paginatorUsuarios._intl.nextPageLabel = 'Próxima página';
        this.paginatorUsuarios._intl.previousPageLabel = 'Página anterior';
    }

    abrirManutencaoExpediente(): void {
        this._dialogService.dialogGenerico(ExpedienteCartorioComponent, {
            scrollStrategy: this._overlay.scrollStrategies.noop(),
            height: 'auto',
            maxHeight: '80%',
            data: {
                cns: this._authenticationService.usuarioAtual.cns,
            },
        });
    }

    abrirManutencaoUsuarios(usuario?: UsuarioCartorio): void {
        console.log(usuario);
        this._dialogService
            .dialogGenerico(ManutencaoUsuariosComponent, {
                scrollStrategy: this._overlay.scrollStrategies.noop(),
                height: 'auto',
                maxHeight: '80%',
                data: {
                    usuario: usuario,
                },
            })
            .subscribe(() => {
                this.carregarUsuarios();
            });
    }

    abrirManutencaoTipoServicoAtendimento(
        tipoServico?: TipoServicoAtendimento
    ): void {
        this._dialogService
            .dialogGenerico(ManutencaoTiposServicoComponent, {
                scrollStrategy: this._overlay.scrollStrategies.noop(),
                height: 'auto',
                maxHeight: '80%',
                data: {
                    tipoServico: tipoServico,
                },
            })
            .subscribe(() => {
                this.carregarTiposServico();
            });
    }

    abrirAtendimentoUsuario(usuario: UsuarioCartorio): void {
        this._dialogService.dialogGenerico(AtendimentoUsuarioComponent, {
            scrollStrategy: this._overlay.scrollStrategies.noop(),
            height: 'auto',
            maxHeight: '80%',
            data: {
                usuario: usuario,
            },
        });
    }

    abrirTiposServicoUsuario(usuario: UsuarioCartorio): void {
        this._dialogService.dialogGenerico(TiposServicoUsuarioComponent, {
            scrollStrategy: this._overlay.scrollStrategies.noop(),
            height: 'auto',
            maxHeight: '80%',
            data: {
                usuario: usuario,
            },
        });
    }

    retornarDescricaoTipoAtendimento(
        tipoServico: TipoServicoAtendimento
    ): string {
        return retornarDescricaoTipoAtendimento(tipoServico.tipoAtendimento);
    }

    excluirUsuario(usuario: UsuarioCartorio): void {
        this._dialogService.dialogPerguntaHtml(
            'Atenção',
            '<h3>Deseja deletar este usuário?</h3> <p>A remoção causará a perca de todas as informações vinculadas ao usuário selecionado.</p>',
            () => {
                this._fuseProgressBarService.show();
                this._usuarioControllerService
                    .excluirUsuarioCartorio(
                        this._authenticationService.usuarioAtual.cns,
                        usuario.id
                    )
                    .subscribe(
                        () => {
                            this._toastService.mensagemSuccesso(
                                'Usuário excluído com sucesso'
                            );
                            this._fuseProgressBarService.hide();
                            this.carregarUsuarios();
                        },
                        (erro) => {
                            console.log(erro);
                            this._toastService.mensagemErro(
                                'Ocorreu um erro ao realizar a exclusão do usuário'
                            );
                            this._fuseProgressBarService.hide();
                        }
                    );
            }
        );
    }

    excluirTipoServicoAtendimento(tipoServico: TipoServicoAtendimento): void {
        this._dialogService.dialogPerguntaHtml(
            'Atenção',
            '<h3>Deseja deletar este tipo de serviço?</h3> <p>A remoção causará a perca de todas as informações vinculadas ao tipo de serviço selecionado.</p>',
            () => {
                this._fuseProgressBarService.show();
                this._tipoServicoControllerService
                    .excluirTiposervicoAtendimento(
                        this._authenticationService.usuarioAtual.cns,
                        tipoServico.id
                    )
                    .subscribe(
                        () => {
                            this._toastService.mensagemSuccesso(
                                'Tipo de serviço excluído com sucesso'
                            );
                            this._fuseProgressBarService.hide();
                            this.carregarTiposServico();
                        },
                        (erro) => {
                            console.log(erro);
                            this._toastService.mensagemErro(
                                'Ocorreu um erro ao realizar a exclusão do tipo de serviço'
                            );
                            this._fuseProgressBarService.hide();
                        }
                    );
            }
        );
    }

    abrirOrientacoesLGPD(): void {
        this._dialogService.dialogGenerico(OrientacoesLgpdComponent, {
            scrollStrategy: this._overlay.scrollStrategies.noop(),
            height: 'auto',
            maxHeight: '80%',
            data: {
                cns: this._authenticationService.usuarioAtual.cns,
                orientacoesLgpd: this.dadosCartorio.orientacoesLGPD
            },
        }).subscribe((orientacoesAtualizadas) => {
            if (!!orientacoesAtualizadas) {
                this.dadosCartorio.orientacoesLGPD = orientacoesAtualizadas;
            }
        });
    }

    abrirExclusaoDados(): void {
        this._dialogService.dialogGenerico(ExclusaoDadosComponent, {
            scrollStrategy: this._overlay.scrollStrategies.noop(),
            height: 'auto',
            maxHeight: '80%'
        })
    }
}
